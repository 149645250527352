import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    vendite: [],
    loading: false,
      
};


//fetch vendite
const fetchVenditeStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchVenditeSuccess = ( state, action ) => {
    return updateObject(state,{
        vendite: action.vendite,
        loading: false});
}

const fetchVenditeFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.FETCH_VENDITE_START: return fetchVenditeStart(state, action);
        case actionTypes.FETCH_VENDITE_SUCCESS: return fetchVenditeSuccess(state, action);
        case actionTypes.FETCH_VENDITE_FAIL:return fetchVenditeFail(state, action);
        default: return state;

        }
}

export default reducer;