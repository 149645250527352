import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    fattura: null,
    fatture: [],
    loading: false,
    added: false
   
};

const addFatturaInit = ( state, action ) => {
    return updateObject(state, {added: false });
}

const addFatturaStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const addFatturaSuccess = ( state, action ) => {
    const newFattura = {
        ...action.fatturaData,
        id:action.fatturaId,
        }    
    return updateObject(state, {
            loading: false,
            fatture: state.fatture.concat(newFattura),
            added: true
        });
}

const addFatturaFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const modificaFatturaStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const modificaFatturaSuccess = ( state, action ) => {
    const newFattura = {
        ...action.fatturaData,
        id:action.fatturaId,
        }    
    return updateObject(state, {
            loading: false,
            fatture: state.fatture.concat(newFattura),
            added: true
        });
}

const modificaFatturaFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//elimina fattura
const eliminaFatturaStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const eliminaFatturaSuccess = ( state, action ) => {
    // const newFattura = {
    //     ...action.fatturaData,
    //     id:action.fatturaId,
    //     }    
    return updateObject(state, {
            loading: false,
            fatture: state.fatture,
            added: true
        });
}

const eliminaFatturaFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch fatture
const fetchFattureStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchFattureSuccess = ( state, action ) => {
    return updateObject(state,{
        fatture: action.fatture,
        loading: false});
}

const fetchFattureFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch fatture
const fetchFatturaStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchFatturaSuccess = ( state, action ) => {
    return updateObject(state,{
        fattura: action.fattura,
        loading: false});
}

const fetchFatturaFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.ADD_FATTURA_INIT: return addFatturaInit( state, action );
        case actionTypes.ADD_FATTURA_START: return addFatturaStart(state, action );            
        case actionTypes.ADD_FATTURA_SUCCESS: return addFatturaSuccess( state, action);
        case actionTypes.ADD_FATTURA_FAIL: return addFatturaFail (state, action);
        case actionTypes.MODIFICA_FATTURA_START: return modificaFatturaStart(state, action );            
        case actionTypes.MODIFICA_FATTURA_SUCCESS: return modificaFatturaSuccess( state, action);
        case actionTypes.MODIFICA_FATTURA_FAIL: return modificaFatturaFail (state, action);
        case actionTypes.ELIMINA_FATTURA_START: return eliminaFatturaStart(state, action );            
        case actionTypes.ELIMINA_FATTURA_SUCCESS: return eliminaFatturaSuccess( state, action);
        case actionTypes.ELIMINA_FATTURA_FAIL: return eliminaFatturaFail (state, action);
        case actionTypes.FETCH_FATTURE_START: return fetchFattureStart(state, action);
        case actionTypes.FETCH_FATTURE_SUCCESS: return fetchFattureSuccess(state, action);
        case actionTypes.FETCH_FATTURE_FAIL:return fetchFattureFail(state, action);
        case actionTypes.FETCH_FATTURA_START: return fetchFatturaStart(state, action);
        case actionTypes.FETCH_FATTURA_SUCCESS: return fetchFatturaSuccess(state, action);
        case actionTypes.FETCH_FATTURA_FAIL:return fetchFatturaFail(state, action);
        default: return state;

        }
}

export default reducer;
