import React, { Component } from 'react';
import classes from './Vendite.module.css'
import Vendita from './Vendita/Vendita.js';
import { Table } from 'reactstrap';


export default class Vendite extends Component {
    render() {

        let vistaHeader = (<tr></tr>);
        let vista = (<tr></tr>);
        let tot = 0;
        
                 


        if (this.props.tipoVendita === 'vista' && this.props.vendite[0] ) {
            //console.log('VISTA');
            vista = this.props.vendite[0].map(vendita => (
                 <Vendita key={vendita.id} id={vendita.id} venditaData={vendita} tipoVenditaData={this.props.tipoVendita} onClicked={this.props.onClicked}/>          
            ));
            this.props.vendite[0].map(vendita => (
                tot +=  (vendita.prezzoMontatura ? parseFloat(vendita.prezzoMontatura) : 0) + (vendita.prezzoLenti ? parseFloat(vendita.prezzoLenti) : 0)         
           ));
        }

        if (this.props.tipoVendita === 'lac' && this.props.vendite[1] ) {
            //console.log('LAC');
            vista = this.props.vendite[1].map(vendita => (
                 <Vendita key={vendita.id} id={vendita.id} venditaData={vendita} tipoVenditaData={this.props.tipoVendita} onClicked={this.props.onClicked}/>          
            ));
            this.props.vendite[1].map(vendita => (
                tot +=  (vendita.prezzoLac ? parseFloat(vendita.prezzoLac) : 0)         
           ));

        }

        if (this.props.vendite[2] && this.props.tipoVendita === 'liquidi') {
            vista = this.props.vendite[2].map(vendita => (
                 <Vendita key={vendita.id} id={vendita.id}  venditaData={vendita} tipoVenditaData={this.props.tipoVendita} onClicked={this.props.onClicked}/>          
            ));
            this.props.vendite[2].map(vendita => (
                tot +=  (vendita.prezzoLiquido ? parseFloat(vendita.prezzoLiquido) : 0)         
           ));
        }

        if (this.props.vendite[3] && this.props.tipoVendita === 'altro') {
             vista = this.props.vendite[3].map(vendita => (
                 <Vendita key={vendita.id} id={vendita.id} venditaData={vendita} tipoVenditaData={this.props.tipoVendita} onClicked={this.props.onClicked}/>          
            ));
            this.props.vendite[3].map(vendita => (
                tot +=  (vendita.prezzoAltro ? parseFloat(vendita.prezzoAltro) : 0)         
           ));
        }

        if (this.props.tipoVendita === 'vista') { vistaHeader = (
                        <tr>            
                            <th className="text-left"> Descrizione</th> 
                            <th className="text-right">Montatura</th>
                            <th className="text-right"> Lenti</th> 
                        </tr>)
         }
        
         if (this.props.tipoVendita !== 'vista') { vistaHeader = (
                    <tr>            
                        <th className="text-left"> Descrizione </th> 
                        <th className="text-right">{' '}</th>
                        <th className="text-right"> Prezzo</th> 
                    </tr>)
        }

      
 
        return (
            
                <div className={ classes.Card }>
        <div className={ classes.CardHeader }>{this.props.tipoVendita}</div>
        <div className={ classes.CardBody}>
            <Table hover >
                        <thead >
                            {vistaHeader}
                        </thead>
                        <tbody>
                           {vista}
                           
                        </tbody>
            </Table>
          
        </div>
        <div className={classes.CardFooter}>Totale: {parseFloat(tot).toFixed(2)}</div>
      </div>

        )
    }
}
