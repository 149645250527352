import React, {useState} from 'react';

//import classes from './NavigationItems.module.css';
import NavigationItem from '../NavigationItem/NavigationItem';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import Aux from '../../../hoc/Aux_/Aux';
import {manager} from '../../../shared/authorization';


const NavigationItems = props => {

  
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownContabilitaOpen, setDropdownContabilitaOpen] = useState(false);
    const [dropdownStatsOpen, setDropdownStatsOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);
    const toggleContabilita = () => setDropdownContabilitaOpen(!dropdownContabilitaOpen);
    const toggleStats = () => setDropdownStatsOpen(!dropdownStatsOpen);


    return (
<Aux>
        { (props.isAuthenticated && manager.includes(props.userId) ) &&
                <Dropdown nav isOpen={dropdownStatsOpen} toggle={toggleStats} >
                <DropdownToggle nav caret >
                    Stats
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem>  <NavigationItem link="/stats/stats" exact={true} clicked={props.clicked}>Stats</NavigationItem> </DropdownItem>
                    <DropdownItem>  <NavigationItem link="/stats/venditeVista" exact={true} clicked={props.clicked}>Vista</NavigationItem> </DropdownItem>
                    <DropdownItem>  <NavigationItem link="/stats/venditeSole" exact={true} clicked={props.clicked}>Sole</NavigationItem> </DropdownItem>
                </DropdownMenu>
                </Dropdown>
        } 
       
        { props.isAuthenticated  && <NavigationItem link="/cashflow" exact={true} clicked={props.clicked}>Cassa</NavigationItem> } 

        { props.isAuthenticated &&
                <Dropdown nav isOpen={dropdownContabilitaOpen} toggle={toggleContabilita} >
                <DropdownToggle nav caret >
                    Contabilità
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem > <NavigationItem link="/contabilita/carica-fattura" clicked={props.clicked}>Carica Fattura</NavigationItem> </DropdownItem>
                    <DropdownItem>  <NavigationItem link="/contabilita/fatture" clicked={props.clicked}>Fatture</NavigationItem> </DropdownItem>
                    <DropdownItem>  <NavigationItem link="/contabilita/pagamenti" clicked={props.clicked}>Pagamenti</NavigationItem> </DropdownItem>
                    <DropdownItem>  <NavigationItem link="/contabilita/fornitori" clicked={props.clicked}>Fornitori</NavigationItem> </DropdownItem>
                </DropdownMenu>
                </Dropdown>
        } 

      
        { props.isAuthenticated && <NavigationItem link="/" exact={true} clicked={props.clicked}>Oggi</NavigationItem> }  
        { props.isAuthenticated && <NavigationItem link="/scegli-giorno" clicked={props.clicked}>Cambia giorno</NavigationItem> } 
        
        { props.isAuthenticated &&
                <Dropdown nav isOpen={dropdownOpen} toggle={toggle} >
                <DropdownToggle nav caret >
                    Tabelle
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem > <NavigationItem link="/tabelle/marche" clicked={props.clicked}>Marche</NavigationItem> </DropdownItem>
                    <DropdownItem>  <NavigationItem link="/tabelle/codici-pagamento" clicked={props.clicked}>Codici pagamenti</NavigationItem> </DropdownItem>
                    <DropdownItem > <NavigationItem link="/tabelle/flussi" clicked={props.clicked}>Tipo di flussi</NavigationItem> </DropdownItem>
                </DropdownMenu>
                </Dropdown>
        } 
       { props.isAuthenticated && <NavigationItem link="/logout" clicked={props.clicked}>Logout</NavigationItem> } 
    {/* </ul>
        
        
              
            
      </div> */}
</Aux>
);
}

export default NavigationItems;
