import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateObject } from '../../shared/utility.js';
import * as actions from '../../store/actions/index';
import {Button, Form, FormGroup, ModalBody, ModalFooter, ModalHeader, Label,
        Input} from 'reactstrap';
import Aux from '../../hoc/Aux_/Aux.js';


const ModificaFornitore  = props => {

    const {
        toggle,
        modifica,
        fornitoreData
      } = props;

    const [ fornitore, setFornitore] = useState({
        id:   { value: fornitoreData && fornitoreData.id ? fornitoreData.id : '',
                validation: { required: false},
                valid: true,
                touched: false},
        denominazione: { value: fornitoreData && fornitoreData.denominazione ? fornitoreData.denominazione : '',
                validation: { required: true},
                valid: false,
                touched: false},
        codiceFiscale: { value: fornitoreData && fornitoreData.codiceFiscale ? fornitoreData.codiceFiscale : '',
                validation: { required: true},
                valid: false,
                touched: false},
        indirizzo: { value: fornitoreData && fornitoreData.indirizzo ? fornitoreData.indirizzo : '',
                    validation: { required: false},
                    valid: true,
                touched: false},
        comune: { value: fornitoreData && fornitoreData.comune ? fornitoreData.comune : '',
                    validation: { required: false},
                    valid: true,
                touched: false},
        cap: { value: fornitoreData && fornitoreData.cap ? fornitoreData.cap : '',
                    validation: { required: false},
                    valid: true,
                touched: false},
        provincia: { value: fornitoreData && fornitoreData.provincia ? fornitoreData.provincia : '',
                    validation: { required: false},
                    valid: true,
                touched: false},
        
        telefono: { value: fornitoreData && fornitoreData.telefono ? fornitoreData.telefono : '',
                    validation: { required: false},
                    valid: true,
                touched: false},
        email: { value: fornitoreData && fornitoreData.email ? fornitoreData.email : '',
                    validation: { required: false},
                    valid: true,
                touched: false},
        })

   
        const checkValidity = (value, rules) => {
            let isValid = false;

            // console.log(rules);
            // console.log(value);
         
            if (rules.required) {
                isValid = value.trim() !== '';
            }

            if (!rules.required) {
                isValid = true;
            }

            return isValid
        }

        const inputChangedHandler = (event, inputIdentifier) => { 
        

            const updatedFormElement = updateObject(fornitore[inputIdentifier], {
                value: event.target.value,
                touched: true,
                valid: checkValidity(event.target.value, fornitore[inputIdentifier].validation),
                
      
            } );
      
            const updatedFornitoreForm = updateObject(fornitore,{
                [inputIdentifier]: updatedFormElement
            });
            
            let formIsValid = true;
            for (let inputIdentifier in updatedFornitoreForm) {
                formIsValid = updatedFornitoreForm[inputIdentifier].valid && formIsValid;
            }
            setFornitore(updatedFornitoreForm);
         
           // setFormIsValid(formIsValid);
        }

            const addFornitoreHandler = ( event ) => {
                event.preventDefault();
                const fornitoreData = {};
                      for (let formElementIdentifier in fornitore) {
                        fornitoreData[formElementIdentifier] = fornitore[formElementIdentifier].value;
                      }
                //console.log(fornitoreData);
                props.onAddFornitore(fornitoreData, props.token);
                toggle();
              }
  
        const submitModificaFornitoreHandler = ( event ) => {
                event.preventDefault();
                const fornitoreData = {};
                      for (let formElementIdentifier in fornitore) {
                        fornitoreData[formElementIdentifier] = fornitore[formElementIdentifier].value;
                      }
                //console.log(fornitoreData)
                props.onModificaFornitore(fornitoreData, props.token);
                toggle();
              }
  
        const submitEliminaFornitoreHandler = ( event ) => {
                event.preventDefault();
                const fornitoreData = {};
                      for (let formElementIdentifier in fornitore) {
                        fornitoreData[formElementIdentifier] = fornitore[formElementIdentifier].value;
                      }
                //console.log(fornitoreData)
                props.onEliminaFornitore(fornitoreData, props.token);
                toggle();
              }
    

        return (
            <Aux>
                
              <ModalHeader toggle={toggle}>{modifica ? 'MODIFICA SCHEDA FORNITORE' : 'INSERISCI NUOVO FORNITORE'}</ModalHeader>
        <ModalBody>
        <Form onSubmit={addFornitoreHandler}>
                      <Input  type="hidden" name="id" id="id" value={fornitore.id} />
                                
                 <FormGroup>
                        
                                
                                
                                <Label>DENOMINAZIONE</Label>
                                
                                <Input valid={(fornitore.denominazione.valid && fornitore.denominazione.touched ) ? true : null } 
                                invalid={(!fornitore.denominazione.valid && fornitore.denominazione.touched) ? true : null } 
                                type="text" name="denominazione" id="denominazione"  
                                placeholder="Denominazione fornitore" 
                                value={fornitore.denominazione.value} 
                                onBlur={(event) => inputChangedHandler(event, "denominazione")}
                                onChange={(event) => inputChangedHandler(event, "denominazione")}/>
                               
                        
                </FormGroup>

                <FormGroup>
                                <Label>CODICE FISCALE (P.Iva)</Label>
                                <Input valid={(fornitore.codiceFiscale.valid && fornitore.codiceFiscale.touched ) ? true : null } 
                                invalid={(!fornitore.codiceFiscale.valid && fornitore.codiceFiscale.touched) ? true : null}  
                                 type="text" name="codiceFiscale" id="codiceFiscale"  placeholder="Codice fiscale fornitore" value={fornitore.codiceFiscale.value} 
                                 onBlur={(event) => inputChangedHandler(event, "codiceFiscale")}
                                 onChange={(event) => inputChangedHandler(event, "codiceFiscale")}/>
                </FormGroup>

                <FormGroup>
                                <Label>INDIRIZZO</Label>
                                <Input valid={(fornitore.indirizzo.valid && fornitore.indirizzo.touched ) ? true : null }
                                invalid={(!fornitore.indirizzo.valid && fornitore.indirizzo.touched) ? true : null }  
                                type="text" name="indirizzo" id="indirizzo"  placeholder="Indirizzo fornitore" value={fornitore.indirizzo.value} 
                                onBlur={(event) => inputChangedHandler(event, "indirizzo")}
                                onChange={(event) => inputChangedHandler(event, "indirizzo")}/>
                </FormGroup>

                <FormGroup>
                                <Label>COMUNE</Label>
                                <Input valid={(fornitore.comune.valid && fornitore.comune.touched ) ? true : null } 
                                invalid={(!fornitore.comune.valid && fornitore.comune.touched) ? true : null }   
                                type="text" name="comune" id="comune"  placeholder="Comune fornitore" value={fornitore.comune.value} 
                                onBlur={(event) => inputChangedHandler(event, "comune")}
                                onChange={(event) => inputChangedHandler(event, "comune")}/>
                </FormGroup>

                <FormGroup>
                                <Label>CAP</Label>
                                <Input valid={(fornitore.cap.valid && fornitore.cap.touched ) ? true : null } 
                                invalid={(!fornitore.cap.valid && fornitore.cap.touched) ? true : null }  
                                type="text" name="cap" id="cap"  placeholder="CAP fornitore" value={fornitore.cap.value} 
                                onBlur={(event) => inputChangedHandler(event, "cap")}
                                onChange={(event) => inputChangedHandler(event, "cap")}/>
                </FormGroup>

                <FormGroup >
                                <Label>PROVINCIA</Label>
                                <Input valid={(fornitore.provincia.valid && fornitore.provincia.touched ) ? true : null } 
                                invalid={(!fornitore.provincia.valid && fornitore.provincia.touched) ? true : null }  
                                type="text" name="provincia" id="provincia"  placeholder="Provincia fornitore" value={fornitore.provincia.value} 
                                onBlur={(event) => inputChangedHandler(event, "provincia")}
                                onChange={(event) => inputChangedHandler(event, "provincia")}/>
                </FormGroup>

                <FormGroup>
                                <Label>TELEFONO</Label>
                                <Input valid={(fornitore.telefono.valid && fornitore.telefono.touched ) ? true : null } 
                                invalid={(!fornitore.telefono.valid && fornitore.telefono.touched) ? true : null }   
                                type="text" name="telefono" id="telefono"  placeholder="Telefono fornitore" value={fornitore.telefono.value} 
                                onBlur={(event) => inputChangedHandler(event, "telefono")}
                                onChange={(event) => inputChangedHandler(event, "telefono")}/>
                </FormGroup>

                <FormGroup>
                                <Label>E-MAIL</Label>
                                <Input valid={(fornitore.email.valid && fornitore.email.touched ) ? true : null }
                                invalid={(!fornitore.email.valid && fornitore.email.touched) ? true : null }   
                                type="email" name="email" id="email"  placeholder="E-mail fornitore" value={fornitore.email.value} 
                                onBlur={(event) => inputChangedHandler(event, "email")}
                                onChange={(event) => inputChangedHandler(event, "email")}/>
                </FormGroup>
          
    </Form>
        </ModalBody>
        <ModalFooter>
        {modifica ? <Button color="primary" onClick={submitModificaFornitoreHandler}>Modifica</Button> : <Button color="primary" onClick={addFornitoreHandler}>Inserisci</Button>}{' '}
        {modifica ? <Button color="danger" onClick={submitEliminaFornitoreHandler}>Elimina</Button> : null}{' '}
          <Button color="secondary" onClick={toggle}>Indietro</Button>
        </ModalFooter>
        </Aux>
        )
    
}

const mapStateToProps = (state) => {
    return {
        fornitori: state.fornitori.fornitori,
        loading: state.fornitori.loading,
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchFornitori: (token) => dispatch(actions.fetchFornitori(token)),
        onAddFornitore: (fornitoreData, token) => dispatch(actions.addFornitore(fornitoreData, token)),
        onModificaFornitore: (fornitoreData, token) => dispatch(actions.modificaFornitore(fornitoreData, token)),
        onEliminaFornitore: (fornitoreData, token) => dispatch(actions.eliminaFornitore(fornitoreData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModificaFornitore);
