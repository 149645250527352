import React from 'react';
import { Redirect } from 'react-router-dom';

const Oggi = () => {
    
        return (
            <div>
                <Redirect to="/" />
            </div>
        )
    }


export default Oggi;
