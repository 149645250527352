import React from "react";
import DatePicker from "react-datepicker";
import Aux from '../../../hoc/Aux_/Aux';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';
registerLocale('it', it)

 
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
 
const Calendario = props => {
  
     return (
      <Aux>
        <DatePicker
        locale = "it"
          selected={Date.parse(props.data)}
          onChange={props.changed}
          inline={props.visibile}
          dateFormat="dd/MM/yyyy"
        />
        
      </Aux>
    )
  };

export  default Calendario;
