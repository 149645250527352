import React  from 'react'
import {  Table } from 'reactstrap';
import Chart from "react-google-charts";
import { dataIntera} from '../../shared/utility.js';


const dettaglio = (props) => {


   // console.log(props.vendite);


    let totale = 0;
    
    Object.keys(props.vendite).map( key  => (
        totale  = parseFloat(totale) +  parseFloat(props.vendite[key].prezzo)
    ));
    


    let dataChart = [['Vendita', 'Ricavo']]
    
    Object.keys(props.vendite).map( key  => (
        dataChart.push([props.vendite[key].nome, parseFloat(props.vendite[key].prezzo)]) 
    ));

   
    
    
    let dataFineCorretta = new Date(props.dataFine);
    
  
  //  if(dataIntera(oggi) !== dataIntera(dataFineCorretta)){
        dataFineCorretta = dataFineCorretta.setDate(props.dataFine.getDate() - 1);
  //  }
    
        return (
            <div>
             <Table hover >
                <thead> 
                  <tr >
                      <th colSpan="3">Dettaglio da {dataIntera(new Date(props.dataInizio))} a {dataIntera(new Date (dataFineCorretta))}  </th>
                    
                  </tr>
                </thead>
                <tbody>
                     
                   {  Object.keys(props.vendite).map( key  =>
                   <tr key={key}>
                     <td >{props.vendite[key].nome}</td> 
                <td style={{textAlign: "right"}}>{props.vendite[key].numero}</td> 
                <td style={{textAlign:"right"}}> {props.vendite[key].prezzo && parseFloat(props.vendite[key].prezzo).toFixed(2)}</td>
                </tr> ) 
        }
            <tr >
                      <th colSpan="3" style={{textAlign:"right"}}>Totale: {parseFloat(totale).toFixed(2)}</th>
                    
                  </tr>
        
                         
                                           
            </tbody>
              </Table>

              {props.v}

                <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={dataChart}
                options={{
                title: totale > 0 ? 'Vendite del periodo' : 'Nessun dato da presentare',
                is3D: true
                }}
                rootProps={{ 'data-testid': '1' }}
                />      


    </div>
        )
    }


export default dettaglio;
