import React, {  useState } from 'react';
import { connect } from 'react-redux';
import {Button, Form, FormGroup, 
    Col,Input, InputGroup, InputGroupAddon, ModalHeader, ModalBody, ModalFooter,
     InputGroupText} from 'reactstrap';
import Aux from '../../../hoc/Aux_/Aux';
import { dataStringa } from '../../../shared/utility';
import * as actions from '../../../store/actions/index';


const ModificaChiusura  = props => {

//console.log(dataStringa(props.giorno));


const [ cash, setCash] = useState({
    idPos: props.chiusure.pos.id,
    idContanti: props.chiusure.contanti.id,
    idAssegni:props.chiusure.assegni.id,
    idBonifici: props.chiusure.bonifici.id,
    data: { value: dataStringa(props.giorno), //0 corrisponde a entrata
        validation: { required: false},
        valid: true},
    
    flusso: { value: 'entrata',
        validation: { required: false},
        valid: true
    },

    descrizione: { value: 'chiusura',
            validation: { required: true},
            valid: true
        },
    pos: { value: props.chiusure.pos.value > 0 ? props.chiusure.pos.value : '',
        validation: { required: false},
        valid: true
    },
    contanti: { value: props.chiusure.contanti.value > 0 ? props.chiusure.contanti.value : '',
        validation: { required: false},
        valid: true
    },
    assegni: { value: props.chiusure.assegni.value > 0 ? props.chiusure.assegni.value : '',
    validation: { required: false},
    valid: true
    },
    bonifici: { value: props.chiusure.bonifici.value > 0 ? props.chiusure.bonifici.value : '',
    validation: { required: false},
    valid: true
    }

    })

    
   
        const checkValidity = (value, rules) => {
            let isValid = false;

            //  console.log(rules);
            //  console.log(value);
         
            if (rules.required) {
                isValid = value.trim() !== '';
            }

            if (!rules.required) {
                isValid = true;
            }

            return isValid
        }
        const inputChangeHandler = (event, id) => {
                    //  console.log(event.target.value + ' elemento: ' + id);
                       const updatedCash = {
                           ...cash
                       };
                       const updatedFormElement = {
                           ...updatedCash[id]
                       };
                       
                       updatedFormElement.value = (typeof event.target.type !== 'undefined' && event.target.type === 'checkbox') ? event.target.checked : event.target.value;
                       
                       updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation)
                       updatedCash[id] = updatedFormElement;
                    //   console.log(updatedFormElement);
       
                       setCash(updatedCash);
                   }

        

        const submitModificaCashHandler = (event) => {
            event.preventDefault();
            let cashData = {data: dataStringa(props.giorno), flusso: 'entrata', tipo: 'chiusura', descrizione: '', modo: '', valore: ''  };
            if (cash.pos.value > 0) {
            //  console.log("Inseriamo chiusura pos: "+ cash.pos.value);
                cashData = {...cashData,  descrizione: 'chiusura pos del ' + props.giorno, modo: 'MP08', valore: cash.pos.value} 
                cashData = cash.idPos !== '' ?  {...cashData, id: cash.idPos } : {...cashData} ;
                cash.idPos !== '' ? props.onModificaCash(cashData, props.token) : props.onAddCash(cashData, props.token);
                props.chiusure.pos.id = cash.idPos; 
                props.chiusure.pos.value = cash.pos.value; 
                console.log('Valore: ' + props.chiusure.pos.value);              
            }

            if (cash.contanti.value > 0) {
            //  console.log("Inseriamo chiusura pos: "+ cash.pos.value);
                cashData = {...cashData,  descrizione: 'chiusura contanti del ' + props.giorno, modo: 'MP01', valore: cash.contanti.value}                   
                cashData = cash.idContanti !== '' ?  {...cashData, id: cash.idContanti } : {...cashData} ;
                cash.idContanti !== '' ? props.onModificaCash(cashData, props.token) : props.onAddCash(cashData, props.token);
                props.chiusure.contanti.id = cash.idContanti; 
                props.chiusure.contanti.value = cash.contanti.value; 
                console.log('Valore: ' + props.chiusure.contanti.value); 
                
            }

            if (cash.assegni.value > 0) {
            //  console.log("Inseriamo chiusura pos: "+ cash.pos.value);
            cashData = {...cashData,  descrizione: 'chiusura assegni del ' + props.giorno, modo: 'MP02', valore: cash.assegni.value}                   
            cashData = cash.idAssegni !== '' ?  {...cashData, id: cash.idAssegni } : {...cashData} ;
            cash.idAssegni !== '' ? props.onModificaCash(cashData, props.token) : props.onAddCash(cashData, props.token);

            }

            if (cash.bonifici.value > 0) {
            //  console.log("Inseriamo chiusura pos: "+ cash.pos.value);
            cashData = {...cashData,  descrizione: 'chiusura bonifici del ' + props.giorno, modo: 'MP05', valore: cash.bonifici.value}                   
            cashData = cash.idBonifici !== '' ?  {...cashData, id: cash.idBonifici } : {...cashData} ;
            cash.idBonifici !== '' ? props.onModificaCash(cashData, props.token) : props.onAddCash(cashData, props.token);
            
            }
           
            props.toggle();
            
            
        }
    
        return (
            <Aux>
                <ModalHeader toggle={props.toggle}>{props.intestazione} </ModalHeader>
                <ModalBody>
                 <Form>
                 <Input  type="hidden" name="idPos" id="idPos" value={cash.idPos} />
                 <Input  type="hidden" name="idContanti" id="idContanti" value={cash.idContanti} />
                 <Input  type="hidden" name="idAssegni" id="idAssegni" value={cash.idAssegni} />
                 <Input  type="hidden" name="idBonifici" id="idBonifici" value={cash.idBonifici} />

                 
                <FormGroup row>
                <Col >
                    <InputGroup> 
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>POS-Carte </InputGroupText>
                        </InputGroupAddon>               
                        <Input  valid={(cash.pos.valid && cash.pos.value) ? true : false } invalid={!cash.pos.valid} type="number" name="pos" id="pos" value={cash.pos.value}   placeholder="POS e Carte" onChange={(event) => inputChangeHandler(event, "pos")}/>
                    </InputGroup>
                </Col>
                </FormGroup>

                <FormGroup row>
                <Col >
                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>Contanti </InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(cash.contanti.valid && cash.contanti.value) ? true : false  } invalid={!cash.contanti.valid} value={cash.contanti.value} type="number" name="contanti" id="contanti"  placeholder="Contanti" onChange={(event) => inputChangeHandler(event, "contanti")}/>
                                
                                </InputGroup>
                    
                    
                </Col>
                </FormGroup>

                <FormGroup row>
                <Col >
                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>Assegni </InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(cash.assegni.valid && cash.assegni.value) ? true : false } invalid={!cash.assegni.valid} value={cash.assegni.value}  type="number" name="assegni" id="assegni"  placeholder="Assegni" onChange={(event) => inputChangeHandler(event, "assegni")}/>
                                
                                </InputGroup>
                    
                    
                </Col>
                </FormGroup>

                <FormGroup row>
                <Col >
                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>Bonifico </InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(cash.bonifici.valid && cash.bonifici.value) ? true : false } invalid={!cash.bonifici.valid} value={cash.bonifici.value} type="number" name="bonifici" id="bonifici"  placeholder="Bonifici" onChange={(event) => inputChangeHandler(event, "bonifici")}/>
                                
                                </InputGroup>
                    
                    
                </Col>
                </FormGroup>
                
          
    </Form>
    </ModalBody>
    <ModalFooter>
        <Button color="primary" onClick={submitModificaCashHandler}>Modifica</Button> 
          <Button color="secondary" onClick={props.toggle}>Indietro</Button>
        </ModalFooter>
            </Aux>
        )
    
}

const mapStateToProps = (state) => ({
    giorno: state.foglio.giorno,
    loading: state.cash.loading,
    token: state.auth.token

})

const mapDispatchToProps = dispatch => {
    return {
        onFetchCash: (token, data) => dispatch(actions.fetchCash(token, data)),
        onAddCash: (cashData, token) => dispatch(actions.addCash(cashData, token)),
        onModificaCash: (cashData, token) => dispatch(actions.modificaCash(cashData, token)),
        onEliminaCash: (cashData, token) => dispatch(actions.eliminaCash(cashData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModificaChiusura);
