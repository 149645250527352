import React from 'react';
import classes from './Uscita.module.css';

const Uscita = props => {
    
    

        return (
            <tr key = {props.uscita.id} onClick={() => props.onClicked(props.uscita)} >
                <td className={classes.TableRow} style={{padding: "4px"}}>{props.uscita.tipo}</td>
                <td className={classes.TableRow} style={{padding: "4px"}}>{props.uscita.descrizione}</td>
                <td className={classes.TableRow} style={{textAlign:"right", padding: "4px"}}> {props.uscita.valore && parseFloat(props.uscita.valore).toFixed(2)}</td>
            </tr>
        )
    
}

export default Uscita;