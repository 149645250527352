import React from 'react'
//import classes from './Logo.module.css';
import logoOtticaPomezia from '../../assets/logo.png';

 const logo = (props) => {
    return (
        // <div className={classes.Logo}  >
        <img src={logoOtticaPomezia} alt='Ottica Pomezia' style={{height: '55px', cursor: 'pointer'}} onClick={props.clicked}/>
   // </div>
    )
}

export default logo;