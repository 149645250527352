import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateObject } from '../../shared/utility.js';
import * as actions from '../../store/actions/index';
// import { dataStringa } from '../../shared/utility';
import {
  Button,
  Form,
  FormGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Spinner,
  InputGroupText,
} from 'reactstrap';
import Aux from '../../hoc/Aux_/Aux.js';

const ModificaPagamento = (props) => {
  const {
    toggle,
    modifica,
    pagamentoData,
    onFetchCodPagamenti,
    onFetchFatture,
  } = props;

  const [pagamento, setPagamento] = useState({
    id: {
      value: pagamentoData && pagamentoData.id ? pagamentoData.id : '',
      validation: { required: false },
      valid: true,
      touched: false,
    },
    fattura: {
      value:
        pagamentoData && pagamentoData.fattura ? pagamentoData.fattura : '',
      validation: { required: false },
      valid: false,
      touched: false,
    },
    scadenza: {
      value:
        pagamentoData && pagamentoData.scadenza ? pagamentoData.scadenza : '',
      validation: { required: true },
      valid: false,
      touched: false,
    },
    importo: {
      value:
        pagamentoData && pagamentoData.importo ? pagamentoData.importo : '',
      validation: { required: true },
      valid: false,
      touched: false,
    },
    modalita: {
      value:
        pagamentoData && pagamentoData.modalita ? pagamentoData.modalita : '',
      validation: { required: true },
      valid: true,
      touched: false,
    },
    rata: {
      value: pagamentoData && pagamentoData.rata ? pagamentoData.rata : '',
      validation: { required: true },
      valid: true,
      touched: false,
    },
    dataPagamento: {
      value:
        pagamentoData && pagamentoData.dataPagamento
          ? pagamentoData.dataPagamento
          : '',
      validation: { required: false },
      valid: true,
      touched: false,
    },
  });

  let body = <Spinner />;

  //  console.log('scadenza: ' + pagamentoData.scadenza);

  useEffect(() => {
    onFetchCodPagamenti(props.token);
    onFetchFatture(props.token);
  }, [onFetchCodPagamenti, onFetchFatture, props.token]);

  if (
    !props.loadingCodPagamenti ||
    props.CodPagamenti?.length ||
    !props.loadingFatture ||
    props.Fatture?.length
  ) {
    body = '';
  }

  const checkValidity = (value, rules) => {
    let isValid = false;

    // console.log(rules);
    // console.log(value);

    if (rules.required) {
      isValid = value.trim() !== '';
    }

    if (!rules.required) {
      isValid = true;
    }

    return isValid;
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedFormElement = updateObject(pagamento[inputIdentifier], {
      value: event.target.value,
      touched: true,
      valid: checkValidity(
        event.target.value,
        pagamento[inputIdentifier].validation
      ),
    });

    const updatedPagamentoForm = updateObject(pagamento, {
      [inputIdentifier]: updatedFormElement,
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedPagamentoForm) {
      formIsValid = updatedPagamentoForm[inputIdentifier].valid && formIsValid;
    }
    setPagamento(updatedPagamentoForm);

    // setFormIsValid(formIsValid);
  };

  const addPagamentoHandler = (event) => {
    event.preventDefault();

    const pagamentoData = {};
    for (let formElementIdentifier in pagamento) {
      pagamentoData[formElementIdentifier] =
        pagamento[formElementIdentifier].value;
    }
    console.log(pagamentoData);
    props.onAddPagamento(pagamentoData, props.token);
    toggle();
  };

  const submitModificaPagamentoHandler = (event) => {
    event.preventDefault();
    const pagamentoData = {};
    for (let formElementIdentifier in pagamento) {
      pagamentoData[formElementIdentifier] =
        pagamento[formElementIdentifier].value;
    }
    //console.log(pagamentoData)
    props.onModificaPagamento(pagamentoData, props.token);
    toggle();
  };

  const submitEliminaPagamentoHandler = (event) => {
    event.preventDefault();
    const pagamentoData = {};
    for (let formElementIdentifier in pagamento) {
      pagamentoData[formElementIdentifier] =
        pagamento[formElementIdentifier].value;
    }
    //console.log(pagamentoData)
    props.onEliminaPagamento(pagamentoData, props.token);
    toggle();
  };

  return (
    <Aux>
      {body}

      <ModalHeader toggle={toggle}>
        {modifica ? 'MODIFICA FATTURA' : 'INSERISCI NUOVA FATTURA'}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={addPagamentoHandler}>
          <Input type='hidden' name='id' id='id' value={pagamento.id} />

          <FormGroup row>
            <Col>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>Fattura</InputGroupText>
                </InputGroupAddon>
                <Input
                  valid={
                    pagamento.fattura.valid && pagamento.fattura.touched
                      ? true
                      : null
                  }
                  invalid={
                    !pagamento.fattura.valid && pagamento.fattura.touched
                      ? true
                      : null
                  }
                  type='select'
                  name='fattura'
                  id='fattura'
                  placeholder='Fattura'
                  value={pagamento.fattura.value}
                  onBlur={(event) => inputChangedHandler(event, 'fattura')}
                  onChange={(event) => inputChangedHandler(event, 'fattura')}
                >
                  <option value=''>Seleziona la fattura...</option>
                  <option value='0' key='0'>
                    Fattura non emessa
                  </option>

                  {props.fatture
                    ? props.fatture
                        .sort((a, b) => (a.data > b.data ? -1 : 1))
                        .map((fattura) => (
                          <option value={fattura.id} key={fattura.id}>
                            {fattura.numero} del {fattura.data}
                          </option>
                        ))
                    : null}
                </Input>
              </InputGroup>
            </Col>
          </FormGroup>

          {/* <FormGroup row>
                        <Col >
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>Scadenza</InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(pagamento.scadenza.valid && pagamento.scadenza.touched ) ? true : null } 
                                invalid={(!pagamento.scadenza.valid && pagamento.scadenza.touched) ? true : null } 
                                type="date" name="scadenza" id="scadenza"  
                                placeholder="Scadenza pagamento" 
                                value={pagamento.scadenza.value} 
                                onBlur={(event) => inputChangedHandler(event, "scadenza")}
                                onChange={(event) => inputChangedHandler(event, "scadenza")}/>
                                </InputGroup>
                        </Col>
                </FormGroup> */}

          <FormGroup row>
            <Col>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>Scadenza</InputGroupText>
                </InputGroupAddon>
                <Input
                  valid={
                    pagamento.scadenza.valid && pagamento.scadenza.touched
                      ? true
                      : null
                  }
                  invalid={
                    !pagamento.scadenza.valid && pagamento.scadenza.touched
                      ? true
                      : null
                  }
                  type='date'
                  name='scadenza'
                  id='scadenza'
                  placeholder='Scadenza pagamento'
                  value={pagamentoData.scadenza}
                  onBlur={(event) => inputChangedHandler(event, 'scadenza')}
                  onChange={(event) => inputChangedHandler(event, 'scadenza')}
                />
              </InputGroup>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>Rata</InputGroupText>
                </InputGroupAddon>
                <Input
                  valid={
                    pagamento.rata.valid && pagamento.rata.touched ? true : null
                  }
                  invalid={
                    !pagamento.rata.valid && pagamento.rata.touched
                      ? true
                      : null
                  }
                  type='text'
                  name='rata'
                  id='rata'
                  placeholder='Rata'
                  value={pagamento.rata.value}
                  onBlur={(event) => inputChangedHandler(event, 'rata')}
                  onChange={(event) => inputChangedHandler(event, 'rata')}
                />
              </InputGroup>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>Importo</InputGroupText>
                </InputGroupAddon>
                <Input
                  valid={
                    pagamento.importo.valid && pagamento.importo.touched
                      ? true
                      : null
                  }
                  invalid={
                    !pagamento.importo.valid && pagamento.importo.touched
                      ? true
                      : null
                  }
                  type='number'
                  name='importo'
                  id='importo'
                  placeholder='Importo'
                  value={pagamento.importo.value}
                  onBlur={(event) => inputChangedHandler(event, 'importo')}
                  onChange={(event) => inputChangedHandler(event, 'importo')}
                />
              </InputGroup>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>Fattura</InputGroupText>
                </InputGroupAddon>
                <Input
                  valid={
                    pagamento.modalita.valid && pagamento.modalita.touched
                      ? true
                      : null
                  }
                  invalid={
                    !pagamento.modalita.valid && pagamento.modalita.touched
                      ? true
                      : null
                  }
                  type='select'
                  name='modalita'
                  id='modalita'
                  placeholder='Modalità'
                  value={pagamento.modalita.value}
                  onBlur={(event) => inputChangedHandler(event, 'modalita')}
                  onChange={(event) => inputChangedHandler(event, 'modalita')}
                >
                  <option value=''>Seleziona metodo pagmento...</option>
                  {props.codPagamenti
                    ? props.codPagamenti
                        .sort((a, b) =>
                          a.codicePagamento > b.codicePagamento ? -1 : 1
                        )
                        .map((codPagamento) => (
                          <option
                            value={codPagamento.codicePagamento}
                            key={codPagamento.id}
                          >
                            {codPagamento.tipoPagamento}
                          </option>
                        ))
                    : null}
                </Input>
              </InputGroup>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>Data pagamento</InputGroupText>
                </InputGroupAddon>
                <Input
                  valid={
                    pagamento.dataPagamento.valid &&
                    pagamento.dataPagamento.touched
                      ? true
                      : null
                  }
                  invalid={
                    !pagamento.dataPagamento.valid &&
                    pagamento.dataPagamento.touched
                      ? true
                      : null
                  }
                  type='date'
                  name='dataPagamento'
                  id='dataPagamento'
                  placeholder='Data pagamento'
                  value={pagamento.dataPagamento.value}
                  onBlur={(event) =>
                    inputChangedHandler(event, 'dataPagamento')
                  }
                  onChange={(event) =>
                    inputChangedHandler(event, 'dataPagamento')
                  }
                />
              </InputGroup>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        {modifica ? (
          <Button color='primary' onClick={submitModificaPagamentoHandler}>
            Modifica
          </Button>
        ) : (
          <Button color='primary' onClick={addPagamentoHandler}>
            Inserisci
          </Button>
        )}{' '}
        {modifica ? (
          <Button color='danger' onClick={submitEliminaPagamentoHandler}>
            Elimina
          </Button>
        ) : null}{' '}
        <Button color='secondary' onClick={toggle}>
          Indietro
        </Button>
      </ModalFooter>
    </Aux>
  );
};

const mapStateToProps = (state) => {
  return {
    fatture: state.fatture.fatture,
    loadingFatture: state.fatture.loading,
    codPagamenti: state.codPagamenti.codPagamenti,
    loadingCodPagamenti: state.codPagamenti.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCodPagamenti: (token) => dispatch(actions.fetchCodPagamenti(token)),
    onFetchFatture: (token) => dispatch(actions.fetchFatture(token)),
    onAddPagamento: (pagamentoData, token) =>
      dispatch(actions.addPagamento(pagamentoData, token)),
    onModificaPagamento: (pagamentoData, token) =>
      dispatch(actions.modificaPagamento(pagamentoData, token)),
    onEliminaPagamento: (pagamentoData, token) =>
      dispatch(actions.eliminaPagamento(pagamentoData, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModificaPagamento);
