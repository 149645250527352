import React, { Component } from 'react';

import classes from './FoglioVendite.module.css';
import Aux from '../../hoc/Aux_/Aux';
import Vendite from '../../components/Vendite/Vendite';
import Modal from '../../components/UI/Modal/Modal';
import BottoneVendita from '../../components/UI/BottoneVendita/BottoneVendita';
import NuovaVendita from '../../components/NuovaVendita/NuovaVendita';
import ModificaVendita from '../../components/ModificaVendita/ModificaVendita';
import { dataStringa, dataIntera } from '../../shared/utility.js';
import axios from '../../hoc/axios-vendite';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { manager } from '../../shared/authorization';

const oggi = new Date();
const nuovaData = new Date();
nuovaData.setDate(oggi.getDate() + 150);

class FoglioVendite extends Component {

   
    

  state = {
    inserisciVendita: false,
    modificaVendita: false,
    dataFoglio: this.props.giorno ,
    vendite: [],
    loading: true,
    totaleGiornaliero: 0,
    idVendita: null,
  };

 

  componentDidMount() {
    // console.log(this.props.match.params.id);
    this.aggiornaVendite();
    
  }

  componentDidUpdate() {
    // console.log(this.props.match.params.id);
    if (dataStringa(this.state.dataFoglio) !== dataStringa(this.props.giorno) ) {
      this.aggiornaVendite();
   //   console.log('coponent did update ',this.props.giorno, ' dataStringa: ', this.state.dataFoglio )
    }
  }

  

  aggiornaVendite = (dataFine = null) => {
    if (Date.parse(this.props.match.params.id) > 0) {
   //   console.log(this.props.match.params.id);
    }


    //console.log('FOGLIO VENDITA...: ',this.props.giorno, ' dataStringa: ', this.state.dataFoglio );

   this.setState({ dataFoglio: this.props.giorno });

    if (!dataFine) {
      dataFine =
        this.props.giorno.getFullYear() +
        '-' +
        ('0' + (this.props.giorno.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + (this.props.giorno.getDate()+1)).slice(-2);

       // console.log('Data Fine ',dataFine);
    }
    let dataInizio =
      this.props.giorno.getFullYear() +
      '-' +
      ('0' + (this.props.giorno.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + this.props.giorno.getDate()).slice(-2);

    // let queryString = 'https://foglio-vendite-dev.firebaseio.com/vendite.json?orderBy="dataVendita"&startAt="' + dataInizio + '"&endAt="' + dataFine + '"';
    // console.log(queryString);

   // this.props.onSetGiorno(dataInizio);

    axios
      .get(
        'https://foglio-vendite-dev.firebaseio.com/vendite.json?auth=' +
          this.props.token +
          '&orderBy="dataVendita"&startAt="' +
          dataInizio +
          '"&endAt="' +
          dataFine +
          '"'
      )
      // axios.get('https://foglio-vendite-dev.firebaseio.com/vendite.json?orderBy="dataVendita"&startAt="2020-505"&endAt="2020-5-6"')
      .then((response) => {
        const fetchedVendite = [];
        const fetchedVenditeVista = [];
        const fetchedVenditeLac = [];
        const fetchedVenditeLiquido = [];
        const fetchedVenditeAltro = [];
        let totaleUpdated = 0;

        const venditeVista = [
          'occhialeCompleto',
          'occhialeSole',
          'occhialeCompletoSole',
          'montatura',
          'lenti',
        ];
        for (let key in response.data) {
          if (venditeVista.indexOf(response.data[key]['tipoVendita']) >= 0) {
            //console.log(totaleUpdated);
            totaleUpdated =
              totaleUpdated * 1 +
              (response.data[key]['prezzoMontatura']
                ? parseFloat(response.data[key]['prezzoMontatura']) * 1
                : 0) +
              (response.data[key]['prezzoLenti']
                ? response.data[key]['prezzoLenti'] * 1
                : 0);
            fetchedVenditeVista.push({
              ...response.data[key],
              id: key,
            });
          } else if (response.data[key]['tipoVendita'] === 'lac') {
            totaleUpdated += response.data[key]['prezzoLac']
              ? parseFloat(response.data[key]['prezzoLac'])
              : 0;
            fetchedVenditeLac.push({
              ...response.data[key],
              id: key,
            });
          } else if (response.data[key]['tipoVendita'] === 'liquido') {
            totaleUpdated += response.data[key]['prezzoLiquido']
              ? parseFloat(response.data[key]['prezzoLiquido'])
              : 0;
            fetchedVenditeLiquido.push({
              ...response.data[key],
              id: key,
            });
          } else if (response.data[key]['tipoVendita'] === 'altro') {
            totaleUpdated += response.data[key]['prezzoAltro']
              ? parseFloat(response.data[key]['prezzoAltro'])
              : 0;
            fetchedVenditeAltro.push({
              ...response.data[key],
              id: key,
            });
          }
        }
        fetchedVendite.push(
          fetchedVenditeVista,
          fetchedVenditeLac,
          fetchedVenditeLiquido,
          fetchedVenditeAltro
        );

        this.setState({
          loading: false,
          vendite: fetchedVendite,
          totaleGiornaliero: totaleUpdated,
        });
        //console.log(this.state.vendite)
      });
  };

  nuovaVenditaHandler = () => {
    this.setState({ inserisciVendita: true });
    console.log('inserici Vendita: '+ this.state.inserisciVendita)
    console.log('Data Vendita: '+ this.state.dataFoglio);
  };

  chiudiInserimentoVenditaHandler = () => {
    this.setState({ inserisciVendita: false });
    this.aggiornaVendite();
  };

  chiudiModificaVenditaHandler = () => {
    this.setState({ modificaVendita: false });
    this.aggiornaVendite();
  };

  cancellaInserimentoVenditaHandler = () => {
    this.setState({ inserisciVendita: false });
  };

  cancellaModificaVenditaHandler = () => {
    this.setState({ modificaVendita: false });
  };

  onClickVenditaHandler = (id) => {
    this.setState({ modificaVendita: true, idVendita: id });
  };

  render() {

    // console.log('this.props.giorno',this.props.giorno);
    // console.log('this.state.dataFoglio',this.state.dataFoglio);
    let gg, mm, aaaa;
    gg = this.props.giorno.getDate();
    mm = this.props.giorno.getMonth() + 1;
    aaaa = this.props.giorno.getFullYear();
    let oggiData =
      oggi.getUTCFullYear() +
      '-' +
      (oggi.getMonth() + 1) +
            '-' +
      ('0' + oggi.getDate()).slice(-2);
    let giornoData =
      this.props.giorno.getUTCFullYear() +
      '-' +
      (this.props.giorno.getMonth() + 1) +
      '-' +
      ('0' + this.props.giorno.getDate()).slice(-2);
    //  console.log("Data di oggi " + oggiData + " vs " + giornoData);
    const intestazione =
      giornoData === oggiData
        ? 'Vendite di oggi ' + dataIntera(this.props.giorno)
        : 'Vendite di  ' + dataIntera(this.props.giorno);

    const intestazioneCell =
      this.props.giorno === oggiData
        ? 'Vendite di oggi ' + gg + '/' + mm + '/' + aaaa
        : 'Vendite del ' + gg + '/' + mm + '/' + aaaa;

    //let vendite =

    let listeVendite = '';

    if (this.state.loading === false) {
      listeVendite = (
        <div className={classes.ContenitoreVendite}>
          <Vendite
            tipoVendita='vista'
            vendite={this.state.vendite}
            onClicked={this.onClickVenditaHandler}
          />
          <Vendite
            tipoVendita='lac'
            vendite={this.state.vendite}
            onClicked={this.onClickVenditaHandler}
          />
          <Vendite
            tipoVendita='liquidi'
            vendite={this.state.vendite}
            onClicked={this.onClickVenditaHandler}
          />
          <Vendite
            tipoVendita='altro'
            vendite={this.state.vendite}
            onClicked={this.onClickVenditaHandler}
          />
        </div>
      );
    }

    return (
      <Aux>
        <Modal
          show={this.state.inserisciVendita}
          modalClosed={this.cancellaInserimentoVenditaHandler}
        >
          <NuovaVendita
            dataVendita={this.state.dataFoglio}
            modalClosed={this.chiudiInserimentoVenditaHandler}
          />
        </Modal>

        <Modal
          show={this.state.modificaVendita}
          modalClosed={this.cancellaModificaVenditaHandler}
        >
          {this.state.idVendita ? (
            <ModificaVendita
              idVendita={this.state.idVendita}
              modalClosed={this.chiudiModificaVenditaHandler}
            />
          ) : null}
        </Modal>
        <div className={classes.Titolo}>
          {intestazione}

          {manager.includes(this.props.userId) && (
            <p className={classes.TotaleGiornaliero}>
              Totale: {parseFloat(this.state.totaleGiornaliero).toFixed(2)}{' '}
            </p>
          )}
        </div>
        <div className={classes.TitoloCell}>
          {intestazioneCell}
          <p className={classes.TotaleGiornaliero}>
            Totale: {parseFloat(this.state.totaleGiornaliero).toFixed(2)}
          </p>
        </div>

        {!this.state.inserisciVendita && (
          <BottoneVendita clicked={this.nuovaVenditaHandler} />
        )}
        {listeVendite}
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    giorno: new Date(state.foglio.giorno),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetGiorno: (giorno) => dispatch(actions.setGiorno(giorno)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FoglioVendite);
