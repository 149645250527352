import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { NavLink, NavItem } from 'reactstrap';

//import classes from './NavigationItem.module.css';

const navigationItem = (props) => (

    

    <NavItem>
        <NavLink  onClick={props.clicked}
            tag={RRNavLink}
            to={props.link}
            exact={props.exact}
            activeStyle={{
                fontWeight: "bold",
                borderBottom: "1px solid #007bff",
                color: "#fff",
                backgroundColor: "#007bff",
                borderRadius: "1000px"
              }} >
                {props.children}
            
            {/* {props.img && <img src={props.img} style={{height: "55px"}} alt='calendario'/>} */}
        </NavLink>
    </NavItem>

);

export default navigationItem;