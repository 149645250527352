import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    codPagamenti: [],
    loading: false,
    added: false
   
};

const addCodPagamentoInit = ( state, action ) => {
    return updateObject(state, {added: false });
}

const addCodPagamentoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const addCodPagamentoSuccess = ( state, action ) => {
    const newCodPagamento = {
        ...action.codPagamentoData,
        id:action.codPagamentoId,
        }    
    return updateObject(state, {
            loading: false,
            codPagamenti: state.codPagamenti.concat(newCodPagamento),
            added: true
        });
}

const addCodPagamentoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const modificaCodPagamentoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const modificaCodPagamentoSuccess = ( state, action ) => {
    const newCodPagamento = {
        ...action.codPagamentoData,
        id:action.codPagamentoId,
        }    
    return updateObject(state, {
            loading: false,
            codPagamenti: state.codPagamenti.concat(newCodPagamento),
            added: true
        });
}

const modificaCodPagamentoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//elimina codPagamento
const eliminaCodPagamentoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const eliminaCodPagamentoSuccess = ( state, action ) => {
    // const newCodPagamento = {
    //     ...action.codPagamentoData,
    //     id:action.codPagamentoId,
    //     }    
    return updateObject(state, {
            loading: false,
            codPagamenti: state.codPagamenti,
            added: true
        });
}

const eliminaCodPagamentoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch codPagamenti
const fetchCodPagamentiStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchCodPagamentiSuccess = ( state, action ) => {
    return updateObject(state,{
        codPagamenti: action.codPagamenti,
        loading: false});
}

const fetchCodPagamentiFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.ADD_COD_PAGAMENTO_INIT: return addCodPagamentoInit( state, action );
        case actionTypes.ADD_COD_PAGAMENTO_START: return addCodPagamentoStart(state, action );            
        case actionTypes.ADD_COD_PAGAMENTO_SUCCESS: return addCodPagamentoSuccess( state, action);
        case actionTypes.ADD_COD_PAGAMENTO_FAIL: return addCodPagamentoFail (state, action);
        case actionTypes.MODIFICA_COD_PAGAMENTO_START: return modificaCodPagamentoStart(state, action );            
        case actionTypes.MODIFICA_COD_PAGAMENTO_SUCCESS: return modificaCodPagamentoSuccess( state, action);
        case actionTypes.MODIFICA_COD_PAGAMENTO_FAIL: return modificaCodPagamentoFail (state, action);
        case actionTypes.ELIMINA_COD_PAGAMENTO_START: return eliminaCodPagamentoStart(state, action );            
        case actionTypes.ELIMINA_COD_PAGAMENTO_SUCCESS: return eliminaCodPagamentoSuccess( state, action);
        case actionTypes.ELIMINA_COD_PAGAMENTO_FAIL: return eliminaCodPagamentoFail (state, action);
        case actionTypes.FETCH_COD_PAGAMENTI_START: return fetchCodPagamentiStart(state, action);
        case actionTypes.FETCH_COD_PAGAMENTI_SUCCESS: return fetchCodPagamentiSuccess(state, action);
        case actionTypes.FETCH_COD_PAGAMENTI_FAIL:return fetchCodPagamentiFail(state, action);
        default: return state;

        }
}

export default reducer;
