import React, { useEffect} from 'react';
import { Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect} from 'react-redux';
import { manager } from './shared/authorization';
import './App.css';
import  Layout  from './hoc/Layout/Layout';
import FoglioVendite from './containers/FoglioVendite/FoglioVendite';
import ScegliGiorno from './components/ScegliGiorno/ScegliGiorno';
// import MarcheMontature from './components/DbData/MarcheMontature';
import Auth from './containers/Auth/Auth';
import * as actions from './store/actions/index';
import Logout from './containers/Auth/Logout/Logout';
import Oggi from './components/ScegliGiorno/Oggi';
import Marche from './components/Marche/Marche';
import Flussi from './components/Flussi/Flussi';
import Fornitori from './components/Fornitori/Fornitori';
import Fatture from './components/Fatture/Fatture';
import Pagamenti from './components/Pagamenti/Pagamenti';
import CodPagamenti from './components/CodPagamenti/CodPagamenti';
import CaricaFattura from './components/XmlReader/XmlReader';
import Stats from './containers/Stats/Stats';
import VenditeVista from './containers/Stats/VenditeVista';
import VenditeSole from './containers/Stats/VenditeSole';
import Cashflow from './containers/Cashflow/Cashflow';
import Contabilita from './containers/Contabilita/Contabilita';

const  App = props => {

  const { onTryAutoLogin } = props;
  useEffect(() => {
    onTryAutoLogin();
  }, [onTryAutoLogin] );


  let routes = (
    <Switch>
          <Route path="/login"  component={Auth} /> 
          <Redirect to="/login" />
    </Switch>
  );

  if (props.isAuthenticated){

    routes = (
    <Switch>
          <Route path="/logout"  component={Logout} /> 
           
          
          <Route path="/pagamenti"  component={Pagamenti} />
          {manager.includes(props.userId) ? <Route path="/stats/stats"  component={Stats}/> : ''}
          {manager.includes(props.userId) ? <Route path="/stats/venditeVista"  component={VenditeVista}/> : ''}
          {manager.includes(props.userId) ? <Route path="/stats/venditeSole"  component={VenditeSole}/> : ''}
          <Route path="/cashflow"  component={Cashflow} />
          <Route path="/scegli-giorno"  component={ScegliGiorno} />
          <Route path="/contabilita/carica-fattura"  component={CaricaFattura} />
          <Route path="/contabilita/fatture"  component={Fatture} />
          <Route path="/contabilita/pagamenti"  component={Pagamenti} />
          <Route path="/contabilita/fornitori"  component={Fornitori} />
          <Route path="/contabilita"  component={Contabilita} />
          <Route path="/tabelle/codici-pagamento"  component={CodPagamenti} />
          <Route path="/tabelle/flussi"  component={Flussi} /> 
          <Route path="/tabelle/marche"  component={Marche} /> 
          <Route path="/oggi"  component={Oggi} />
          <Route path="/:id"  component={FoglioVendite} />
          <Route path="/" exact component={FoglioVendite} />
          
      </Switch>
    );

  }
  
  return (
    <div className="App">

      <Layout >
      
      {routes}
       
      </Layout>
      
    </div>
  );
}
const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userId: state.auth.userId 
  }
}

const mapDispatchToProps = dispatch =>{
    return {
        onTryAutoLogin: () =>dispatch(actions.authCheckState())
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
