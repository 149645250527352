import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateObject } from '../../shared/utility.js';
import * as actions from '../../store/actions/index';
import {Button, Form, FormGroup,  ModalBody, ModalFooter, ModalHeader,
    Col,Input, InputGroup, InputGroupAddon, Spinner,
     InputGroupText} from 'reactstrap';
import Aux from '../../hoc/Aux_/Aux.js';


const ModificaFattura  = props => {

    const {
        toggle,
        modifica,
        fatturaData,
        onFetchFornitori
      } = props;

    const [ fattura, setFattura] = useState({
        id:   { value: fatturaData && fatturaData.id ? fatturaData.id : '',
                validation: { required: false},
                valid: true,
                touched: false},
        numero: { value: fatturaData && fatturaData.numero ? fatturaData.numero : '',
                validation: { required: true},
                valid: false,
                touched: false},
        data: { value: fatturaData && fatturaData.data ? fatturaData.data : '',
                validation: { required: true},
                valid: false,
                touched: false},
        fornitore: { value: fatturaData && fatturaData.fornitore ? fatturaData.fornitore : '',
                    validation: { required: true},
                    valid: false,
                touched: false},
        importo: { value: fatturaData && fatturaData.importo ? fatturaData.importo : '',
                    validation: { required: true},
                    valid: true,
                touched: false},
        imponibile: { value: fatturaData && fatturaData.imponibile ? fatturaData.imponibile : '',
                    validation: { required: true},
                    valid: true,
                touched: false},
        iva: { value: fatturaData && fatturaData.iva ? fatturaData.iva : '',
                    validation: { required: true},
                    valid: true,
                touched: false}
        })

        let body = <Spinner />;

        useEffect( () => {
    
            onFetchFornitori(props.token);
            
            }, [onFetchFornitori, props.token] );
    
         if (!props.loadingFornitori || (props.fornitori?.length) ){
            body = '';
            }

   
        const checkValidity = (value, rules) => {
            let isValid = false;

            // console.log(rules);
            // console.log(value);
         
            if (rules.required) {
                isValid = value.trim() !== '';
            }

            if (!rules.required) {
                isValid = true;
            }

            return isValid
        }

        const inputChangedHandler = (event, inputIdentifier) => { 
        

            const updatedFormElement = updateObject(fattura[inputIdentifier], {
                value: event.target.value,
                touched: true,
                valid: checkValidity(event.target.value, fattura[inputIdentifier].validation),
                
      
            } );
      
            const updatedFatturaForm = updateObject(fattura,{
                [inputIdentifier]: updatedFormElement
            });
            
            let formIsValid = true;
            for (let inputIdentifier in updatedFatturaForm) {
                formIsValid = updatedFatturaForm[inputIdentifier].valid && formIsValid;
            }
            setFattura(updatedFatturaForm);
         
           // setFormIsValid(formIsValid);
        }

            const addFatturaHandler = ( event ) => {
                event.preventDefault();
                
                const fatturaData = {};
                      for (let formElementIdentifier in fattura) {
                        fatturaData[formElementIdentifier] = fattura[formElementIdentifier].value;
                      }
                console.log(fatturaData);
                props.onAddFattura(fatturaData, props.token);
                toggle();
              }
  
        const submitModificaFatturaHandler = ( event ) => {
                event.preventDefault();
                const fatturaData = {};
                      for (let formElementIdentifier in fattura) {
                        fatturaData[formElementIdentifier] = fattura[formElementIdentifier].value;
                      }
                //console.log(fatturaData)
                props.onModificaFattura(fatturaData, props.token);
                toggle();
              }
  
        const submitEliminaFatturaHandler = ( event ) => {
                event.preventDefault();
                const fatturaData = {};
                      for (let formElementIdentifier in fattura) {
                        fatturaData[formElementIdentifier] = fattura[formElementIdentifier].value;
                      }
                //console.log(fatturaData)
                props.onEliminaFattura(fatturaData, props.token);
                toggle();
              }
    

        return (
            <Aux>
                    { body }

              <ModalHeader toggle={toggle}>{modifica ? 'MODIFICA FATTURA' : 'INSERISCI NUOVA FATTURA'}</ModalHeader>
        <ModalBody>
        <Form onSubmit={addFatturaHandler}>
                      <Input  type="hidden" name="id" id="id" value={fattura.id} />
                                
                 <FormGroup row>
                        <Col >
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>NUMERO</InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(fattura.numero.valid && fattura.numero.touched ) ? true : null } 
                                invalid={(!fattura.numero.valid && fattura.numero.touched) ? true : null } 
                                type="text" name="numero" id="numero"  
                                placeholder="Numero fattura" 
                                value={fattura.numero.value} 
                                onBlur={(event) => inputChangedHandler(event, "numero")}
                                onChange={(event) => inputChangedHandler(event, "numero")}/>
                                </InputGroup>
                        </Col>
                </FormGroup>

                <FormGroup row>
                        <Col >
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>DATA</InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(fattura.data.valid && fattura.data.touched ) ? true : null } 
                                invalid={(!fattura.data.valid && fattura.data.touched) ? true : null}  
                                 type="date" name="data" id="data"  placeholder="Data" value={fattura.data.value} 
                                 onBlur={(event) => inputChangedHandler(event, "data")}
                                 onChange={(event) => inputChangedHandler(event, "data")}/>
                                </InputGroup>
                        </Col>
                </FormGroup>

                <FormGroup row>
                        <Col >
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>FORNITORE</InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(fattura.fornitore.valid && fattura.fornitore.touched ) ? true : null }
                                invalid={(!fattura.fornitore.valid && fattura.fornitore.touched) ? true : null }  
                                type="select" name="fornitore" id="fornitore"  placeholder="Fornitore" value={fattura.fornitore.value} 
                                onBlur={(event) => inputChangedHandler(event, "fornitore")}
                                onChange={(event) => inputChangedHandler(event, "fornitore")}>
                                         <option value='' >Seleziona il fornitore...</option>
                                        {props.fornitori ? 
                                            props.fornitori
                                            .sort((a, b) => (a.denominazione > b.denominazione) ? -1 : 1)
                                            .map( fornitore => (                                                        
                                                        <option value={fornitore.codiceFiscale} key={fornitore.id}  >{fornitore.denominazione}</option>
                                                        ) 
                                                ) : null }
                                </Input>
                                </InputGroup>
                        </Col>
                </FormGroup>

                <FormGroup row>
                        <Col >
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>IMPORTO TOTALE</InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(fattura.importo.valid && fattura.importo.touched ) ? true : null } 
                                invalid={(!fattura.importo.valid && fattura.importo.touched) ? true : null }   
                                type="number" name="importo" id="importo"  placeholder="Importo totale" value={fattura.importo.value} 
                                onBlur={(event) => inputChangedHandler(event, "importo")}
                                onChange={(event) => inputChangedHandler(event, "importo")}/>
                                </InputGroup>
                        </Col>
                </FormGroup>

                <FormGroup row>
                        <Col >
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>IMPONIBILE</InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(fattura.imponibile.valid && fattura.imponibile.touched ) ? true : null } 
                                invalid={(!fattura.imponibile.valid && fattura.imponibile.touched) ? true : null }  
                                type="number" name="imponibile" id="imponibile"  placeholder="Imponibile" value={fattura.imponibile.value} 
                                onBlur={(event) => inputChangedHandler(event, "imponibile")}
                                onChange={(event) => inputChangedHandler(event, "imponibile")}/>
                                </InputGroup>
                        </Col>
                </FormGroup>

                <FormGroup row>
                        <Col >
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>IVA</InputGroupText>
                                </InputGroupAddon>
                                <Input valid={(fattura.iva.valid && fattura.iva.touched ) ? true : null } 
                                invalid={(!fattura.iva.valid && fattura.iva.touched) ? true : null }  
                                type="number" name="iva" id="iva"  placeholder="Iva" value={fattura.iva.value} 
                                onBlur={(event) => inputChangedHandler(event, "iva")}
                                onChange={(event) => inputChangedHandler(event, "iva")}/>
                                </InputGroup>
                        </Col>
                </FormGroup>
           
          
    </Form>
        </ModalBody>
        <ModalFooter>
        {modifica ? <Button color="primary" onClick={submitModificaFatturaHandler}>Modifica</Button> : <Button color="primary" onClick={addFatturaHandler}>Inserisci</Button>}{' '}
        {modifica ? <Button color="danger" onClick={submitEliminaFatturaHandler}>Elimina</Button> : null}{' '}
          <Button color="secondary" onClick={toggle}>Indietro</Button>
        </ModalFooter>
        </Aux>
        )
    
}

const mapStateToProps = (state) => {
    return {
        fornitori: state.fornitori.fornitori,
        loadingFornitori: state.fornitori.loading,
        fatture: state.fatture.fatture,
        loading: state.fatture.loading,
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchFornitori: (token) => dispatch(actions.fetchFornitori(token)),
        onFetchFatture: (token) => dispatch(actions.fetchFatture(token)),
        onAddFattura: (fatturaData, token) => dispatch(actions.addFattura(fatturaData, token)),
        onModificaFattura: (fatturaData, token) => dispatch(actions.modificaFattura(fatturaData, token)),
        onEliminaFattura: (fatturaData, token) => dispatch(actions.eliminaFattura(fatturaData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModificaFattura);
