import React from 'react';
import classes from './Vendita.module.css';



const vendita = props => {

   // console.log(props.venditaData)
        
    const imgUrl =  require('../../../assets/' + props.venditaData.tipoVendita + '.png') ;

    

        return (

            <tr onClick={() => props.onClicked(props.id) }><td><img className={classes.ImmagineVendita} src={imgUrl} alt={props.venditaData.tipoVendita} /> 
            {props.venditaData.marcaMontatura && props.venditaData.marcaMontatura} {props.venditaData.tipoVendita === 'lenti' && 'Lenti'} {props.venditaData.tipoLenti && props.venditaData.tipoLenti}
            {props.venditaData.marcaLac && props.venditaData.marcaLac} {props.venditaData.tipoLac && props.venditaData.tipoLac}  
            {props.venditaData.tipoLiquido && props.venditaData.tipoLiquido} 
            {props.venditaData.altro && props.venditaData.altro}  </td>
            <td className={classes.PrezziVendita}>{''}{props.venditaData.prezzoMontatura &&  parseFloat(props.venditaData.prezzoMontatura).toFixed(2)}</td>
            <td className={classes.PrezziVendita}>
                {props.venditaData.prezzoLenti && parseFloat(props.venditaData.prezzoLenti).toFixed(2)}
                {props.venditaData.prezzoLac && parseFloat(props.venditaData.prezzoLac).toFixed(2)}
                {props.venditaData.prezzoLiquido && parseFloat(props.venditaData.prezzoLiquido).toFixed(2)}
                {props.venditaData.prezzoAltro&& parseFloat(props.venditaData.prezzoAltro).toFixed(2)}
               
               </td></tr>
        ) 
            
            
        
    
}

export default vendita;