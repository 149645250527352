import React from 'react';
import classes from './BottoneVendita.module.css';

const bottoneVendita = (props) => {
   

        return (
            <div className={classes.ButtonAdd} onClick={props.clicked} />
        )
}

export default bottoneVendita;