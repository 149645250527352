import React, { Component} from 'react';
import Calendario from '../UI/Calendario/Calendario';
import { Button } from 'reactstrap';
import { Redirect} from 'react-router-dom';
import { dataIntera } from '../../shared/utility.js';

class ScegliGiorno extends Component  {

    state = { today: new Date(),
              nuovaData: new Date(),
              referrer: null,
            }

            handleChange = date => {
                    this.setState({nuovaData: date});
            };

            redirectHandler = (date) => { 
                        //console.log('Button is cliked! ' + date);
                        this.setState({referrer: '/'+ date});
                    
            }

    render (){

        
        let dataFormattata = this.state.nuovaData.getFullYear() + '-' +  ("0" + (this.state.nuovaData.getMonth() + 1)).slice(-2) + '-' + ("0" + this.state.nuovaData.getDate()).slice(-2);

        let dataFormattataIntera = dataIntera(this.state.nuovaData);
       
        const {referrer} = this.state;
        if (referrer) return <Redirect to={referrer} />;

return (

            <div><p>
                <Button color="primary" onClick={() => this.redirectHandler(dataFormattata)} >{dataFormattataIntera}</Button>
                </p>
                <Calendario data={this.state.today} visibile={true} changed={(date) => this.handleChange(date)}/>
                
            </div>
        )
     }
}

export default ScegliGiorno;

            