import * as actionTypes from './actionTypes';
import axios from '../../hoc/axios-vendite';

//create add flusso Start action
export const addFlussoStart= () => {
    return  {
        type: actionTypes.ADD_FLUSSO_START
        };
    };
    
export const addFlussoSuccess = (id,flussoData) => {
        return {

        type: actionTypes.ADD_FLUSSO_SUCCESS,
        flussoId: id,
        flussoData: flussoData
    }
}

export const addFlussoFail= (error) => {
    return {
        type: actionTypes.ADD_FLUSSO_FAIL,
        error: error
    }
}

export const addFlusso = (flussoData, token) => {
    return dispatch => {
        dispatch(addFlussoStart());
       // console.log('sto chiamando axios')
        axios.post( '/flussi.json?auth=' + token, flussoData )
            .then( response => {
                console.log('response.data.name');
               console.log(response.data.name);
                dispatch( addFlussoSuccess(response.data.name, flussoData))
                //console.log(response.data.name)
                dispatch(fetchFlussi(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( addFlussoFail(error));
               
            } );
    }
}

export const addFlussoInit = () => {
    return  {
        type: actionTypes.ADD_FLUSSO_INIT
        };
    };

//create add flusso Start action
export const modificaFlussoStart= () => {
    return  {
        type: actionTypes.MODIFICA_FLUSSO_START
        };
    };
    
export const modificaFlussoSuccess = (id,flussoData) => {
        return {

        type: actionTypes.MODIFICA_FLUSSO_SUCCESS,
        flussoId: id,
        flussoData: flussoData
    }
}

export const modificaFlussoFail= (error) => {
    return {
        type: actionTypes.MODIFICA_FLUSSO_FAIL,
        error: error
    }
}

export const modificaFlusso = (flussoData, token) => {
    return dispatch => {
        dispatch(modificaFlussoStart());
        console.log('sto chiamando axios')
        axios.patch( '/flussi/' +  flussoData.id + '.json?auth=' + token, flussoData )
            .then( response => {
                console.log("response.data.name");
                console.log(response.data.id);
                dispatch( modificaFlussoSuccess(flussoData.id, flussoData))
                //console.log(response.data.name)
                dispatch(fetchFlussi(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( modificaFlussoFail(error));
               
            } );
    }
}

//create elimina flusso Start action
export const eliminaFlussoStart= () => {
    return  {
        type: actionTypes.ELIMINA_FLUSSO_START
        };
    };
    
export const eliminaFlussoSuccess = (flussoData) => {
        return {

        type: actionTypes.ELIMINA_FLUSSO_SUCCESS,
        flussoId: flussoData.id
    }
}

export const eliminaFlussoFail= (error) => {
    return {
        type: actionTypes.ELIMINA_FLUSSO_FAIL,
        error: error
    }
}

export const eliminaFlusso = (flussoData, token) => {
    return dispatch => {
        dispatch(eliminaFlussoStart());
        console.log('sto chiamando axios per cancellare')
        axios.delete( '/flussi/' +  flussoData.id + '.json?auth=' + token, flussoData )
            .then( response => {
                dispatch( eliminaFlussoSuccess(flussoData))
                //console.log(response.data.name)
                dispatch(fetchFlussi(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( eliminaFlussoFail(error));
               
            } );
    }
}

export const fetchFlussiStart = () => {
    return {
        type: actionTypes.FETCH_FLUSSI_START
    };
};

export const fetchFlussiSuccess = (flussi) => {
    return {
        type: actionTypes.FETCH_FLUSSI_SUCCESS,
        flussi : flussi        
    };
};

export const fetchFlussiFail = (error) => {
    return {
        type: actionTypes.FETCH_FLUSSI_FAIL,
        error: error
    };
};

export const fetchFlussi= (token) => {
   return dispatch => {
        dispatch(fetchFlussiStart());
        const queryParams = '?auth=' + token ;
        axios.get('/flussi.json' + queryParams )
    .then(res => {
        const fetchedFlussi=[];
        for (let key in res.data) {
            fetchedFlussi.push({
                ...res.data[key],
                id: key
            });
        }
        dispatch(fetchFlussiSuccess(fetchedFlussi))
        
    })
    .catch(err => {
        dispatch(fetchFlussiFail(err))        
    });
    }
}

