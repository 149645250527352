import * as actionTypes from './actionTypes';
import axios from '../../hoc/axios-vendite';

//create add cash Start action
export const addCashStart= () => {
    return  {
        type: actionTypes.ADD_CASH_START
        };
    };
    
export const addCashSuccess = (id,cashData) => {
        return {

        type: actionTypes.ADD_CASH_SUCCESS,
        cashId: id,
        cashData: cashData
    }
}

export const addCashFail= (error) => {
    return {
        type: actionTypes.ADD_CASH_FAIL,
        error: error
    }
}

export const addCash = (cashData, token) => {
    return dispatch => {
        dispatch(addCashStart());
       // console.log('sto chiamando axios')
        axios.post( '/cash.json?auth=' + token, cashData )
            .then( response => {
               // console.log(response.data.name)
                dispatch( addCashSuccess(response.data.name, cashData))
                //console.log(response.data.name)
                dispatch(fetchCash(token, cashData.data))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( addCashFail(error));
               
            } );
    }
}

export const addCashInit = () => {
    return  {
        type: actionTypes.ADD_CASH_INIT
        };
    };

//create add cash Start action
export const modificaCashStart= () => {
    return  {
        type: actionTypes.MODIFICA_CASH_START
        };
    };
    
export const modificaCashSuccess = (id,cashData) => {
        return {

        type: actionTypes.MODIFICA_CASH_SUCCESS,
        cashId: id,
        cashData: cashData
    }
}

export const modificaCashFail= (error) => {
    return {
        type: actionTypes.MODIFICA_CASH_FAIL,
        error: error
    }
}

export const modificaCash = (cashData, token) => {
    return dispatch => {
        dispatch(modificaCashStart());
        //console.log('sto chiamando axios')
        axios.patch( '/cash/' +  cashData.id + '.json?auth=' + token, cashData )
            .then( response => {
                //console.log(response.data.name)
                dispatch( modificaCashSuccess(response.data.name, cashData))
                //console.log(response.data.name)
                dispatch(fetchCash(token, cashData.data))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( modificaCashFail(error));
               
            } );
    }
}

//create elimina cash Start action
export const eliminaCashStart= () => {
    return  {
        type: actionTypes.ELIMINA_CASH_START
        };
    };
    
export const eliminaCashSuccess = (cashData) => {
        return {

        type: actionTypes.ELIMINA_CASH_SUCCESS,
        cashId: cashData.id
    }
}

export const eliminaCashFail= (error) => {
    return {
        type: actionTypes.ELIMINA_CASH_FAIL,
        error: error
    }
}

export const eliminaCash = (cashData, token) => {
    return dispatch => {
        dispatch(eliminaCashStart());
       // console.log('sto chiamando axios per cancellare')
        axios.delete( '/cash/' +  cashData.id + '.json?auth=' + token, cashData )
            .then( response => {
                dispatch( eliminaCashSuccess(cashData))
                //console.log(response.data.name)
                dispatch(fetchCash(token, cashData.data))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( eliminaCashFail(error));
               
            } );
    }
}

export const fetchCashStart = () => {
    return {
        type: actionTypes.FETCH_CASH_START
    };
};

export const fetchCashSuccess = (cash) => {
    return {
        type: actionTypes.FETCH_CASH_SUCCESS,
        cash : cash        
    };
};

export const fetchCashFail = (error) => {
    return {
        type: actionTypes.FETCH_CASH_FAIL,
        error: error
    };
};

export const fetchCash= (token, data) => {
   return dispatch => {
        dispatch(fetchCashStart());
        let queryParams = '?auth=' + token + '&orderBy="data"&equalTo="' + data +'"&print=pretty'  ;
        axios.get('/cash.json' + queryParams )
    .then(res => {
        const fetchedCash=[];
        for (let key in res.data) {
            fetchedCash.push({
                ...res.data[key],
                id: key
            });
        }
        dispatch(fetchCashSuccess(fetchedCash))
        
    })
    .catch(err => {
        dispatch(fetchCashFail(err))        
    });
    }
}

