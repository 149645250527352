import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    marche: [],
    loading: false,
    added: false
   
};

const addMarcaInit = ( state, action ) => {
    return updateObject(state, {added: false });
}

const addMarcaStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const addMarcaSuccess = ( state, action ) => {
    const newMarca = {
        ...action.marcaData,
        id:action.marcaId,
        }    
    return updateObject(state, {
            loading: false,
            marche: state.marche.concat(newMarca),
            added: true
        });
}

const addMarcaFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const modificaMarcaStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const modificaMarcaSuccess = ( state, action ) => {
    const newMarca = {
        ...action.marcaData,
        id:action.marcaId,
        }    
    return updateObject(state, {
            loading: false,
            marche: state.marche.concat(newMarca),
            added: true
        });
}

const modificaMarcaFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//elimina marca
const eliminaMarcaStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const eliminaMarcaSuccess = ( state, action ) => {
    // const newMarca = {
    //     ...action.marcaData,
    //     id:action.marcaId,
    //     }    
    return updateObject(state, {
            loading: false,
            marche: state.marche,
            added: true
        });
}

const eliminaMarcaFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch marche
const fetchMarcheStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchMarcheSuccess = ( state, action ) => {
    return updateObject(state,{
        marche: action.marche,
        loading: false});
}

const fetchMarcheFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.ADD_MARCA_INIT: return addMarcaInit( state, action );
        case actionTypes.ADD_MARCA_START: return addMarcaStart(state, action );            
        case actionTypes.ADD_MARCA_SUCCESS: return addMarcaSuccess( state, action);
        case actionTypes.ADD_MARCA_FAIL: return addMarcaFail (state, action);
        case actionTypes.MODIFICA_MARCA_START: return modificaMarcaStart(state, action );            
        case actionTypes.MODIFICA_MARCA_SUCCESS: return modificaMarcaSuccess( state, action);
        case actionTypes.MODIFICA_MARCA_FAIL: return modificaMarcaFail (state, action);
        case actionTypes.ELIMINA_MARCA_START: return eliminaMarcaStart(state, action );            
        case actionTypes.ELIMINA_MARCA_SUCCESS: return eliminaMarcaSuccess( state, action);
        case actionTypes.ELIMINA_MARCA_FAIL: return eliminaMarcaFail (state, action);
        case actionTypes.FETCH_MARCHE_START: return fetchMarcheStart(state, action);
        case actionTypes.FETCH_MARCHE_SUCCESS: return fetchMarcheSuccess(state, action);
        case actionTypes.FETCH_MARCHE_FAIL:return fetchMarcheFail(state, action);
        default: return state;

        }
}

export default reducer;
