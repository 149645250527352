import * as actionTypes from './actionTypes'
import axios from '../../hoc/axios-vendite';

//create add codPagamento Start action
export const addCodPagamentoStart= () => {
    return  {
        type: actionTypes.ADD_COD_PAGAMENTO_START
        };
    };
    
export const addCodPagamentoSuccess = (id,codPagamentoData) => {
        return {

        type: actionTypes.ADD_COD_PAGAMENTO_SUCCESS,
        codPagamentoId: id,
        codPagamentoData: codPagamentoData
    }
}

export const addCodPagamentoFail= (error) => {
    return {
        type: actionTypes.ADD_COD_PAGAMENTO_FAIL,
        error: error
    }
}

export const addCodPagamento = (codPagamentoData, token) => {
    return dispatch => {
        dispatch(addCodPagamentoStart());
       // console.log('sto chiamando axios')
        axios.post( '/codPagamenti.json?auth=' + token, codPagamentoData )
            .then( response => {
               // console.log(response.data.name)
                dispatch( addCodPagamentoSuccess(response.data.id, codPagamentoData))
                //console.log(response.data.name)
                dispatch(fetchCodPagamenti(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( addCodPagamentoFail(error));
               
            } );
    }
}

export const addCodPagamentoInit = () => {
    return  {
        type: actionTypes.ADD_COD_PAGAMENTO_INIT
        };
    };

//create add codPagamento Start action
export const modificaCodPagamentoStart= () => {
    return  {
        type: actionTypes.MODIFICA_COD_PAGAMENTO_START
        };
    };
    
export const modificaCodPagamentoSuccess = (id,codPagamentoData) => {
        return {

        type: actionTypes.MODIFICA_COD_PAGAMENTO_SUCCESS,
        codPagamentoId: id,
        codPagamentoData: codPagamentoData
    }
}

export const modificaCodPagamentoFail= (error) => {
    return {
        type: actionTypes.MODIFICA_COD_PAGAMENTO_FAIL,
        error: error
    }
}

export const modificaCodPagamento = (codPagamentoData, token) => {
    return dispatch => {
        dispatch(modificaCodPagamentoStart());
        console.log('sto chiamando axios')
        axios.patch( '/codPagamenti/' +  codPagamentoData.id + '.json?auth=' + token, codPagamentoData )
            .then( response => {
                console.log(response.data.name)
                dispatch( modificaCodPagamentoSuccess(response.data.id, codPagamentoData))
                //console.log(response.data.name)
                dispatch(fetchCodPagamenti(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( modificaCodPagamentoFail(error));
               
            } );
    }
}

//create elimina codPagamento Start action
export const eliminaCodPagamentoStart= () => {
    return  {
        type: actionTypes.ELIMINA_COD_PAGAMENTO_START
        };
    };
    
export const eliminaCodPagamentoSuccess = (codPagamentoData) => {
        return {

        type: actionTypes.ELIMINA_COD_PAGAMENTO_SUCCESS,
        codPagamentoId: codPagamentoData.id
    }
}

export const eliminaCodPagamentoFail= (error) => {
    return {
        type: actionTypes.ELIMINA_COD_PAGAMENTO_FAIL,
        error: error
    }
}

export const eliminaCodPagamento = (codPagamentoData, token) => {
    return dispatch => {
        dispatch(eliminaCodPagamentoStart());
        console.log('sto chiamando axios per cancellare')
        axios.delete( '/codPagamenti/' +  codPagamentoData.id + '.json?auth=' + token, codPagamentoData )
            .then( response => {
                dispatch( eliminaCodPagamentoSuccess(codPagamentoData))
                //console.log(response.data.name)
                dispatch(fetchCodPagamenti(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( eliminaCodPagamentoFail(error));
               
            } );
    }
}

export const fetchCodPagamentiStart = () => {
    return {
        type: actionTypes.FETCH_COD_PAGAMENTI_START
    };
};

export const fetchCodPagamentiSuccess = (codPagamenti) => {
    return {
        type: actionTypes.FETCH_COD_PAGAMENTI_SUCCESS,
        codPagamenti : codPagamenti        
    };
};

export const fetchCodPagamentiFail = (error) => {
    return {
        type: actionTypes.FETCH_COD_PAGAMENTI_FAIL,
        error: error
    };
};

export const fetchCodPagamenti= (token) => {
   return dispatch => {
        dispatch(fetchCodPagamentiStart());
        const queryParams = '?auth=' + token ;
        axios.get('/codPagamenti.json' + queryParams )
    .then(res => {
        const fetchedCodPagamenti=[];
        for (let key in res.data) {
            fetchedCodPagamenti.push({
                ...res.data[key],
                id: key
            });
        }
        dispatch(fetchCodPagamentiSuccess(fetchedCodPagamenti))
        
    })
    .catch(err => {
        dispatch(fetchCodPagamentiFail(err))        
    });
    }
}

