import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    pagamenti: [],
    pagamento: null,
    loading: false,
    added: false
   
};

const addPagamentoInit = ( state, action ) => {
    return updateObject(state, {added: false });
}

const addPagamentoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const addPagamentoSuccess = ( state, action ) => {
    const newPagamento = {
        ...action.pagamentoData,
        id:action.pagamentoId,
        }    
    return updateObject(state, {
            loading: false,
            pagamenti: state.pagamenti.concat(newPagamento),
            added: true
        });
}

const addPagamentoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const modificaPagamentoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const modificaPagamentoSuccess = ( state, action ) => {
    const newPagamento = {
        ...action.pagamentoData,
        id:action.pagamentoId,
        }    
    return updateObject(state, {
            loading: false,
            pagamenti: state.pagamenti.concat(newPagamento),
            added: true
        });
}

const modificaPagamentoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//elimina pagamento
const eliminaPagamentoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const eliminaPagamentoSuccess = ( state, action ) => {
    // const newPagamento = {
    //     ...action.pagamentoData,
    //     id:action.pagamentoId,
    //     }    
    return updateObject(state, {
            loading: false,
            pagamenti: state.pagamenti,
            added: true
        });
}

const eliminaPagamentoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch pagamenti
const fetchPagamentiStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchPagamentiSuccess = ( state, action ) => {
    return updateObject(state,{
        pagamenti: action.pagamenti,
        loading: false});
}

const fetchPagamentiFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch pagamento
const fetchPagamentoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchPagamentoSuccess = ( state, action ) => {
    return updateObject(state,{
        pagamento: action.pagamento,
        loading: false});
}

const fetchPagamentoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.ADD_PAGAMENTO_INIT: return addPagamentoInit( state, action );
        case actionTypes.ADD_PAGAMENTO_START: return addPagamentoStart(state, action );            
        case actionTypes.ADD_PAGAMENTO_SUCCESS: return addPagamentoSuccess( state, action);
        case actionTypes.ADD_PAGAMENTO_FAIL: return addPagamentoFail (state, action);
        case actionTypes.MODIFICA_PAGAMENTO_START: return modificaPagamentoStart(state, action );            
        case actionTypes.MODIFICA_PAGAMENTO_SUCCESS: return modificaPagamentoSuccess( state, action);
        case actionTypes.MODIFICA_PAGAMENTO_FAIL: return modificaPagamentoFail (state, action);
        case actionTypes.ELIMINA_PAGAMENTO_START: return eliminaPagamentoStart(state, action );            
        case actionTypes.ELIMINA_PAGAMENTO_SUCCESS: return eliminaPagamentoSuccess( state, action);
        case actionTypes.ELIMINA_PAGAMENTO_FAIL: return eliminaPagamentoFail (state, action);
        case actionTypes.FETCH_PAGAMENTI_START: return fetchPagamentiStart(state, action);
        case actionTypes.FETCH_PAGAMENTI_SUCCESS: return fetchPagamentiSuccess(state, action);
        case actionTypes.FETCH_PAGAMENTI_FAIL:return fetchPagamentiFail(state, action);
        case actionTypes.FETCH_PAGAMENTO_START: return fetchPagamentoStart(state, action);
        case actionTypes.FETCH_PAGAMENTO_SUCCESS: return fetchPagamentoSuccess(state, action);
        case actionTypes.FETCH_PAGAMENTO_FAIL:return fetchPagamentoFail(state, action);
        default: return state;

        }
}

export default reducer;
