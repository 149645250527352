import React, {forwardRef} from 'react';
import {NavLink} from 'react-router-dom';
import classes from './NavigationBar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationsItems/NavigationItems';
import { connect } from 'react-redux';
import { dataStringa} from '../../../shared/utility.js';
import * as actions from '../../../store/actions/index.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';
import { Nav, NavbarBrand} from 'reactstrap';

registerLocale('it', it)

// const Cal = () => {
//     const [startDate, setStartDate] = useState(new Date());
//     const calendar =  require('../../../assets/calendarIcon.png') ;
//     const ExampleCustomInput = ({ onClick }) => (
        
//       <img className="example-custom-input" src={calendar} style={{height: "55px"}} alt='calendario' onClick={onClick}/> 
//     );
//     return (
//       <DatePicker
//         locale = "it"
//         selected={startDate}
//         onChange={date => setStartDate(date)}
//         customInput={<ExampleCustomInput />}

//       />
//     );
//   }

const NavigationBar = props => {

    const calendar =  require('../../../assets/calendarIcon.png') ;
    const logout =  require('../../../assets/logoutIcon.png') ;
    const oggi =  require('../../../assets/oggiIcon.png') ;
    const avanti =  require('../../../assets/avanti.png') ;
    const indietro =  require('../../../assets/indietro.png') ;
    const cashflow =  require('../../../assets/cashflow.png') ;

    let giornoData = new Date(props.giorno);
   

    //console.log('Data oggi 1 = ', giornoData, props.giorno);

    let giornoDopo = dataStringa(giornoData.setDate(giornoData.getDate() + 1));
    //giornoData = new Date(props.giorno);
    let giornoPrima = dataStringa(giornoData.setDate(giornoData.getDate() - 2));
    // console.log('Data oggi 2= ', props.giorno);
    // console.log('Data domani 3= ', giornoDopo);
    // console.log('Data ieri 4= ', giornoPrima);

   
    const onGiornoPrimaHandler = () => {
        props.onSetGiorno(giornoPrima);
    }

    const onGiornoOggiHandler = () => {
        let oggi = new Date();
       // oggi.setDate(oggi.getDate() + 1);
        props.onSetGiorno(dataStringa(oggi));
    }
    const onGiornoDopoHandler = () => {
        props.onSetGiorno(giornoDopo);
    }
    
    const ref = React.createRef();
    const ScegliGiorno = forwardRef(({ onClick }, ref) => (
        <img src={calendar} style={{height: "55px"}} alt='calendario' onClick={onClick} ref={ref}/> 
          
      ));


   
  



    return (
          <header className={classes.NavigationBar}>
        {/* <Navbar expand="sm"> */}
        <NavbarBrand ><Logo clicked={props.drawerToggleClicked}/></NavbarBrand>
        <div className={classes.DesktopOnly}>
        <Nav className="ml-auto" >
            
        <NavigationItems isAuthenticated={props.isAuthenticated} userId={props.userId}/>
        </ Nav>
        </div>
        {/* <div className={classes.MobileOnly}> */}
        <Nav >
               {props.isAuthenticated && <ul  className={classes.NavigationItems}>
                                            <li className={classes.NavigationItem}>
                                            <NavLink 
                                                    to="/cashflow"
                                                    exact><img src={cashflow} style={{height: "55px"}} alt='Entrate/uscite'/> 
                                                </NavLink>
                                                
                                            </li> 
                                            <li className={classes.NavigationItem}>
                                                <img src={indietro} style={{height: "55px"}} alt='giorno prima' onClick={onGiornoPrimaHandler}/> 
                                            </li> 
                                            <li className={classes.NavigationItem}>
                                                <img src={oggi} style={{height: "55px"}} alt='oggi' onClick={onGiornoOggiHandler}/> 
                                            </li>
                                           
                                            <li className={classes.NavigationItem}>
                                            <DatePicker
                                                todayButton="Oggi"
                                                popperPlacement="top-end"
                                                popperModifiers={{
                                                    offset: {
                                                    enabled: true,
                                                    offset: "20px, 0px"
                                                    },
                                                    preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: "viewport"
                                                }}
        }
                                                selected={new Date(props.giorno)}
                                                onChange={date => {
                                                    //date.setDate(date.getDate() + 1);
                                                    props.onSetGiorno(dataStringa(date))
                                                }}
                                                customInput={<ScegliGiorno ref={ref}/>}
                                            />
                                            </li>
                                           
                                            <li className={classes.NavigationItem}> 
                                            <img src={avanti} style={{height: "55px"}} alt='giorno successivo' onClick={onGiornoDopoHandler}/> 
                                            </li>

                                            <li className={classes.NavigationItem}>
                                                <NavLink 
                                                    to="/logout"
                                                    exact
                                                    activeClassName={classes.active}><img src={logout} style={{height: "55px"}} alt='logout'/> 
                                                </NavLink>
                                            </li> 
                                        </ul> }
        </Nav>
        {/* </div> */}
        
        {/* </Navbar> */}
         </header>
    )
}

const mapStateToProps = state => {
    return {
        giorno: state.foglio.giorno
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetGiorno: (giorno) => dispatch(actions.setGiorno(giorno)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);

