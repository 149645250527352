import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    flussi: [],
    loading: false,
    added: false
   
};

const addFlussoInit = ( state, action ) => {
    return updateObject(state, {added: false });
}

const addFlussoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const addFlussoSuccess = ( state, action ) => {
    const newFlusso = {
        ...action.flussoData,
        id:action.flussoId,
        }    
    return updateObject(state, {
            loading: false,
            flussi: state.flussi.concat(newFlusso),
            added: true
        });
}

const addFlussoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const modificaFlussoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const modificaFlussoSuccess = ( state, action ) => {
    const newFlusso = {
        ...action.flussoData,
        id:action.flussoId,
        }    
    return updateObject(state, {
            loading: false,
            flussi: state.flussi.concat(newFlusso),
            added: true
        });
}

const modificaFlussoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//elimina flusso
const eliminaFlussoStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const eliminaFlussoSuccess = ( state, action ) => {
    // const newFlusso = {
    //     ...action.flussoData,
    //     id:action.flussoId,
    //     }    
    return updateObject(state, {
            loading: false,
            flussi: state.flussi,
            added: true
        });
}

const eliminaFlussoFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch flussi
const fetchFlussiStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchFlussiSuccess = ( state, action ) => {
    return updateObject(state,{
        flussi: action.flussi,
        loading: false});
}

const fetchFlussiFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.ADD_FLUSSO_INIT: return addFlussoInit( state, action );
        case actionTypes.ADD_FLUSSO_START: return addFlussoStart(state, action );            
        case actionTypes.ADD_FLUSSO_SUCCESS: return addFlussoSuccess( state, action);
        case actionTypes.ADD_FLUSSO_FAIL: return addFlussoFail (state, action);
        case actionTypes.MODIFICA_FLUSSO_START: return modificaFlussoStart(state, action );            
        case actionTypes.MODIFICA_FLUSSO_SUCCESS: return modificaFlussoSuccess( state, action);
        case actionTypes.MODIFICA_FLUSSO_FAIL: return modificaFlussoFail (state, action);
        case actionTypes.ELIMINA_FLUSSO_START: return eliminaFlussoStart(state, action );            
        case actionTypes.ELIMINA_FLUSSO_SUCCESS: return eliminaFlussoSuccess( state, action);
        case actionTypes.ELIMINA_FLUSSO_FAIL: return eliminaFlussoFail (state, action);
        case actionTypes.FETCH_FLUSSI_START: return fetchFlussiStart(state, action);
        case actionTypes.FETCH_FLUSSI_SUCCESS: return fetchFlussiSuccess(state, action);
        case actionTypes.FETCH_FLUSSI_FAIL:return fetchFlussiFail(state, action);
        default: return state;

        }
}

export default reducer;
