import React from 'react';
//import classes from './Fornitore.module.css';
import { Card, CardTitle, CardSubtitle, CardText, Row, Col } from 'reactstrap';

const FornitoreCard = props => {
    
    

        return (

<Row style={{padding: "5px"}}>
      <Col  >
      <div style={{ cursor: 'pointer' }}  onClick={() => props.onClicked(props.fornitore)}>
        <Card body key={props.fornitore.id}>
          <CardTitle tag="h5" >{props.fornitore.denominazione}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">Codice Fisc. {props.fornitore.codiceFiscale}</CardSubtitle>
          <CardText>Indirizzo: <br/> {props.fornitore.indirizzo}<br/>{props.fornitore.cap} {props.fornitore.comune} ({props.fornitore.provincia}) </CardText>
          <CardText>Tel: {props.fornitore.telefono} <br/>Email: {props.fornitore.email} </CardText>
        
        </Card>
      </div>
      </Col>
</Row>

        )
    
}

export default FornitoreCard;