import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {Button, Form, FormGroup, Label, 
    Input, FormFeedback, Spinner} from 'reactstrap';
import Aux from '../../hoc/Aux_/Aux';
import classes from './Auth.module.css';
import { checkValidity } from '../../shared/utility.js';
import * as actions from '../../store/actions/index.js';

 class Auth extends Component {

    state = {
        controls: {
            email: {  value: '',
                      validation: { required: true,
                                    isEmail: true },                                          
                      valid: true,
                      touched: false
                    },
            password: {  value: '',
                        validation: { required: true,
                                        minLength: 6 },                                          
                        valid: true,
                        touched: false
                    }
            }
        } //end State

            
       

        inputChangeHandler = (event, id) => {
                       const updatedControls = {
                           ...this.state.controls
                       };
                       const updatedFormElement = {
                           ...updatedControls[id]
                       };
                       
                       updatedFormElement.value = (typeof event.target.type !== 'undefined' && event.target.type === 'checkbox') ? event.target.checked : event.target.value;
                      // console.log(updatedFormElement);
                       updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
                       updatedFormElement.touched = true;
                       updatedControls[id] = updatedFormElement;
                      
       
                       this.setState({controls: updatedControls});
                       
                   }

       submitHandler = (event) => {
           event.preventDefault();
           //console.log('submitted');
           this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value );
       } 


    render() {

        let form = (
            <Aux>
            <FormGroup>
                <Label>Email address</Label>
                <Input type="email" 
                       className="form-control" 
                       id="email" placeholder="Enter email" 
                       valid={this.state.controls.email.valid} 
                       invalid={!this.state.controls.email.valid && this.state.controls.email.touched } 
                       onChange={(event) => this.inputChangeHandler(event, "email")}/>
                       { !this.state.controls.email.valid && <FormFeedback>Email non valida</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>Password</Label>
                <Input type="password" 
                        className="form-control" 
                        id="password"
                        placeholder="Enter password" 
                        valid={this.state.controls.password.valid} 
                        invalid={!this.state.controls.password.valid && this.state.controls.password.touched} 
                        onChange={(event) => this.inputChangeHandler(event, "password")} />
                        { !this.state.controls.password.valid && <FormFeedback>Password troppo corta, almeno 6 caratteri</FormFeedback>}
            </FormGroup>
            </Aux>

        );

        if (this.props.loading) {
                form = <Spinner />;
        }

        let errorMessage = null;

            if (this.props.error) {
                errorMessage = (
                    <p>{this.props.error.message}</p>
                )
            }

            let authRedirect = '';
            if (this.props.isAuthenticated) {
                console.log("sono auenticato!!")
                authRedirect = <Redirect to="/" />;
               // console.log(authRedirect)
            }

        return (
  <div className={classes.formAuth}>
      {authRedirect}
      <Form onSubmit={this.submitHandler}>
            <h3>Sign In</h3>
            {errorMessage}
            
            
      {form}
      <Button type="submit" className="btn btn-primary btn-block" >Submit</Button>
         </Form>
    </div>
        )
    }
}
const mapStateToProps = state => {
    return{
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Auth);
