import React, {  useEffect, useState } from 'react';
import axios from '../../hoc/axios-vendite';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Fornitore from './Fornitore/Fornitore';
import FornitoreCard from './Fornitore/FornitoreCard';
import * as actions from '../../store/actions/index';
import { Spinner, Table } from 'reactstrap';
import classes from './Fornitori.module.css';
//import { checkValidity,updateObject } from '../../shared/utility.js';
import { Button, Modal } from 'reactstrap';
import Aux from '../../hoc/Aux_/Aux';
import ModificaFornitore from './ModificaFornitore';

const Fornitori = props => {

    const {
        onFetchFornitori,
      } = props;

      
      const [modal, setModal] = useState(false);
      const [modifica, setModifica] = useState(false);
      const [fornitore, setFornitore] = useState(null);

    
      const toggle = () => setModal(!modal);

      const modificaFornitoreHandler = (fornitoreData) => {
        setModifica(true);
        //console.log(fornitoreData);
        
        if(fornitoreData.id !== ''){
          setFornitore(fornitoreData);
        
        toggle();
        }
        
      }  


      const nuovoFornitoreHandler = () => {
        setModifica(false);
        //console.log(fornitoreData);
        setFornitore(null);
        toggle();
        
        
      }  
   
    useEffect( () => {
      onFetchFornitori(props.token)}, [onFetchFornitori, props.token] );

      let fornitoriList =  <Spinner />;

        if (!props.loading) {
          
          props.fornitori.sort((a, b) => (a.denominazione > b.denominazione) ? 1 : -1);

          fornitoriList = (
            <Aux >
            <div className={classes.DesktopOnly}>
            <Table hover className={classes.Table}>
                <thead>  
                  <tr >
                    <th style={{textAlign: "left"}}>Denominazione</th>
                    <th style={{textAlign: "left"}}>Indirizzo</th>
                    <th style={{textAlign: "left"}}>Contatti</th>
                  </tr>
                </thead>
                <tbody>

                {props.fornitori.map(fornitore => (
                        <Fornitore 
                            key = {fornitore.id}
                            fornitore = {fornitore}
                            onClicked={modificaFornitoreHandler}/> 
                ))  }
            </tbody>
              </Table>
              </div>
                  <div  className={classes.MobileOnly}>
                    {props.fornitori.map(fornitore => (
                      <FornitoreCard 
                          key = { fornitore.id}
                          fornitore = {fornitore}
                          onClicked={modificaFornitoreHandler}/> 
                    ))  }
                </div>
            </Aux>

            );
        };

    


    
        return (
            <div>
            <Button color="primary" onClick={nuovoFornitoreHandler} style={{heigth: "15px", fontSize: "1.2rem", padding: "2px", margin: "5px"}}>  <i className="fas fa-plus" />  Inserisci un nuovo fornitore</Button>
      <Modal isOpen={modal} toggle={toggle} >
          <ModificaFornitore fornitoreData={fornitore} toggle={toggle} modifica={modifica} /> 
      </Modal> 
      <h3>Fornitori</h3>
               {fornitoriList} 
            </div>
            
        );
    
}

const mapStateToProps = state => {
    return {
        fornitori: state.fornitori.fornitori,
        loading: state.fornitori.loading,
        token: state.auth.token
    }
    
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchFornitori: (token) => dispatch(actions.fetchFornitori(token)),
        onAddFornitore: (fornitorerData, token) => dispatch(actions.addFornitore(fornitorerData, token)),
        onModificaFornitore: (fornitoreData, token) => dispatch(actions.modificaFornitore(fornitoreData, token)),
        onEliminaFornitore: (fornitoreData, token) => dispatch(actions.eliminaFornitore(fornitoreData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Fornitori, axios));
