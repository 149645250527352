import React, {  useEffect, useState } from 'react';
import axios from '../../hoc/axios-vendite';
import Aux from '../../hoc/Aux_/Aux';
import classes from './CashFlow.module.css';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
import {  dataIntera, dataStringa } from '../../shared/utility.js';
import { Modal, Spinner, Card, CardHeader, CardFooter, Col, Table, Row } from 'reactstrap';
import ModificaChiusura from './ModificaChiusura/ModificaChiusura';
import Uscita from '../../components/Uscite/Uscita/Uscita';
import ModificaUscita from '../../components/Uscite/Uscita/ModificaUscita'


const oggi = new Date();
const nuovaData = new Date();
nuovaData.setDate(oggi.getDate() + 150);



const Cashflow = props => {

    const {
        onFetchCash,
      } = props;

      let giornoData = new Date(props.giorno);
      let gg, mm, aaaa;
      gg = giornoData.getDate();
      mm = giornoData.getMonth()+1;
      aaaa = giornoData.getFullYear();

      const [modal, setModal] = useState(false);
      const toggle = () => setModal(!modal);

      const [modalUscita, setModalUscita] = useState(false);
      const toggleUscita = () => setModalUscita(!modalUscita);
      
      const [ uscita, setUscita] = useState({
        data: dataStringa(props.giorno)
        });

        let oggiData = oggi.getUTCFullYear() + '-' + (oggi.getMonth()+1) + '-' + ("0" + oggi.getDate()).slice(-2);
      
    //   console.log(props.giorno + ' vs ' + oggiData);
      const intestazione = (props.giorno === oggiData  
          ? ("Chiusura di oggi " + dataIntera(giornoData))
          : ("Chiusura di  " + dataIntera(giornoData))
      );

      const intestazioneCell = (giornoData === oggiData 
          ? ("Chiusura di oggi " + gg +'/' + mm +'/' + aaaa)
          : ("Chiusura del " + gg +'/' + mm +'/' + aaaa)
      );  
       
      const intestazioneSpese = (props.giorno === oggiData  
        ? ("Uscite di oggi " + dataIntera(giornoData))
        : ("Uscite di  " + dataIntera(giornoData))
    );

    const intestazioneSpeseCell = (giornoData === oggiData 
        ? ("Uscite di oggi " + gg +'/' + mm +'/' + aaaa)
        : ("Uscite del " + gg +'/' + mm +'/' + aaaa)
    );  
     
         let chiusure = {
            pos: { id: '', value: 0},
            contanti: { id: '', value: 0},
            assegni: { id: '', value: 0},
            bonifici: { id: '', value: 0},
            totale: 0
        }

        let uscite = 0;


        useEffect( () => {

       // console.log('Data 1: ' + props.giorno);
        onFetchCash(props.token, dataStringa(props.giorno) );
        
        }, [onFetchCash, props.token, props.giorno] );
  
        let chiusura =  <Spinner />;
        let usciteList= <Spinner />

        const modificaUscitaHandler = (u) => {
            console.log(u);
            setUscita({...u, modifica: true});
            toggleUscita();
            
        }

        const nuovaUscitaHandler = () => {
            setUscita({data: dataStringa(props.giorno)});
            toggleUscita();
            
        }
  
          if (!props.loading && props.cash ) {       
              
                        
          //  console.log(props.cash);
               

            for (let key in props.cash) {
                chiusure.pos = (props.cash[key]['tipo'] === 'chiusura' && props.cash[key]['modo'] === 'MP08' ) ? 
                        { ...chiusure.pos, value: props.cash[key]['valore'], id:props.cash[key]['id'] } : {...chiusure.pos};
                chiusure.contanti = (props.cash[key]['tipo'] === 'chiusura' && props.cash[key]['modo'] === 'MP01' ) ? 
                        { ...chiusure.contanti, value: props.cash[key]['valore'], id:props.cash[key]['id']} : {...chiusure.contanti};    
                chiusure.assegni = (props.cash[key]['tipo'] === 'chiusura' && props.cash[key]['modo'] === 'MP02' ) ? 
                        { ...chiusure.assegni, value: props.cash[key]['valore'], id:props.cash[key]['id']} : {...chiusure.assegni};    
                chiusure.bonifici = (props.cash[key]['tipo'] === 'chiusura' && props.cash[key]['modo'] === 'MP05' ) ? 
                        { ...chiusure.bonifici, value: props.cash[key]['valore'], id:props.cash[key]['id']} : {...chiusure.bonifici}; 
                uscite = (props.cash[key]['flusso'] === 'uscita') ? parseFloat(uscite) + parseFloat(props.cash[key]['valore']) : parseFloat(uscite);
                //console.log(props.cash[key]['flusso'] + parseFloat(uscite));
                }

            chiusure.totale = ( parseFloat(chiusure.pos.value) +  parseFloat(chiusure.contanti.value) +  parseFloat(chiusure.assegni.value) +  parseFloat(chiusure.bonifici.value));
                //console.log("Tot uscite " + uscite);
                
          chiusura = (

        
          <Table hover >
                
                <tbody>
                    <tr>
                        <td >POS</td>
                        <td style={{textAlign:"right"}}> {chiusure.pos.value && parseFloat(chiusure.pos.value).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td >Contanti</td>
                        <td style={{textAlign:"right"}}> {chiusure.contanti.value && parseFloat(chiusure.contanti.value).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td >Assegni</td>
                        <td style={{textAlign:"right"}}> {chiusure.assegni.value && parseFloat(chiusure.assegni.value).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td >Bonifici</td>
                        <td style={{textAlign:"right"}}> {chiusure.bonifici.value && parseFloat(chiusure.bonifici.value).toFixed(2)}</td>
                    </tr>
                              
            </tbody>
              </Table>
           
           );

         //  console.log("Cash: " + props.cash);

           usciteList = (
             <Table hover className={classes.Table}>
                 <thead> 
                   <tr style={{heigth: "15px", fontSize: "0,4rem", padding: "2px"}}>
                     <th style={{textAlign: "left"}}>Tipo</th>
                     <th style={{textAlign: "left"}}>Descrizione</th>
                     <th style={{textAlign: "right"}}>Importo</th>
                   </tr>
                 </thead>
                 <tbody>
                 {props.cash ? 
                  props.cash.filter(function(hero) { return hero.flusso === 'uscita' }).map(uscita => (
                         <Uscita 
                            key= {uscita.id}
                             uscita = {uscita}
                             onClicked={modificaUscitaHandler}/> 
                 )) : null  }
                  
             </tbody>
               </Table>
             )

          }

          
        
        return (
          <Aux>

            <Modal isOpen={modal} toggle={toggle} >
                 <ModificaChiusura  toggle={toggle} chiusure={chiusure} intestazione={intestazioneCell} />
            </Modal>

            <Modal isOpen={modalUscita} toggle={nuovaUscitaHandler} >
                <ModificaUscita  toggle={nuovaUscitaHandler} uscita={uscita} intestazione={intestazioneCell} />
            </Modal>
        
       
            <Row>
      <Col sm="6">
       <Card onClick={toggle} className="m-2">
           <CardHeader><span className={classes.Titolo}>
            {intestazione} 
        </span >
        <span className={classes.TitoloCell}>
            {intestazioneCell} 
        </span > &nbsp;  <span style={{fontSize: "24px", color: "Dodgerblue"}}><i className="fas fa-cash-register"></i></span></CardHeader>
            {chiusura}
           <CardFooter style={{textAlign: "right"}}><b>Totale chiusura: {chiusure.totale && parseFloat(chiusure.totale).toFixed(2)}</b> </CardFooter>
       </Card>
        </Col>
<br></br>
<Col sm="6">


       <Card  className="m-2">
           <CardHeader onClick={toggleUscita} ><span className={classes.Titolo}>
            {intestazioneSpese} 
        </span >
        <span className={classes.TitoloCell}>
            {intestazioneSpeseCell} 
        </span > &nbsp;  <span style={{fontSize: "24px", color: "Dodgerblue"}}><i className="fas fa-money-bill-wave"></i></span></CardHeader>
            {usciteList}
           <CardFooter style={{textAlign: "right"}}><b>Totale uscite: {uscite && parseFloat(uscite).toFixed(2)}</b> </CardFooter>
       </Card>
        </Col>
        </Row>
     
            </Aux>  
        );
    
}

const mapStateToProps = state => {
    return {
        cash: state.cash.cash,
        giorno: state.foglio.giorno,
        loading: state.cash.loading,
        token: state.auth.token
    }
    
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCash: (token, data) => dispatch(actions.fetchCash(token, data))
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Cashflow, axios));
