import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    fornitore: null,
    fornitori: [],
    loading: false,
    added: false
   
};

const addFornitoreInit = ( state, action ) => {
    return updateObject(state, {added: false });
}

const addFornitoreStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const addFornitoreSuccess = ( state, action ) => {
    const newFornitore = {
        ...action.fornitoreData,
        id:action.fornitoreId,
        }    
    return updateObject(state, {
            loading: false,
            fornitori: state.fornitori.concat(newFornitore),
            added: true
        });
}

const addFornitoreFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const modificaFornitoreStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const modificaFornitoreSuccess = ( state, action ) => {
    const newFornitore = {
        ...action.fornitoreData,
        id:action.fornitoreId,
        }    
    return updateObject(state, {
            loading: false,
            fornitori: state.fornitori.concat(newFornitore),
            added: true
        });
}

const modificaFornitoreFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//elimina fornitore
const eliminaFornitoreStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const eliminaFornitoreSuccess = ( state, action ) => {
    // const newFornitore = {
    //     ...action.fornitoreData,
    //     id:action.fornitoreId,
    //     }    
    return updateObject(state, {
            loading: false,
            fornitori: state.fornitori,
            added: true
        });
}

const eliminaFornitoreFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch fornitori
const fetchFornitoriStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchFornitoriSuccess = ( state, action ) => {
    return updateObject(state,{
        fornitori: action.fornitori,
        loading: false});
}

const fetchFornitoriFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch fornitori
const fetchFornitoreStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchFornitoreSuccess = ( state, action ) => {
    return updateObject(state,{
        fornitore: action.fornitore,
        loading: false});
}

const fetchFornitoreFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.ADD_FORNITORE_INIT: return addFornitoreInit( state, action );
        case actionTypes.ADD_FORNITORE_START: return addFornitoreStart(state, action );            
        case actionTypes.ADD_FORNITORE_SUCCESS: return addFornitoreSuccess( state, action);
        case actionTypes.ADD_FORNITORE_FAIL: return addFornitoreFail (state, action);
        case actionTypes.MODIFICA_FORNITORE_START: return modificaFornitoreStart(state, action );            
        case actionTypes.MODIFICA_FORNITORE_SUCCESS: return modificaFornitoreSuccess( state, action);
        case actionTypes.MODIFICA_FORNITORE_FAIL: return modificaFornitoreFail (state, action);
        case actionTypes.ELIMINA_FORNITORE_START: return eliminaFornitoreStart(state, action );            
        case actionTypes.ELIMINA_FORNITORE_SUCCESS: return eliminaFornitoreSuccess( state, action);
        case actionTypes.ELIMINA_FORNITORE_FAIL: return eliminaFornitoreFail (state, action);
        case actionTypes.FETCH_FORNITORI_START: return fetchFornitoriStart(state, action);
        case actionTypes.FETCH_FORNITORI_SUCCESS: return fetchFornitoriSuccess(state, action);
        case actionTypes.FETCH_FORNITORI_FAIL:return fetchFornitoriFail(state, action);
        case actionTypes.FETCH_FORNITORE_START: return fetchFornitoreStart(state, action);
        case actionTypes.FETCH_FORNITORE_SUCCESS: return fetchFornitoreSuccess(state, action);
        case actionTypes.FETCH_FORNITORE_FAIL:return fetchFornitoreFail(state, action);
        default: return state;

        }
}

export default reducer;
