import * as actionTypes from './actionTypes';
import axios from '../../hoc/axios-vendite';

//create add pagamento Start action
export const addPagamentoStart= () => {
    return  {
        type: actionTypes.ADD_PAGAMENTO_START
        };
    };
    
export const addPagamentoSuccess = (id,pagamentoData) => {
        return {

        type: actionTypes.ADD_PAGAMENTO_SUCCESS,
        pagamentoId: id,
        pagamentoData: pagamentoData
    }
}

export const addPagamentoFail= (error) => {
    return {
        type: actionTypes.ADD_PAGAMENTO_FAIL,
        error: error
    }
}

export const addPagamento = (pagamentoData, token, params = null) => {
    return dispatch => {
        dispatch(addPagamentoStart());
       // console.log('sto chiamando axios')
        axios.post( '/pagamenti.json?auth=' + token, pagamentoData )
            .then( response => {
               // console.log(response.data.name)
                dispatch( addPagamentoSuccess(response.data.id, pagamentoData))
                //console.log(response.data.name)
                dispatch(fetchPagamenti(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( addPagamentoFail(error));
               
            } );
    }
}

export const addPagamentoInit = () => {
    return  {
        type: actionTypes.ADD_PAGAMENTO_INIT
        };
    };

//create add pagamento Start action
export const modificaPagamentoStart= () => {
    return  {
        type: actionTypes.MODIFICA_PAGAMENTO_START
        };
    };
    
export const modificaPagamentoSuccess = (id,pagamentoData) => {
        return {

        type: actionTypes.MODIFICA_PAGAMENTO_SUCCESS,
        pagamentoId: id,
        pagamentoData: pagamentoData
    }
}

export const modificaPagamentoFail= (error) => {
    return {
        type: actionTypes.MODIFICA_PAGAMENTO_FAIL,
        error: error
    }
}

export const modificaPagamento = (pagamentoData, token, params = null) => {
    return dispatch => {
        dispatch(modificaPagamentoStart());
        console.log('sto chiamando axios')
        axios.patch( '/pagamenti/' +  pagamentoData.id + '.json?auth=' + token, pagamentoData )
            .then( response => {
                //console.log(response.data.name)
                dispatch( modificaPagamentoSuccess(response.data.name, pagamentoData))
                //console.log(response.data.name)
                dispatch(fetchPagamenti(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( modificaPagamentoFail(error));
               
            } );
    }
}

//create elimina pagamento Start action
export const eliminaPagamentoStart= () => {
    return  {
        type: actionTypes.ELIMINA_PAGAMENTO_START
        };
    };
    
export const eliminaPagamentoSuccess = (pagamentoData) => {
        return {

        type: actionTypes.ELIMINA_PAGAMENTO_SUCCESS,
        pagamentoId: pagamentoData.id
    }
}

export const eliminaPagamentoFail= (error) => {
    return {
        type: actionTypes.ELIMINA_PAGAMENTO_FAIL,
        error: error
    }
}

export const eliminaPagamento = (pagamentoData, token) => {
    return dispatch => {
        dispatch(eliminaPagamentoStart());
        //console.log('sto chiamando axios per cancellare')
        axios.delete( '/pagamenti/' +  pagamentoData.id + '.json?auth=' + token, pagamentoData )
            .then( response => {
                dispatch( eliminaPagamentoSuccess(pagamentoData))
                //console.log(response.data.name)
                dispatch(fetchPagamenti(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( eliminaPagamentoFail(error));
               
            } );
    }
}

export const fetchPagamentiStart = () => {
    return {
        type: actionTypes.FETCH_PAGAMENTI_START
    };
};

export const fetchPagamentiSuccess = (pagamenti) => {
    //console.log(pagamenti)
    return {
        type: actionTypes.FETCH_PAGAMENTI_SUCCESS,
        pagamenti : pagamenti        
    };
};

export const fetchPagamentiFail = (error) => {
    return {
        type: actionTypes.FETCH_PAGAMENTI_FAIL,
        error: error
    };
};

export const fetchPagamenti= (token, fattura = null) => {
   return dispatch => {
        dispatch(fetchPagamentiStart());
        let queryParams = '?auth=' + token ;
        queryParams =  fattura ? queryParams  + '&orderBy="fattura"&equalTo="' + fattura +'"' : queryParams;
        //console.log( "id fatt = " + fattura + ' string= ' + queryParams);
        axios.get('/pagamenti.json' + queryParams )
    .then(res => {
        const fetchedPagamenti=[];
        //console.log(res.data);
        for (let key in res.data) {
           // console.log("Key =" + key);
            fetchedPagamenti.push({
                ...res.data[key],
                id: key
            });
        }
        dispatch(fetchPagamentiSuccess(fetchedPagamenti))
        
    })
    .catch(err => {
        dispatch(fetchPagamentiFail(err))        
    });
    }
}

//Fetch singolo pagamento

export const fetchPagamentoStart = () => {
    return {
        type: actionTypes.FETCH_PAGAMENTO_START
    };
};

export const fetchPagamentoSuccess = (pagamento) => {
    return {
        type: actionTypes.FETCH_PAGAMENTO_SUCCESS,
        pagamento : pagamento        
    };
};

export const fetchPagamentoFail = (error) => {
    return {
        type: actionTypes.FETCH_PAGAMENTO_FAIL,
        error: error
    };
};

export const fetchPagamento= (pagamentoData, token) => {
   return dispatch => {
        dispatch(fetchPagamentoStart());
        //console.log(token)
        const queryParams = '?auth=' + token  + '&orderBy="fattura"&equalTo="' + pagamentoData.fattura +'"';
        //console.log('/pagamenti.json' + queryParams);
        axios.get('/pagamenti.json' + queryParams )
    .then(res => {
       //console.log(res.data)
        let fetchedPagamento=null;
        for (let key in res.data) {
            if (res.data[key].rata === pagamentoData.rata ){
                fetchedPagamento = {
                    ...res.data[key],
                    id: key};
                }
            };
        //console.log(fetchedPagamento);
        dispatch(fetchPagamentoSuccess(fetchedPagamento))
        
    })
    .catch(err => {
        dispatch(fetchPagamentoFail(err))        
    });
    }
}
