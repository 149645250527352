import React from 'react';
//import classes from './Fornitore.module.css';
import { Card, CardTitle, CardSubtitle, CardText, Row, Col } from 'reactstrap';
import {dataNormale} from '../../../shared/utility';

const FatturaCard = props => {
    
  
        return (

          <Row style={{padding: "5px", cursor: 'pointer' }}  >
                <Col  >
                
                  <Card body key={props.fattura.id} onClick={() => props.onClicked(props.fattura)}>
                    <CardTitle tag="h5" >{props.fattura.numero} del {dataNormale(props.fattura.data)}</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">{props.fornitore[0].denominazione}<br/>Cod.Fisc. {props.fornitore[0].codiceFiscale}</CardSubtitle>
                    <CardText>Imponibile: {props.fattura.imponibile}<br/>
                              Imposta: {props.fattura.imponibile}
                              <br/>
                              <b>TOTALE: {props.fattura.importo}</b> </CardText>
                    </Card>
                
                </Col>
            </Row>

        )
    
}


export default FatturaCard;