import React, {useState}  from 'react';
import { connect } from 'react-redux';
import NavigationBar from '../../components/Navigation/NavigationBar/NavigationBar';
import Aux from '../Aux_/Aux';
import classes from './Layout.module.css';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';



const Layout = (props) => {

  const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);


const sideDrawerClosedHandler = () => {
    setSideDrawerIsVisible(false);
}

const sideDrawerToggleHandler = () => {
    setSideDrawerIsVisible(!sideDrawerIsVisible);
};


 
        return (
            <Aux>
                

                <NavigationBar 
                          isAuthenticated={props.isAuthenticated} 
                          userId = {props.userId}
                          giorno={props.giorno}
                          drawerToggleClicked= {sideDrawerToggleHandler}
                          open={sideDrawerIsVisible}
                          />
            
            <SideDrawer  
                          isAuthenticated = {props.isAuthenticated}
                          userId = {props.userId}
                          open= {sideDrawerIsVisible}
                          closed={sideDrawerClosedHandler}/>
                       
            <main className={classes.Content}>
               {props.children}
            </main>
            </Aux>
        )
     
}

const mapStateToProps = state => {
    return {
      isAuthenticated: state.auth.token !== null,
      userId: state.auth.userId,
      giorno: state.foglio.giorno
    }
  }
  
  const mapDispatchToProps = dispatch =>{
      return {
          
    }
  }
  
  
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
