import * as actionTypes from './actionTypes';


export const setGiorno = (giorno) => {
    return {
        type:actionTypes.SET_GIORNO,
        giorno: giorno
    }
}


