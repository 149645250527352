import React from 'react';
//import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import XmlReader from '../../components/XmlReader/XmlReader';


const Contabilita = props => {

 
    
        return (
        
        <div>
            <h2>Componente per la lettura degli xml:</h2>
            <XmlReader />
        </div>
        
            );
    
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(null, mapDispatchToProps)(Contabilita);