import React from 'react';
import classes from './Fattura.module.css';
import {dataNormale} from '../../../shared/utility';



const Fattura = props => {
    
  //  console.log(props.fornitore[0]);
 

        return (
            <tr key = {props.fattura.id} onClick={() => props.onClicked(props.fattura) } style={{cursor: 'pointer'}}>
                <td className={classes.TableRow} style={{fontSize: "1.6rem", textAlign: "left"}}>{props.fattura.numero}<br/>del {dataNormale(props.fattura.data)}</td>
                <td className={classes.TableRow} style={{fontSize: "1.6rem", textAlign: "left"}}>{props.fornitore[0].denominazione}<br/>Cod.Fisc. {props.fattura.fornitore}</td>
                <td className={classes.TableRow} style={{fontSize: "1.6rem", textAlign: "left"}}>Totale: <b>{props.fattura.importo}</b> <br/>Imponibile: {props.fattura.imponibile} (Iva: {props.fattura.imponibile})</td>
            </tr>
        )
    
}



export default Fattura;