import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Form, FormGroup, Label, 
        Col,Input, InputGroup, InputGroupAddon, 
        CustomInput, InputGroupText} from 'reactstrap';
import Calendario from '../UI/Calendario/Calendario';
import classes from './NuovaVendita.module.css';
import Aux from '../../hoc/Aux_/Aux';
import axios from '../../hoc/axios-vendite';
import { Spinner } from 'reactstrap';
import * as actions from '../../store/actions/index';


class NuovaVendita extends Component {

    state = this.initialState;

    get initialState() {
        return {
            
            loading: false,
            formError: '',
            formVisible: {
                switchForm: true,
                montatura: false,
                lenti: false,
                lac: false,
                liquido: false,
                altro: false
            },
            marcheLista: [],
            vendita: {
                dataVendita: {  value: this.props.dataVendita,
                                validation: { required: false },                                          
                                valid: true
                             },
                tipoVendita: {  value: '',
                                validation: { required: false },                                            
                                valid: true },
                tipoMontatura: {    value: '',
                                    validation: { required: false },                                            
                                    valid: true },
                marcaMontatura:{    value: '',
                                    validation: { required: false },                                            
                                    valid: true },
                prezzoMontatura: {  value: '',
                                    validation: { required: false },                                            
                                    valid: true },        
                tipoLenti: {    value: '',
                                validation: { required: false },                                            
                                valid: true },
                transition: {   value: false,
                                validation: { required: false },                                            
                                valid: true },
                colorate: {     value: false,
                                validation: { required: false },                                            
                                valid: true },
                prezzoLenti: {  value: '',
                                validation: { required: false },                                            
                                valid: true },
                tipoLac: {      value: '',
                                validation: { required: false },                                            
                                valid: true },
                marcaLac: {     value: '',
                                validation: { required: false },                                            
                                valid: true },
                prezzoLac: {    value: '',
                                validation: { required: false },                                            
                                valid: true },
                tipoLiquido: {  value: '',
                                validation: { required: false },                                            
                                valid: true },
                prezzoLiquido: {    value: '',
                                    validation: { required: false },                                            
                                    valid: true },
                altro:{         value: '',
                                validation: { required: false },                                            
                                valid: true },
                prezzoAltro:{   value: '',
                                validation: { required: false },                                            
                                valid: true },
            }
        }       
    }

            componentDidMount () {
                // console.log(this.props.dataVendita);
                this.caricaMarche();
            };

            componentDidUpdate(){
                //console.log('Componend Did update');
                this.caricaMarche();  
            }

            controllaData () { if (this.props.dataVendita !== this.state.vendita.dataVendita) {
                this.aggiustaData(this.props.dataVendita);
            }
            }
    

            caricaMarche(){

                if (this.props.loadingMarche || (this.props.marche && this.props.marche.length) ){
                    return;
                }
               // console.log('dovrei caricare le marche');   
                 this.props.onFetchMarche(this.props.token);

            //     if(this.props.loadingMarche){
            //         return; //sacricamento in corso...non fare nulla
            //     }

            //     if(this.state.marche.length > 0){
            //         return; //mache gia' caricate
            //     }

            //     this.setState({loading: true});
            //     axios.get('/marche.json?auth=' + this.props.token)
            // .then(response => {
                               
            //     let marche = [];
            //  //   console.log(response.data);
            //     for (let key in response.data){
            //         marche.push({
            //             ...response.data[key],
            //                 id: key
            //             });
            //         }
            //    this.setState({marcheLista: marche, loading: false});
            //    // console.log(marche);
            // })  
            // .catch(error => {
            //     this.setState({loading: false});
            //     console.log(error);
            // });

        };

            
            
            handleChange = date => {
                const updatedVendita = {
                    ...this.state.vendita
                };
                updatedVendita['dataVendita']['value'] = date;
                this.setState({
                vendita: updatedVendita
                });
            };

            

            formVisibleHandler = (type) => { 
                const updatedFormVisible = {
                    ...this.state.formVisible
                };
                const updatedVendita = {
                    ...this.state.vendita
                }
                switch(type) {
                    case 'vista':
                        updatedFormVisible['switchForm'] = false;
                        updatedFormVisible['montatura'] = true;
                        updatedFormVisible['lenti'] = true;
                        updatedVendita['tipoVendita']['value'] = 'occhialeCompleto';
                        updatedVendita['tipoMontatura']['value'] = 'vista';
                        updatedVendita['marcaMontatura']['validation']['required'] = true;
                        updatedVendita['marcaMontatura']['valid'] = false;
                        updatedVendita['prezzoMontatura']['validation']['required'] = true;
                        updatedVendita['prezzoMontatura']['valid'] = false;
                        updatedVendita['tipoLenti']['value'] = 'monofocali';
                        updatedVendita['prezzoLenti']['validation']['required'] = true;
                        updatedVendita['prezzoLenti']['valid'] = false;

                        return this.setState({formVisible: updatedFormVisible, vendita: updatedVendita});
                    case 'sole':
                        updatedFormVisible['switchForm'] = false;
                        updatedFormVisible['montatura'] = true;
                        updatedVendita['tipoVendita']['value'] = 'occhialeSole';
                        updatedVendita['tipoMontatura']['value'] = 'sole';
                        updatedVendita['marcaMontatura']['validation']['required'] = true;
                        updatedVendita['marcaMontatura']['valid'] = false;
                        updatedVendita['prezzoMontatura']['validation']['required'] = true;
                        updatedVendita['prezzoMontatura']['valid'] = false;
                        return this.setState({formVisible: updatedFormVisible, tipoVendita: updatedVendita});
                    case 'sole-vista':
                        updatedFormVisible['switchForm'] = false;
                        updatedFormVisible['montatura'] = true;
                        updatedFormVisible['lenti'] = true;
                        updatedVendita['tipoVendita']['value'] = 'occhialeCompletoSole';
                        updatedVendita['tipoMontatura']['value'] = 'sole';
                        updatedVendita['marcaMontatura']['validation']['required'] = true;
                        updatedVendita['marcaMontatura']['valid'] = false;
                        updatedVendita['prezzoMontatura']['validation']['required'] = true;
                        updatedVendita['prezzoMontatura']['valid'] = false;
                        updatedVendita['tipoLenti']['value'] = 'monofocali';
                        updatedVendita['tipoLenti']['validation']['required'] = true;
                        updatedVendita['tipoLenti']['valid'] = true;
                        updatedVendita['colorate']['value'] = true;
                        updatedVendita['prezzoLenti']['validation']['required'] = true;
                        updatedVendita['prezzoLenti']['valid'] = false;
                        return this.setState({formVisible: updatedFormVisible, tipoVendita: updatedVendita});
                    case 'solo-montatura':
                        updatedFormVisible['switchForm'] = false;
                        updatedFormVisible['montatura'] = true;
                        updatedVendita['tipoVendita']['value'] = 'montatura';
                        updatedVendita['tipoMontatura']['value'] = 'vista';
                        updatedVendita['marcaMontatura']['validation']['required'] = true;
                        updatedVendita['marcaMontatura']['valid'] = false;
                        updatedVendita['prezzoMontatura']['validation']['required'] = true;
                        updatedVendita['prezzoMontatura']['valid'] = false;
                        return this.setState({formVisible: updatedFormVisible, tipoVendita: updatedVendita});
                    case 'solo-lenti':
                        updatedFormVisible['switchForm'] = false;
                        updatedFormVisible['montatura'] = false;
                        updatedFormVisible['lenti'] = true;
                        updatedVendita['tipoVendita']['value'] = 'lenti';
                        updatedVendita['tipoLenti']['value'] = 'monofocali';
                        updatedVendita['prezzoLenti']['validation']['required'] = true;
                        updatedVendita['prezzoLenti']['valid'] = false;
                        return this.setState({formVisible: updatedFormVisible, tipoVendita: updatedVendita});
                    case 'lac':
                        updatedFormVisible['switchForm'] = false;
                        updatedFormVisible['lac'] = true;
                        updatedVendita['tipoVendita']['value'] = 'lac';
                        updatedVendita['tipoLac']['validation']['required'] = true;
                        updatedVendita['tipoLac']['valid'] = false;
                        updatedVendita['marcaLac']['validation']['required'] = true;
                        updatedVendita['marcaLac']['valid'] = false;
                        updatedVendita['prezzoLac']['validation']['required'] = true;
                        updatedVendita['prezzoLac']['valid'] = false;
                        return this.setState({formVisible: updatedFormVisible, tipoVendita: updatedVendita});
                    case 'liquido':
                        updatedFormVisible['switchForm'] = false;
                        updatedFormVisible['liquido'] = true;
                        updatedVendita['tipoVendita']['value'] = 'liquido';
                        updatedVendita['tipoLiquido']['validation']['required'] = true;
                        updatedVendita['tipoLiquido']['valid'] = false;
                        updatedVendita['prezzoLiquido']['validation']['required'] = true;
                        updatedVendita['prezzoLiquido']['valid'] = false;
                        return this.setState({formVisible: updatedFormVisible, tipoVendita: updatedVendita});
                    case 'altro':
                        updatedFormVisible['switchForm'] = false;
                        updatedFormVisible['altro'] = true;
                        updatedVendita['tipoVendita']['value'] = 'altro';
                        updatedVendita['altro']['validation']['required'] = true;
                        updatedVendita['altro']['valid'] = false;
                        updatedVendita['prezzoAltro']['validation']['required'] = true;
                        updatedVendita['prezzoAltro']['valid'] = false;
                        return this.setState({formVisible: updatedFormVisible, tipoVendita: 'altro'});
                    default: 
                        return this.setState({formVisible: updatedFormVisible});
                  }
            };

            

            cancellaHandler = () => {
                this.setState(this.initialState);
                this.props.modalClosed();
            }

            checkValidity(value, rules) {
                let isValid = false;             

                if (rules.required) {
                    isValid = value.trim() !== '';
                }

                if (!rules.required) {
                    isValid = true;
                }

                return isValid
            }

            inputChangeHandler = (event, id) => {
     //          console.log(event.target.value + ' elemento: ' + id);
                const updatedVendita = {
                    ...this.state.vendita
                };
                const updatedFormElement = {
                    ...updatedVendita[id]
                };
                
                updatedFormElement.value = (typeof event.target.type !== 'undefined' && event.target.type === 'checkbox') ? event.target.checked : event.target.value;
                
                updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation)
                updatedVendita[id] = updatedFormElement;
         //       console.log(updatedFormElement);

                this.setState({vendita: updatedVendita});
            }

            

            altroChangeHandler = (event, value) => {
           //     console.log('Altro: ' + value);
                 const updatedVendita = {
                     ...this.state.vendita
                 };
                 //updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation)
                 updatedVendita['altro'] = value; 
                 this.setState({vendita: updatedVendita});
             }

            salvaVenditaHandler = (event) => {
                 event.preventDefault();
               // console.log(this.state.vendita);
               this.setState({loading: true})

                if (this.props.dataVendita !== this.state.vendita.dataVendita) {
                    console.log('data diversa');
                }

               const vendita = {};
              
              // controllo la correttezza dei dati forniti nel form
               for (let formElementId in this.state.vendita){
                vendita[formElementId] = this.state.vendita[formElementId].value
                          //  console.log(this.state.vendita[formElementId].valid);
                        if (!this.state.vendita[formElementId].valid){
                         //   console.log(formElementId, ' valore non corretto: ', this.state.vendita[formElementId].value, ' ma validido: ', this.state.vendita[formElementId].valid);
                            this.setState({formError: ' valore non corretto: ' + formElementId, loading: false})
                            return;
                    }
                }
              
               axios.post('/vendite.json?auth=' + this.props.token, vendita)
                    .then(response => {
                        this.setState({loading: false});
                    })
                    .then(this.cancellaHandler)
                    .catch(error => {
                        this.setState({loading: false});
                    });
            }

            aggiustaData = (nuovaData) => {
                this.setState({vendita: {...this.state.vendita, dataVendita: nuovaData}});
            }




      
      render() {

        
        // console.log('Nuova Vendita: ' + this.props.dataVendita);
        // console.log('Nuova Vendita state: ' + this.state.vendita.dataVendita.value);

       
        

        let switchForm = (
            <div className={classes.TipoVendita}>

                <div className={classes.azzurro} onClick={() => this.formVisibleHandler('vista')}>OCCHIALE COMPLETO</div>
                <div className={classes.acqua} onClick={() => this.formVisibleHandler('solo-lenti')}>LENTI</div>
                <div className={classes.rosso} onClick={() => this.formVisibleHandler('solo-montatura')} >MONTATURA</div>
                <div className={classes.violetto} onClick={() => this.formVisibleHandler('sole')} >SOLE</div>
                <div className={classes.purple} onClick={() => this.formVisibleHandler('sole-vista')}>SOLE/VISTA</div>
                <div className={classes.grigiochiaro} onClick={() => this.formVisibleHandler('lac')}>LAC</div>
                <div className={classes.verde} onClick={() => this.formVisibleHandler('liquido')}>LIQUIDO</div>
                <div className={classes.giallo} onClick={() => this.formVisibleHandler('altro')}>ALTRO</div>
              
                </div>
        );
        
      let montaturaForm = (
          <Aux>
            <p>MONTATURA</p>
            <FormGroup row>
                <Col >
                    
                    <InputGroup>
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>Marca montatura</InputGroupText>
                    </InputGroupAddon><Input valid={this.state.vendita.marcaMontatura.valid} 
                                            invalid={!this.state.vendita.marcaMontatura.valid} 
                                            type="select" 
                                            name="marcaMontatura" 
                                            id="marcaMontatura"  
                                            onChange={(event) => this.inputChangeHandler(event, "marcaMontatura")}>
                                        <option value='' >Seleziona la marca...</option>
                                            {this.props.marche ? 
                                            this.props.marche.filter(function(hero) { return hero.tipo === 'montatura' })
                                            .sort((a, b) => (a.name > b.name) ? 1 : -1)
                                            .map( marca => (                                                        
                                                        <option value={marca.name} key={marca.id}  >{marca.name}</option>
                                                        ) 
                                                ) : null }
                                               
                        </Input> </InputGroup>  
                    
                </Col>
                </FormGroup>
                <FormGroup row>
                <Col >
                    <InputGroup>
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>Prezzo montatura</InputGroupText>
                    </InputGroupAddon>
                    <Input valid={this.state.vendita.prezzoMontatura.valid} 
                    invalid={!this.state.vendita.prezzoMontatura.valid} 
                    type="number" 
                    step="0.01"
                    name="prezzo" 
                    id="prezzoMontatura"  
                    placeholder="inserisci prezzo" onChange={(event) => this.inputChangeHandler(event, "prezzoMontatura")} />
                    <InputGroupAddon addonType="append">
                    <InputGroupText>€</InputGroupText>
                    </InputGroupAddon>
                    </InputGroup>
            </Col>
            </FormGroup>
            
            </Aux>
          );

          let lentiForm = (
              <Aux>
                  <p>LENTI</p>
              
            <div style={{textAlign: "left"}}>
      <FormGroup row>
            <Col >
            <Label for="exampleCheckbox">Tipo lenti</Label>
                <CustomInput type="radio" id="tipoLentiMonofocali" name="tipoLenti" label="Monofocali" value="monofocali" checked={this.state.vendita.tipoLenti.value === 'monofocali'} onChange={(event) => this.inputChangeHandler(event, "tipoLenti")}  />
                <CustomInput type="radio" id="tipoLentiMultifocali" name="tipoLenti" label="Multifocali (progressive)" value="multifocali" onChange={(event) => this.inputChangeHandler(event, "tipoLenti")} />
                <CustomInput type="radio" id="tipoLentiAltro" name="tipoLenti"  label="Altro" value="altro" onChange={(event) => this.inputChangeHandler(event, "tipoLenti")} />
            </ Col>
            <Col >
                    <Label for="exampleCheckbox">Extra</Label>
                    <div>
                    <CustomInput type="switch" id="colorate" label="colorate" checked={this.state.vendita.colorate.value} onChange={(event) => this.inputChangeHandler(event, "colorate")}/>
                    <CustomInput  type="switch" id="transition" label="transition"  onChange={(event) => this.inputChangeHandler(event, "transition")} />
                    </div>
            </ Col>
            </FormGroup>
            <FormGroup row>
            <Col >
                    <InputGroup>
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>Prezzo lenti</InputGroupText>
                    </InputGroupAddon>
                    <Input valid={this.state.vendita.prezzoLenti.valid} invalid={!this.state.vendita.prezzoLenti.valid} 
                    type="number" 
                    step="0.01"
                    name="prezzo" 
                    id="prezzoLenti"  
                    placeholder="Prezzo lenti" 
                    onChange={(event) => this.inputChangeHandler(event, "prezzoLenti")} />
                    <InputGroupAddon addonType="append">
                    <InputGroupText>€</InputGroupText>
                    </InputGroupAddon>
                    </InputGroup>
            </Col>
      </FormGroup>
              </div>
              </Aux>
            );    
        
    let lacForm = (
                <Aux>
                    <p>LAC</p>
                
              <div style={{textAlign: "left"}}>
        <FormGroup row>
              <Col >
              <Label for="exampleCheckbox">Tipo lenti</Label>
                  <CustomInput type="radio" id="giornaliere" name="tipoLac" label="giornaliere" value="giornaliere"  checked={this.state.vendita.tipoLac.value === 'giornaliere'} onChange={(event) => this.inputChangeHandler(event, "tipoLac")}/>
                  <CustomInput type="radio" id="bisettimanali" name="tipoLac" label="bisettimanali" value="bisettimanali" onChange={(event) => this.inputChangeHandler(event, "tipoLac")}/>
                  <CustomInput type="radio" id="mensili" name="tipoLac" label="mensili" value="mensili"  onChange={(event) => this.inputChangeHandler(event, "tipoLac")}/>
                  <CustomInput type="radio" id="altro" name="tipoLac"  label="altro"  value="altro"  onChange={(event) => this.inputChangeHandler(event, "tipoLac")}/>
              </ Col>
              </FormGroup>
              <FormGroup row>
                <Col >
                    
                    <InputGroup>
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>Marca LAC</InputGroupText>
                    </InputGroupAddon><Input valid={this.state.vendita.marcaLac.valid} invalid={!this.state.vendita.marcaLac.valid} 
                    type="select" 
                    name="selectMarcaLac" 
                    id="marcaLac" 
                    onChange={(event) => this.inputChangeHandler(event, "marcaLac")}>
                                <option value='' >Seleziona la marca...</option>
                                         {this.props.marche ? 
                                         this.props.marche.filter(function(hero) { return hero.tipo === 'lac' })
                                         .sort((a, b) => (a.name > b.name) ? 1 : -1)
                                         .map( marca => (                                                        
                                                    <option value={marca.name} key={marca.id}  >{marca.name}</option>
                                                    ) 
                                            ) : null }
                        </Input> </InputGroup>  
                    
                </Col>
                </FormGroup>
              <FormGroup row>
              <Col >
                      <InputGroup>
                      <InputGroupAddon addonType="prepend">
                      <InputGroupText>Prezzo LAC</InputGroupText>
                      </InputGroupAddon>
                      <Input valid={this.state.vendita.prezzoLac.valid} invalid={!this.state.vendita.prezzoLac.valid}  
                      type="number" 
                      name="prezzoLac" 
                      id="prezzoLac"  
                      step="0.01"
                      placeholder="Prezzo LAC" 
                      onChange={(event) => this.inputChangeHandler(event, "prezzoLac")}/>
                      <InputGroupAddon addonType="append">
                      <InputGroupText>€</InputGroupText>
                      </InputGroupAddon>
                      </InputGroup>
              </Col>
        </FormGroup>
                </div>
                </Aux>
              );  


              let liquidoForm = (
                <Aux>
                    <p>LIQUIDO</p>
                
              <div style={{textAlign: "left"}}>
        <FormGroup row>
              <Col >
              <Label for="exampleCheckbox">Tipo lenti</Label>
                  <CustomInput type="radio" id="SoluzioneUnica" name="tipoLiquido" label="Soluzione unica" value="soluzione unica" checked={this.state.vendita.tipoLiquido.value === 'soluzione unica'} onChange={(event) => this.inputChangeHandler(event, "tipoLiquido")}/>
                  <CustomInput type="radio" id="Salina" name="tipoLiquido" label="Salina" value="salina"  onChange={(event) => this.inputChangeHandler(event, "tipoLiquido")}/>
                  <CustomInput type="radio" id="Altro" name="tipoLiquido"  label="Altro" value="altro"  onChange={(event) => this.inputChangeHandler(event, "tipoLiquido")}/>
              </ Col>
              </FormGroup>
              <FormGroup row>
              <Col >
                      <InputGroup>
                      <InputGroupAddon addonType="prepend">
                      <InputGroupText>Prezzo Liquido</InputGroupText>
                      </InputGroupAddon>
                      <Input    valid={this.state.vendita.prezzoLiquido.valid} 
                                invalid={!this.state.vendita.prezzoLiquido.valid} type="number" 
                                name="prezzoLiquido" 
                                id="prezzoLiquido" 
                                step="0.01"
                                placeholder="Prezzo liquido" 
                                onChange={(event) => this.inputChangeHandler(event, "prezzoLiquido")} />
                      <InputGroupAddon addonType="append">
                      <InputGroupText>€</InputGroupText>
                      </InputGroupAddon>
                      </InputGroup>
              </Col>
        </FormGroup>
                </div>
                </Aux>
              );  

        let altroForm = (
                <Aux>
                    <p>Altro</p>
                
              <div style={{textAlign: "left"}}>
        <FormGroup row>
              <Col >
              <Label for="exampleCheckbox">Tipo altra vendita</Label>
                  <CustomInput type="radio" id="riparazione" name="altro" label="riparazione" value="riparazione" checked={this.state.vendita.altro.value === 'riparazione'} onChange={(event) => this.inputChangeHandler(event, "altro")}  />
                  <CustomInput type="radio" id="aste" name="altro" label="aste" value="aste" checked={this.state.vendita.altro.value === 'aste'}  onChange={(event) => this.inputChangeHandler(event, "altro")}/>
                  <CustomInput type="radio" id="accessori" name="altro"  label="accessori" checked={this.state.vendita.altro.value === 'accessori'}  value="accessori" onChange={(event) => this.inputChangeHandler(event, "altro")} />
                  <CustomInput type="radio" id="aggiuntivo" name="altro"  label="aggiuntivo" checked={this.state.vendita.altro.value === 'aggiuntivo'}  value="aggiuntivo" onChange={(event) => this.inputChangeHandler(event, "altro")}  />
              </ Col>
              </FormGroup>
              <FormGroup row>
              <Col >
                      <InputGroup>
                      <InputGroupAddon addonType="prepend">
                      <InputGroupText>Descrizione vendita</InputGroupText>
                      </InputGroupAddon>
                      <Input    valid={this.state.vendita.altro.valid} 
                                invalid={!this.state.vendita.altro.valid} 
                                type="text" name="altro" id="altro"  
                                placeholder="Scegli sopra o scrivi qui"
                                value = {this.state.vendita.altro.value}
                                onChange={(event) => this.inputChangeHandler(event, "altro")} />
                      <InputGroupAddon addonType="append">
                      <InputGroupText>€</InputGroupText>
                      </InputGroupAddon>
                      </InputGroup>
              </Col>
        </FormGroup>
              <FormGroup row>
              <Col >
                      <InputGroup>
                      <InputGroupAddon addonType="prepend">
                      <InputGroupText>Prezzo Altro</InputGroupText>
                      </InputGroupAddon>
                      <Input    valid={this.state.vendita.prezzoAltro.valid} 
                                invalid={!this.state.vendita.prezzoAltro.valid} 
                                type="number" step="0.01"
                                name="prezzoAltro" id="prezzoAltro"  
                                placeholder="Prezzo altro"
                                onChange={(event) => this.inputChangeHandler(event, "prezzoAltro")} />
                      <InputGroupAddon addonType="append">
                      <InputGroupText>€</InputGroupText>
                      </InputGroupAddon>
                      </InputGroup>
              </Col>
        </FormGroup>
                </div>
                </Aux>
              );  

            

    return (
            <Aux>
                <Form className={classes.NuovaVenditaForm} onSubmit={this.salvaVenditaHandler}>
                <FormGroup row>
                    <Label  sm={5}>Data Vendita </Label>
                    <Col sm={4}>
                    <Calendario data={this.props.dataVendita} changed={(date) => this.handleChange(date)}  />
                    
                    </Col>      
                </FormGroup>
                
                
                        
                { this.state.formVisible.switchForm && switchForm}
                { this.state.formVisible.montatura && montaturaForm}
                { this.state.formVisible.lenti && lentiForm}
                { this.state.formVisible.lac && lacForm}
                { this.state.formVisible.liquido && liquidoForm}
                { this.state.formVisible.altro && altroForm}
                
                { this.state.loading && <Spinner color="primary" /> }<br />
                <p style={{color: "red"}}>{this.state.formError}</p>
               <Button color="danger" onClick={this.cancellaHandler} >Cancella</Button>{' '}
               <Button type="submit" color="success"> Salva </Button> 
            </Form>
            </Aux>
           
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        marche: state.marche.marche,
        loadingMarche: state.marche.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchMarche: (token) => dispatch(actions.fetchMarche(token)),
        onAddMarca: (marcarData, token) => dispatch(actions.addMarca(marcarData, token)),
        onModificaMarca: (marcaData, token) => dispatch(actions.modificaMarca(marcaData, token)),
        onEliminaMarca: (marcaData, token) => dispatch(actions.eliminaMarca(marcaData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NuovaVendita);