import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers } from 'redux';
import authReducer from './store/reducers/auth';
import marcheReducer from './store/reducers/marche';
import venditeReducer from './store/reducers/vendite';
import foglioReducer from './store/reducers/foglio';
import cashReducer from './store/reducers/cash';
import flussiReducer from './store/reducers/flussi';
import pagamentiReducer from './store/reducers/pagamenti';
import fornitoriReducer from './store/reducers/fornitori';
import fattureReducer from './store/reducers/fatture';
import codPagamentiReducer from './store/reducers/codPagamenti';
import thunk from 'redux-thunk';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    marche: marcheReducer,
    vendite: venditeReducer,
    foglio: foglioReducer,
    cash: cashReducer,
    flussi: flussiReducer,
    pagamenti: pagamentiReducer,
    fornitori: fornitoriReducer,
    fatture: fattureReducer,
    codPagamenti: codPagamentiReducer
});

const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(thunk)
));

const app = (
  <Provider store={store}>
      <BrowserRouter>
          <React.Fragment>
              <App />
          </React.Fragment>
    </BrowserRouter>
  </Provider>
  
)


ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
