import React from 'react';
import classes from './Fornitore.module.css';


const Fornitore = props => {
    
   // console.log(props.fornitore);
    

        return (
            <tr key = {props.fornitore.id} onClick={() => props.onClicked(props.fornitore) } style={{cursor: 'pointer'}}>
                <td className={classes.TableRow} style={{fontSize: "1.6rem", textAlign: "left"}}>{props.fornitore.denominazione}<br/>{props.fornitore.codiceFiscale}</td>
                <td className={classes.TableRow} style={{fontSize: "1.6rem", textAlign: "left"}}>{props.fornitore.indirizzo}<br/>{props.fornitore.cap} {props.fornitore.comune} ({props.fornitore.provincia})</td>

                <td className={classes.TableRow} style={{fontSize: "1.6rem", textAlign: "left"}}>Tel: {props.fornitore.telefono} <br/>Email: {props.fornitore.email}</td>
            </tr>
        )
    
}

export default Fornitore;