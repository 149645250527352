import React from 'react';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationsItems/NavigationItems';
import Backdrop from '../../UI/Backdrop/Backdrop';
import classes from './SideDrawer.module.css';
import Aux from '../../../hoc/Aux_/Aux';
import { Nav } from 'reactstrap';


const SideDrawer = (props) => {


    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses =  [classes.SideDrawer, classes.Open];
     }

    // console.log(attachedClasses);

    return(
        <Aux>
        <Backdrop show={props.open} clicked={props.closed} />
        <div className={attachedClasses.join(' ')} >
            <div className={classes.Logo}>
                 <Logo />
            </div>
            <hr />
            
      <Nav vertical>
      <NavigationItems isAuthenticated = {props.isAuthenticated} userId={props.userId} clicked={props.closed}/>
   
      </Nav>
     
      
                
                    
                
        </div>
        </Aux>
    );
};

export default SideDrawer;
