export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const FETCH_MARCHE_START = 'FETCH_MARCHE_START';
export const FETCH_MARCHE_SUCCESS = 'FETCH_MARCHE_SUCCESS';
export const FETCH_MARCHE_FAIL = 'FETCH_MARCHE_FAIL';

export const ADD_MARCA_INIT = 'ADD_MARCA_INIT';
export const ADD_MARCA_START = 'ADD_MARCA_START';
export const ADD_MARCA_SUCCESS = 'ADD_MARCA_SUCCESS';
export const ADD_MARCA_FAIL = 'ADD_MARCA_FAIL';

export const MODIFICA_MARCA_START = 'MODIFICA_MARCA_START';
export const MODIFICA_MARCA_SUCCESS = 'MODIFICA_MARCA_SUCCESS';
export const MODIFICA_MARCA_FAIL = 'MODIFICA_MARCA_FAIL';

export const ELIMINA_MARCA_START = 'ELIMINA_MARCA_START';
export const ELIMINA_MARCA_SUCCESS = 'ELIMINA_MARCA_SUCCESS';
export const ELIMINA_MARCA_FAIL = 'ELIMINA_MARCA_FAIL';

export const FETCH_CASH_START = 'FETCH_CASH_START';
export const FETCH_CASH_SUCCESS = 'FETCH_CASH_SUCCESS';
export const FETCH_CASH_FAIL = 'FETCH_CASH_FAIL';

export const ADD_CASH_INIT = 'ADD_CASH_INIT';
export const ADD_CASH_START = 'ADD_CASH_START';
export const ADD_CASH_SUCCESS = 'ADD_CASH_SUCCESS';
export const ADD_CASH_FAIL = 'ADD_CASH_FAIL';

export const MODIFICA_CASH_START = 'MODIFICA_CASH_START';
export const MODIFICA_CASH_SUCCESS = 'MODIFICA_CASH_SUCCESS';
export const MODIFICA_CASH_FAIL = 'MODIFICA_CASH_FAIL';

export const ELIMINA_CASH_START = 'ELIMINA_CASH_START';
export const ELIMINA_CASH_SUCCESS = 'ELIMINA_CASH_SUCCESS';
export const ELIMINA_CASH_FAIL = 'ELIMINA_CASH_FAIL';

export const FETCH_FLUSSI_START = 'FETCH_FLUSSI_START';
export const FETCH_FLUSSI_SUCCESS = 'FETCH_FLUSSI_SUCCESS';
export const FETCH_FLUSSI_FAIL = 'FETCH_FLUSSI_FAIL';

export const ADD_FLUSSO_INIT = 'ADD_FLUSSO_INIT';
export const ADD_FLUSSO_START = 'ADD_FLUSSO_START';
export const ADD_FLUSSO_SUCCESS = 'ADD_FLUSSO_SUCCESS';
export const ADD_FLUSSO_FAIL = 'ADD_FLUSSO_FAIL';

export const MODIFICA_FLUSSO_START = 'MODIFICA_FLUSSO_START';
export const MODIFICA_FLUSSO_SUCCESS = 'MODIFICA_FLUSSO_SUCCESS';
export const MODIFICA_FLUSSO_FAIL = 'MODIFICA_FLUSSO_FAIL';

export const ELIMINA_FLUSSO_START = 'ELIMINA_FLUSSO_START';
export const ELIMINA_FLUSSO_SUCCESS = 'ELIMINA_FLUSSO_SUCCESS';
export const ELIMINA_FLUSSO_FAIL = 'ELIMINA_FLUSSO_FAIL';


//Pagamenti
export const FETCH_PAGAMENTO_START = 'FETCH_PAGAMENTO_START';
export const FETCH_PAGAMENTO_SUCCESS = 'FETCH_PAGAMENTO_SUCCESS';
export const FETCH_PAGAMENTO_FAIL = 'FETCH_PAGAMENTO_FAIL';

export const FETCH_PAGAMENTI_START = 'FETCH_PAGAMENTI_START';
export const FETCH_PAGAMENTI_SUCCESS = 'FETCH_PAGAMENTI_SUCCESS';
export const FETCH_PAGAMENTI_FAIL = 'FETCH_PAGAMENTI_FAIL';

export const ADD_PAGAMENTO_INIT = 'ADD_PAGAMENTO_INIT';
export const ADD_PAGAMENTO_START = 'ADD_PAGAMENTO_START';
export const ADD_PAGAMENTO_SUCCESS = 'ADD_PAGAMENTO_SUCCESS';
export const ADD_PAGAMENTO_FAIL = 'ADD_PAGAMENTO_FAIL';

export const MODIFICA_PAGAMENTO_START = 'MODIFICA_PAGAMENTO_START';
export const MODIFICA_PAGAMENTO_SUCCESS = 'MODIFICA_PAGAMENTO_SUCCESS';
export const MODIFICA_PAGAMENTO_FAIL = 'MODIFICA_PAGAMENTO_FAIL';

export const ELIMINA_PAGAMENTO_START = 'ELIMINA_PAGAMENTO_START';
export const ELIMINA_PAGAMENTO_SUCCESS = 'ELIMINA_PAGAMENTO_SUCCESS';
export const ELIMINA_PAGAMENTO_FAIL = 'ELIMINA_PAGAMENTO_FAIL';

//Pagamenti
export const FETCH_COD_PAGAMENTI_START = 'FETCH_COD_PAGAMENTI_START';
export const FETCH_COD_PAGAMENTI_SUCCESS = 'FETCH_COD_PAGAMENTI_SUCCESS';
export const FETCH_COD_PAGAMENTI_FAIL = 'FETCH_COD_PAGAMENTI_FAIL';

export const ADD_COD_PAGAMENTO_INIT = 'ADD_COD_PAGAMENTO_INIT';
export const ADD_COD_PAGAMENTO_START = 'ADD_COD_PAGAMENTO_START';
export const ADD_COD_PAGAMENTO_SUCCESS = 'ADD_COD_PAGAMENTO_SUCCESS';
export const ADD_COD_PAGAMENTO_FAIL = 'ADD_COD_PAGAMENTO_FAIL';

export const MODIFICA_COD_PAGAMENTO_START = 'MODIFICA_COD_PAGAMENTO_START';
export const MODIFICA_COD_PAGAMENTO_SUCCESS = 'MODIFICA_COD_PAGAMENTO_SUCCESS';
export const MODIFICA_COD_PAGAMENTO_FAIL = 'MODIFICA_COD_PAGAMENTO_FAIL';

export const ELIMINA_COD_PAGAMENTO_START = 'ELIMINA_COD_PAGAMENTO_START';
export const ELIMINA_COD_PAGAMENTO_SUCCESS = 'ELIMINA_COD_PAGAMENTO_SUCCESS';
export const ELIMINA_COD_PAGAMENTO_FAIL = 'ELIMINA_COD_PAGAMENTO_FAIL';

//Vendite
export const FETCH_VENDITE_START = 'FETCH_VENDITE_START';
export const FETCH_VENDITE_SUCCESS = 'FETCH_VENDITE_SUCCESS';
export const FETCH_VENDITE_FAIL = 'FETCH_VENDITE_FAIL';


export const FETCH_FORNITORE_START = 'FETCH_FORNITORE_START';
export const FETCH_FORNITORE_SUCCESS = 'FETCH_FORNITORE_SUCCESS';
export const FETCH_FORNITORE_FAIL = 'FETCH_FORNITORE_FAIL';

export const FETCH_FORNITORI_START = 'FETCH_FORNITORI_START';
export const FETCH_FORNITORI_SUCCESS = 'FETCH_FORNITORI_SUCCESS';
export const FETCH_FORNITORI_FAIL = 'FETCH_FORNITORI_FAIL';

export const ADD_FORNITORE_INIT = 'ADD_FORNITORE_INIT';
export const ADD_FORNITORE_START = 'ADD_FORNITORE_START';
export const ADD_FORNITORE_SUCCESS = 'ADD_FORNITORE_SUCCESS';
export const ADD_FORNITORE_FAIL = 'ADD_FORNITORE_FAIL';

export const MODIFICA_FORNITORE_START = 'MODIFICA_FORNITORE_START';
export const MODIFICA_FORNITORE_SUCCESS = 'MODIFICA_FORNITORE_SUCCESS';
export const MODIFICA_FORNITORE_FAIL = 'MODIFICA_FORNITORE_FAIL';

export const ELIMINA_FORNITORE_START = 'ELIMINA_FORNITORE_START';
export const ELIMINA_FORNITORE_SUCCESS = 'ELIMINA_FORNITORE_SUCCESS';
export const ELIMINA_FORNITORE_FAIL = 'ELIMINA_FORNITORE_FAIL';

//fatture
export const FETCH_FATTURA_START = 'FETCH_FATTURA_START';
export const FETCH_FATTURA_SUCCESS = 'FETCH_FATTURA_SUCCESS';
export const FETCH_FATTURA_FAIL = 'FETCH_FATTURA_FAIL';

export const FETCH_FATTURE_START = 'FETCH_FATTURE_START';
export const FETCH_FATTURE_SUCCESS = 'FETCH_FATTURE_SUCCESS';
export const FETCH_FATTURE_FAIL = 'FETCH_FATTURE_FAIL';

export const ADD_FATTURA_INIT = 'ADD_FATTURA_INIT';
export const ADD_FATTURA_START = 'ADD_FATTURA_START';
export const ADD_FATTURA_SUCCESS = 'ADD_FATTURA_SUCCESS';
export const ADD_FATTURA_FAIL = 'ADD_FATTURA_FAIL';

export const MODIFICA_FATTURA_START = 'MODIFICA_FATTURA_START';
export const MODIFICA_FATTURA_SUCCESS = 'MODIFICA_FATTURA_SUCCESS';
export const MODIFICA_FATTURA_FAIL = 'MODIFICA_FATTURA_FAIL';

export const ELIMINA_FATTURA_START = 'ELIMINA_FATTURA_START';
export const ELIMINA_FATTURA_SUCCESS = 'ELIMINA_FATTURA_SUCCESS';
export const ELIMINA_FATTURA_FAIL = 'ELIMINA_FATTURA_FAIL';

//scadenze



export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const SET_GIORNO = 'SET_GIORNO';