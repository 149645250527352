import React, {  useEffect, useState } from 'react';
import axios from '../../hoc/axios-vendite';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Fattura from './Fattura/Fattura';
import FatturaCard from './Fattura/FatturaCard';
import * as actions from '../../store/actions/index';
import { Spinner, Table } from 'reactstrap';
import classes from './Fatture.module.css';
//import { checkValidity,updateObject } from '../../shared/utility.js';
import { Button, Modal } from 'reactstrap';
import Aux from '../../hoc/Aux_/Aux';
import ModificaFattura from './ModificaFattura';


  


const Fatture = props => {

    const {
        onFetchFatture,
        onFetchFornitori
      } = props;

      
      const [modal, setModal] = useState(false);
      const [modifica, setModifica] = useState(false);
      const [fattura, setFattura] = useState(null);
   
      const toggle = () => setModal(!modal);

      const modificaFatturaHandler = (fatturaData) => {
        setModifica(true);
        //console.log(fatturaData);
        
        if(fatturaData.id !== ''){
          setFattura(fatturaData);
        
        toggle();
        }
        
      }  


      const nuovoFatturaHandler = () => {
        setModifica(false);
        //console.log(fatturaData);
        setFattura(null);
        toggle();
        
        
      }  
   
    useEffect( () => {
      onFetchFatture(props.token);
      console.log('UseEffect: 1');
    }, [onFetchFatture, props.token] );
     
    useEffect( () => {
      onFetchFornitori(props.token);
      console.log('UseEffect: 2');
    }, [onFetchFornitori, props.token] );
    


      let fattureList =  <Spinner />;

        if (!props.loading && !props.loadingFornitori && props.fornitori.length) {
          
          console.log("primo if");
          //console.log(props.fornitori);
          // console.log(props.fornitori ? 
          //   props.fornitori.filter(function(hero) { return hero.codiceFiscale === "IT12801471009" }): null );


          props.fatture.sort((a, b) => (a.data > b.data) ? 1 : -1);

          fattureList = (
            <Aux >
            <div className={classes.DesktopOnly}>
            <Table hover className={classes.Table}>
                <thead>  
                  <tr >
                    <th style={{textAlign: "left"}}>Numero</th>
                    <th style={{textAlign: "left"}}>Fornitore</th>
                    <th style={{textAlign: "left"}}>Importo</th>
                  </tr>
                </thead>
                <tbody>

                {props.fatture.map(fattura => (
                        <Fattura 
                            key = {fattura.id}
                            fattura = {fattura}
                            fornitore = {props.fornitori ? 
                              props.fornitori.filter(function(hero) { return hero.codiceFiscale === fattura.fornitore })
                              .map( fornitore => ( fornitore )  ) : null }
                            onClicked={modificaFatturaHandler}/> 
                ))  }
            </tbody>
              </Table>
              </div>
                  <div  className={classes.MobileOnly}>
                    {props.fatture.map(fattura => (
                      <FatturaCard 
                          key = { fattura.id}
                          fattura = {fattura}
                          fornitore = {props.fornitori ? 
                            props.fornitori.filter(function(hero) { return hero.codiceFiscale === fattura.fornitore })
                            .map( fornitore => ( fornitore )  ) : null }
                          onClicked={modificaFatturaHandler}/> 
                    ))  }
                </div>
            </Aux>

            );
        };

    


    
        return (
            <div>
            <Button color="primary" onClick={nuovoFatturaHandler} style={{heigth: "15px", fontSize: "1.2rem", padding: "2px", margin: "5px"}}>  <i className="fas fa-plus" />  Inserisci una nuovo fattura</Button>
      <Modal isOpen={modal} toggle={toggle} >
          <ModificaFattura fatturaData={fattura} toggle={toggle} modifica={modifica} /> 
      </Modal> 
      <h3>Fatture</h3>
               {fattureList} 
            </div>
            
        );
    
}

const mapStateToProps = state => {
    return {
        fornitori: state.fornitori.fornitori,
        loadingFornitori: state.fornitori.loading,
        fatture: state.fatture.fatture,
        loading: state.fatture.loading,
        token: state.auth.token
    }
    
}

const mapDispatchToProps = dispatch => {
    return {
      onFetchFornitori: (token) => dispatch(actions.fetchFornitori(token)),
      onFetchFatture: (token) => dispatch(actions.fetchFatture(token)),
      onAddFattura: (fatturarData, token) => dispatch(actions.addFattura(fatturarData, token)),
      onModificaFattura: (fatturaData, token) => dispatch(actions.modificaFattura(fatturaData, token)),
      onEliminaFattura: (fatturaData, token) => dispatch(actions.eliminaFattura(fatturaData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Fatture, axios));
