import React, {  useEffect, useState } from 'react';
import axios from '../../hoc/axios-vendite';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Flusso from './Flusso/Flusso';
import * as actions from '../../store/actions/index';
import { Spinner, Table } from 'reactstrap';
import classes from './Flussi.module.css';
import { checkValidity,updateObject } from '../../shared/utility.js';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup,  
  Col,Input, InputGroup, InputGroupAddon, 
  InputGroupText} from 'reactstrap';


  


const Flussi = props => {

    const {
        className,
        onFetchFlussi,
      } = props;

      
      const [modal, setModal] = useState(false);
      const [modifica, setModifica] = useState(false);

      const [ flusso, setFlusso] = useState({
        id:   { value: '',
                validation: { required: false},
                valid: true},
        name: { value: '',
                validation: { required: true},
                valid: false},
        tipo: { value: '',
                validation: { required: true},
                valid: false
            }
        })
    
      const toggle = () => setModal(!modal);

      const modificaFlussoHandler = (flussoData) => {
        setModifica(true);
        console.log(flussoData);
        setFlusso({id: {value: flussoData.id, valid: true}, name: {value: flussoData.name, valid: true}, tipo:{value: flussoData.tipo, valid: true}});
        console.log(flusso);
        if(flusso.id !== ''){
          toggle();
        }
        
      }  


      const nuovaFlussoHandler = () => {
        setModifica(false);
        //console.log(flussoData);
        setFlusso({id: {value: '', valid: true}, name: {value: '', valid: false}, tipo:{value: '', valid: false}});
        toggle();
        
        
      }  
   console.log(props.token);
    useEffect( () => {
      
      console.log('OnFetchFlussi');
      onFetchFlussi(props.token)}, [onFetchFlussi, props.token] );

      let flussiList =  <Spinner />;

        if (!props.loading) {
          
          props.flussi.sort((a, b) => (a.name > b.name) ? 1 : -1);

          console.log(props.flussi);
          flussiList = (
            <Table hover className={classes.Table}>
                <thead>  
                  <tr >
                    <th style={{fontSize: "1.8rem", textAlign: "left"}}>Nome</th>
                    <th style={{fontSize: "1.8rem", textAlign: "left"}}>Tipo</th>
                  </tr>
                </thead>
                <tbody>

                {props.flussi.map(flusso => (
                        <Flusso 
                            flusso = {flusso}
                            key={flusso.id}
                            nome={flusso.name}
                            tipo={flusso.tipo}
                            onClicked={modificaFlussoHandler}/> 
                ))  }
            </tbody>
              </Table>
            )
        };

    

    

    const inputChangedHandler = (event, inputIdentifier) => { 
        

      const updatedFormElement = updateObject(flusso[inputIdentifier], {
          value: event.target.value,
          valid: checkValidity(event.target.value, flusso[inputIdentifier].validation),
          

      } );

      const updatedFlussoForm = updateObject(flusso,{
          [inputIdentifier]: updatedFormElement
      });
      
      let formIsValid = true;
      for (let inputIdentifier in updatedFlussoForm) {
          formIsValid = updatedFlussoForm[inputIdentifier].valid && formIsValid;
      }
      setFlusso(updatedFlussoForm);
   
     // setFormIsValid(formIsValid);
  }
  
      
      const addFlussoHandler = ( event ) => {
              event.preventDefault();
              const flussoData = {};
                    for (let formElementIdentifier in flusso) {
                      flussoData[formElementIdentifier] = flusso[formElementIdentifier].value;
                    }
              console.log(flussoData)
              props.onAddFlusso(flussoData, props.token);
              toggle();
            }

      const submitModificaFlussoHandler = ( event ) => {
              event.preventDefault();
              const flussoData = {};
                    for (let formElementIdentifier in flusso) {
                      flussoData[formElementIdentifier] = flusso[formElementIdentifier].value;
                    }
              console.log(flussoData)
              props.onModificaFlusso(flussoData, props.token);
              toggle();
            }

      const submitEliminaFlussoHandler = ( event ) => {
              event.preventDefault();
              const flussoData = {};
                    for (let formElementIdentifier in flusso) {
                      flussoData[formElementIdentifier] = flusso[formElementIdentifier].value;
                    }
              console.log(flussoData)
              props.onEliminaFlusso(flussoData, props.token);
              toggle();
            }

    
        return (
            <div>
            <Button color="primary" onClick={nuovaFlussoHandler} style={{heigth: "15px", fontSize: "0.8rem", padding: "2px", margin: "5px"}}>Inserisci un nuovo tipo di flusso</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{modifica ? 'MODIFICA TIPO DI FLUSSO' : 'INSERISCI NOVO TIPO DI FLUSSO'}</ModalHeader>
        <ModalBody>
        <Form onSubmit={addFlussoHandler}>
                      <Input  type="hidden" name="id" id="id" value={flusso.id} />
                                
                 <FormGroup row>
                        <Col >
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>NOME FLUSSO</InputGroupText>
                                </InputGroupAddon>
                                <Input valid={flusso.name.valid} invalid={!flusso.name.valid}  type="text" name="name" id="name"  placeholder="Nome flusso" value={flusso.name.value} onChange={(event) => inputChangedHandler(event, "name")}/>
                                
                                </InputGroup>
                        </Col>
                </FormGroup>
                <FormGroup row>
                <Col >
                    
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                                <InputGroupText>TIPO FLUSSO</InputGroupText>
                        </InputGroupAddon>
                        <Input valid={flusso.tipo.valid} invalid={!flusso.tipo.valid} type="select" name="tipo" id="tipo" value={flusso.tipo.value} onChange={(event) => inputChangedHandler(event, "tipo")}>
                            <option value='' >Seleziona il tipo...</option>
                            <option value='entrata'>entrata</option>
                            <option value='uscita'>uscita</option>
                        </Input>
                    </InputGroup>  
                    
                </Col>
                </FormGroup>
          
    </Form>
        </ModalBody>
        <ModalFooter>
        {modifica ? <Button color="primary" onClick={submitModificaFlussoHandler}>Modifica</Button> : <Button color="primary" onClick={addFlussoHandler}>Inserisci</Button>}{' '}
        {modifica ? <Button color="danger" onClick={submitEliminaFlussoHandler}>Elimina</Button> : null}{' '}
          <Button color="secondary" onClick={toggle}>Indietro</Button>
        </ModalFooter>
      </Modal>
      <h3>TIPI DI USCITE/ENTRATE</h3>
               {flussiList} 
            </div>
            
        );
    
}

const mapStateToProps = state => {
    return {
        flussi: state.flussi.flussi,
        loading: state.flussi.loading,
        token: state.auth.token
    }
    
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchFlussi: (token) => dispatch(actions.fetchFlussi(token)),
        onAddFlusso: (flussorData, token) => dispatch(actions.addFlusso(flussorData, token)),
        onModificaFlusso: (flussoData, token) => dispatch(actions.modificaFlusso(flussoData, token)),
        onEliminaFlusso: (flussoData, token) => dispatch(actions.eliminaFlusso(flussoData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Flussi, axios));
