import React, { useEffect, useState } from 'react';
import axios from '../../hoc/axios-vendite';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
//import Pagamento from './Pagamento/Pagamento';
//import PagamentoCard from './Pagamento/PagamentoCard';
import * as actions from '../../store/actions/index';
import { Spinner } from 'reactstrap';
//import classes from './Pagamenti.module.css';
//import Aux from '../../hoc/Aux_/Aux';
import ModificaPagamento from './ModificaPagamento';
import DataTable from 'react-data-table-component';
import { dataNormale, dataStringa } from '../../shared/utility';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';

let today = new Date();

const HeaderComponent = ({
  filterText,
  onFilter,
  onClear,
  onAdd,
  onFromDate,
  onToDate,
  filterFromDate,
  filterToDate,
}) => (
  <Form inline>
    <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
      <Label className='mr-sm-2'>Dal </Label>
      <Input
        id='fromDate'
        type='date'
        placeholder='Dal...'
        value={
          filterFromDate
            ? filterFromDate
            : dataStringa(new Date(today.getFullYear(), today.getMonth(), 1))
        }
        onChange={onFromDate}
        style={{ width: '180px', cursor: 'pointer' }}
      />
    </FormGroup>

    <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
      <Label className='mr-sm-2'>Al </Label>

      <Input
        id='toDate'
        type='date'
        placeholder='Dal...'
        value={
          filterToDate
            ? filterToDate
            : dataStringa(
                new Date(today.getFullYear(), today.getMonth() + 1, 0)
              )
        }
        onChange={onToDate}
        style={{ width: '180px', cursor: 'pointer' }}
      />
    </FormGroup>

    <FormGroup>
      <InputGroup>
        <Input
          id='search'
          type='text'
          placeholder='Cerca...'
          value={filterText}
          onChange={onFilter}
          style={{ width: '180px', cursor: 'pointer' }}
        />
        <InputGroupAddon addonType='append'>
          <Button onClick={onClear}>
            <i className='fas fa-times-circle'></i>
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
    <FormGroup>
      <Button className='ml-1' color='primary' onClick={onAdd}>
        {' '}
        <i className='fas fa-plus' /> Nuovo
      </Button>
    </FormGroup>
    {/* <TextField  />
    <ClearButton type="button" onClick={onClear}>X</ClearButton> */}
  </Form>
);

const Pagamenti = (props) => {
  const {
    onFetchPagamenti,
    onFetchFatture,
    onFetchCodPagamenti,
    onFetchFornitori,
    token,
  } = props;

  let dataTableList = <Spinner />;
  //  let pagamentiDataTable = [];
  let columns = [];
  let today = new Date();

  const [modal, setModal] = useState(false);
  const [modifica, setModifica] = useState(false);
  const [pagamento, setPagamento] = useState(null);

  const [filterText, setFilterText] = React.useState('');
  const [filterFromDate, setFilterFromDate] = React.useState(
    dataStringa(new Date(today.getFullYear(), today.getMonth(), 1))
  );
  const [filterToDate, setFilterToDate] = React.useState(
    dataStringa(new Date(today.getFullYear(), today.getMonth() + 1, 0))
  );
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  let filteredItems = [];

  const toggle = () => setModal(!modal);
  const modificaPagamentoHandler = (pagamentoData) => {
    setModifica(true);
    //console.log(pagamentoData);

    if (pagamentoData.id !== '') {
      setPagamento(pagamentoData);

      toggle();
    }
  };

  // const nuovoPagamentoHandler = () => {
  //   setModifica(false);
  //   //console.log(pagamentoData);
  //   setPagamento(null);
  //   toggle();
  // };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        setFilterFromDate('');
        setFilterToDate('');
      }
    };

    //console.log(filterFromDate);

    return (
      <HeaderComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        onFromDateì
        onFromDate={(e) => setFilterFromDate(e.target.value)}
        onToDate={(e) => setFilterToDate(e.target.value)}
       // onAdd={nuovoPagamentoHandler}
        filterFromDate={filterFromDate}
        filterToDate={filterToDate}
      />
    );
  }, [filterText, filterFromDate, filterToDate, resetPaginationToggle]);

  useEffect(() => {
    onFetchPagamenti(token);
  }, [onFetchPagamenti, token]);

  useEffect(() => {
    onFetchCodPagamenti(token);
  }, [onFetchCodPagamenti, token]);

  useEffect(() => {
    onFetchFatture(token);
  }, [onFetchFatture, token]);

  useEffect(() => {
    onFetchFornitori(token);
  }, [onFetchFornitori, token]);

  if (
    !props.loading &&
    !props.loadingFatture &&
    !props.loadingCodPagamenti &&
    props.fatture &&
    props.codPagamenti
  ) {
    props.pagamenti.sort((a, b) => (a.data > b.data ? 1 : -1));

    //console.log(props.fatture);

    let pagamentiDataTable = [];
    let fat = null; //fatture
    let forn = null; //fornitori
    let codPag = null; //Codice pagamento
    props.pagamenti.map((p) => {
      fat = props.fatture.filter(function (hero) {
        return hero.id === p.fattura;
      });
      forn = props.fornitori.filter(function (hero) {
        return hero.codiceFiscale === fat[0].fornitore;
      });
      codPag = props.codPagamenti.filter(function (hero) {
        return hero.codicePagamento === p.modalita;
      });
      pagamentiDataTable.push({
        ...p,
        numeroFattura: fat[0].numero,
        dataFattura: dataNormale(fat[0].data),
        dataScadenza: p.scadenza,
        scadenza: p.scadenza,
        fornitore: forn[0]?.denominazione,
        modalita: codPag[0]?.tipoPagamento,
      });
      return null;
    });
    //console.log(pagamentiDataTable);
    //console.log(pagamentiDataTable.length);

    columns = [
      {
        name: 'Fornitore',
        selector: 'fornitore',
        sortable: true,
        grow: 2,
      },
      {
        name: 'Numero',
        selector: 'numeroFattura',
        sortable: true,
        grow: 2,
      },
      {
        name: 'Data',
        selector: 'dataFattura',
        sortable: true,
        right: true,
      },
      {
        name: 'Rata',
        selector: 'rata',
        sortable: true,
        right: true,
      },
      {
        name: 'Scadenza',
        selector: 'scadenza',
        sortable: true,
        right: true,
      },
      {
        name: 'Importo',
        selector: 'importo',
        sortable: true,
        right: true,
      },
      {
        name: 'Modalità',
        selector: 'modalita',
        sortable: true,
        right: true,
      },
      {
        name: 'Data pagamento',
        selector: 'dataPagamento',
        sortable: true,
        right: true,
      },
    ];
    let conditionalRowStyles = [];
    conditionalRowStyles = [
      // You can also pass a callback to style for additional customization
      {
        when: (row) => new Date(row.scadenza) < new Date(),
        style: {
          backgroundColor: 'pink',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
      {
        when: (row) => !!row.dataPagamento,
        style: {
          backgroundColor: '#D5F5E3',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    ];

    // console.log(conditionalRowStyles);

    if (pagamentiDataTable.length > 0) {
      // console.log(Date.today() );
      filteredItems = pagamentiDataTable.filter(
        (item) =>
          (item.numeroFattura &&
            item.numeroFattura
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.fornitore &&
            item.fornitore.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.importo &&
            item.importo.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.scadenza &&
            item.scadenza.toLowerCase().includes(filterText.toLowerCase()))
      );

      //console.log(filterFromDate);
      filteredItems = filteredItems.filter(
        (item) =>
          item.dataScadenza &&
          new Date(item.dataScadenza) >= new Date(filterFromDate) &&
          item.dataScadenza &&
          new Date(item.dataScadenza) <= new Date(filterToDate)
      );

      dataTableList = (
        <DataTable
          title='Pagamenti'
          columns={columns}
          data={filteredItems}
          conditionalRowStyles={conditionalRowStyles}
          onRowClicked={modificaPagamentoHandler}
          striped
          highlightOnHover
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
        />
      );
    }
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModificaPagamento
          pagamentoData={pagamento}
          toggle={toggle}
          modifica={modifica}
        />
      </Modal>

      {/* {pagamentiList}  */}

      {dataTableList}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    codPagamenti: state.codPagamenti.codPagamenti,
    loadingCodPagamenti: state.codPagamenti.loading,
    fornitori: state.fornitori.fornitori,
    loadingFornitori: state.fornitori.loading,
    fatture: state.fatture.fatture,
    loadingFatture: state.fatture.loading,
    pagamenti: state.pagamenti.pagamenti,
    loading: state.pagamenti.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchFornitori: (token) => dispatch(actions.fetchFornitori(token)),
    onFetchFatture: (token) => dispatch(actions.fetchFatture(token)),
    onFetchCodPagamenti: (token) => dispatch(actions.fetchCodPagamenti(token)),
    onFetchPagamenti: (token) => dispatch(actions.fetchPagamenti(token)),
    onAddPagamento: (pagamentorData, token) =>
      dispatch(actions.addPagamento(pagamentorData, token)),
    onModificaPagamento: (pagamentoData, token) =>
      dispatch(actions.modificaPagamento(pagamentoData, token)),
    onEliminaPagamento: (pagamentoData, token) =>
      dispatch(actions.eliminaPagamento(pagamentoData, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Pagamenti, axios));
