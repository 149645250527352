import * as actionTypes from './actionTypes';
import { dataStringa } from '../../shared/utility.js';
import axios from '../../hoc/axios-vendite';

export const fetchVenditeStart = () => {
    return {
        type: actionTypes.FETCH_VENDITE_START
    };
};

export const fetchVenditeSuccess = (vendite) => {
    return {
        type: actionTypes.FETCH_VENDITE_SUCCESS,
        vendite : vendite        
    };
};

export const fetchVenditeFail = (error) => {
    return {
        type: actionTypes.FETCH_VENDITE_FAIL,
        error: error
    };
};

export const fetchVendite= (token, dataInizio = new Date(), dataFine = null) => {

    // console.log('fetchVendita: ',dataFine);
    // console.log('fetchVendita: ',dataFine);
   
    dataInizio = dataStringa(dataInizio);
   
    
    dataFine = (dataFine ? dataStringa(dataFine): dataStringa(dataFine.setDate(dataInizio.getDate() + 1)));
    
    //console.log("data inizio:" + dataInizio + " - data fine:" + dataFine);

   return dispatch => {
        dispatch(fetchVenditeStart());
        const queryParams = '?auth=' + token + '&orderBy="dataVendita"&startAt="' + dataInizio + '"&endAt="' + dataFine + '"';
       // console.log('/vendite.json', queryParams);
        axios.get('/vendite.json' + queryParams )
    .then(res => {
        // console.log(res.data);
        const fetchedVendite=[];
        for (let key in res.data) {
            fetchedVendite.push({
                ...res.data[key],
                id: key
            });
        }
        dispatch(fetchVenditeSuccess(fetchedVendite));
        
        
    })
    .catch(err => {
        dispatch(fetchVenditeFail(err));
        console.log(err);        
    });
    }
}
