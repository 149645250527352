import * as actionTypes from './actionTypes';
import axios from '../../hoc/axios-vendite';

//create add fornitore Start action
export const addFornitoreStart= () => {
    return  {
        type: actionTypes.ADD_FORNITORE_START
        };
    };
    
export const addFornitoreSuccess = (id,fornitoreData) => {
        return {

        type: actionTypes.ADD_FORNITORE_SUCCESS,
        fornitoreId: id,
        fornitoreData: fornitoreData
    }
}

export const addFornitoreFail= (error) => {
    return {
        type: actionTypes.ADD_FORNITORE_FAIL,
        error: error
    }
}

export const addFornitore = (fornitoreData, token) => {
    return dispatch => {
        dispatch(addFornitoreStart());
       // console.log('sto chiamando axios')
        axios.post( '/fornitori.json?auth=' + token, fornitoreData )
            .then( response => {
               console.log(response.data.id)
                dispatch( addFornitoreSuccess(response.data.id, response.data));
                //console.log(response.data.name)
                dispatch(fetchFornitori(token));
                dispatch(fetchFornitore(fornitoreData.codiceFiscale, token));
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( addFornitoreFail(error));
               
            } );
    }
}

export const addFornitoreInit = () => {
    return  {
        type: actionTypes.ADD_FORNITORE_INIT
        };
    };

//create add fornitore Start action
export const modificaFornitoreStart= () => {
    return  {
        type: actionTypes.MODIFICA_FORNITORE_START
        };
    };
    
export const modificaFornitoreSuccess = (id,fornitoreData) => {
        return {

        type: actionTypes.MODIFICA_FORNITORE_SUCCESS,
        fornitoreId: id,
        fornitoreData: fornitoreData
    }
}

export const modificaFornitoreFail= (error) => {
    return {
        type: actionTypes.MODIFICA_FORNITORE_FAIL,
        error: error
    }
}

export const modificaFornitore = (fornitoreData, token) => {
    return dispatch => {
        dispatch(modificaFornitoreStart());
        console.log('sto chiamando axios')
        axios.patch( '/fornitori/' +  fornitoreData.id + '.json?auth=' + token, fornitoreData )
            .then( response => {
                console.log(response.data.name)
                dispatch( modificaFornitoreSuccess(response.data.name, fornitoreData))
                //console.log(response.data.name)
                dispatch(fetchFornitori(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( modificaFornitoreFail(error));
               
            } );
    }
}

//create elimina fornitore Start action
export const eliminaFornitoreStart= () => {
    return  {
        type: actionTypes.ELIMINA_FORNITORE_START
        };
    };
    
export const eliminaFornitoreSuccess = (fornitoreData) => {
        return {

        type: actionTypes.ELIMINA_FORNITORE_SUCCESS,
        fornitoreId: fornitoreData.id
    }
}

export const eliminaFornitoreFail= (error) => {
    return {
        type: actionTypes.ELIMINA_FORNITORE_FAIL,
        error: error
    }
}

export const eliminaFornitore = (fornitoreData, token) => {
    return dispatch => {
        dispatch(eliminaFornitoreStart());
        console.log('sto chiamando axios per cancellare')
        axios.delete( '/fornitori/' +  fornitoreData.id + '.json?auth=' + token, fornitoreData )
            .then( response => {
                dispatch( eliminaFornitoreSuccess(fornitoreData))
                //console.log(response.data.name)
                dispatch(fetchFornitori(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( eliminaFornitoreFail(error));
               
            } );
    }
}

export const fetchFornitoriStart = () => {
    return {
        type: actionTypes.FETCH_FORNITORI_START
    };
};

export const fetchFornitoriSuccess = (fornitori) => {
    return {
        type: actionTypes.FETCH_FORNITORI_SUCCESS,
        fornitori : fornitori        
    };
};

export const fetchFornitoriFail = (error) => {
    return {
        type: actionTypes.FETCH_FORNITORI_FAIL,
        error: error
    };
};

export const fetchFornitori= (token) => {
   return dispatch => {
        dispatch(fetchFornitoriStart());
        const queryParams = '?auth=' + token ;
        axios.get('/fornitori.json' + queryParams )
    .then(res => {
        const fetchedFornitori=[];
        for (let key in res.data) {
            fetchedFornitori.push({
                ...res.data[key],
                id: key
            });
        }
        dispatch(fetchFornitoriSuccess(fetchedFornitori))
        
    })
    .catch(err => {
        dispatch(fetchFornitoriFail(err))        
    });
    }
}


//Fetch singolo fornitore

export const fetchFornitoreStart = () => {
    return {
        type: actionTypes.FETCH_FORNITORE_START
    };
};

export const fetchFornitoreSuccess = (fornitore) => {
    return {
        type: actionTypes.FETCH_FORNITORE_SUCCESS,
        fornitore : fornitore        
    };
};

export const fetchFornitoreFail = (error) => {
    return {
        type: actionTypes.FETCH_FORNITORE_FAIL,
        error: error
    };
};

export const fetchFornitore= (cf, token) => {
   return dispatch => {
        dispatch(fetchFornitoreStart());
       // console.log(token)
        const queryParams = '?auth=' + token  + '&orderBy="codiceFiscale"&equalTo="' + cf +'"';

        axios.get('/fornitori.json' + queryParams )
    .then(res => {
       //console.log(res.data)
        let fetchedFornitore=null;

        for (let key in res.data) {
            fetchedFornitore = res.data[key];
            };
        dispatch(fetchFornitoreSuccess(fetchedFornitore))
        
    })
    .catch(err => {
        dispatch(fetchFornitoreFail(err))        
    });
    }
}
