import React, {  useEffect, useState } from 'react';
import axios from '../../hoc/axios-vendite';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import CodPagamento from './CodPagamento/CodPagamento';
import * as actions from '../../store/actions/index';
import { Spinner, Table } from 'reactstrap';
import classes from './CodPagamenti.module.css';
import { checkValidity,updateObject } from '../../shared/utility.js';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup,  
  Input, Label } from 'reactstrap';


const CodPagamenti = props => {

    const {
        className,
        onFetchCodPagamenti,
      } = props;

      
      const [modal, setModal] = useState(false);
      const [modifica, setModifica] = useState(false);

      const [ codPagamento, setCodPagamento] = useState({
        id:   { value: '',
                validation: { required: false},
                valid: true},
        codicePagamento: { value: '',
                validation: { required: true},
                valid: false},
        tipoPagamento: { value: '',
                validation: { required: true},
                valid: false}
        })
    
      const toggle = () => setModal(!modal);

      const modificaCodPagamentoHandler = (codPagamentoData) => {
        setModifica(true);
        console.log(codPagamentoData);
        setCodPagamento({ id: {value: codPagamentoData.id, valid: true}, 
                          codicePagamento: {value: codPagamentoData.codicePagamento, valid: true},
                          tipoPagamento: {value: codPagamentoData.tipoPagamento, valid: true}}
                          );
        console.log(codPagamento);
        if(codPagamento.id !== ''){
          toggle();
        }
        
      }  


      const nuovaCodPagamentoHandler = () => {
        setModifica(false);
        //console.log(codPagamentoData);
        setCodPagamento({ id: {value: '', valid: true}, 
                          codicePagamento: {value: '', valid: false},
                          tipoPagamento: {value: '', valid: false}
                        });
        toggle();
        
        
      }  
   
    useEffect( () => {
      onFetchCodPagamenti(props.token)}, [onFetchCodPagamenti, props.token] );

      let codPagamentiList =  <Spinner />;

        if (!props.loading && props.codPagamenti ) {
          
          props.codPagamenti.sort((a, b) => (a.codicePagamento > b.codicePagamento) ? 1 : -1);

          codPagamentiList = (
            <Table hover className={classes.Table}>
                <thead>  
                  <tr >
                    <th style={{fontSize: "1.8rem", textAlign: "left"}}>Codice</th>
                    <th style={{fontSize: "1.8rem", textAlign: "left"}}>Tipo</th>
                  </tr>
                </thead>
                <tbody>

                {props.codPagamenti.map(codPagamento => (
                        <CodPagamento 
                            codPagamento = {codPagamento}
                            key={codPagamento.id}
                            codicePagamento={codPagamento.codicePagamento}
                            tipoPagamento={codPagamento.tipoPagamento}
                            onClicked={modificaCodPagamentoHandler}/> 
                ))  }
            </tbody>
              </Table>
            )
        };

    

    

    const inputChangedHandler = (event, inputIdentifier) => { 
        

      const updatedFormElement = updateObject(codPagamento[inputIdentifier], {
          value: event.target.value,
          valid: checkValidity(event.target.value, codPagamento[inputIdentifier].validation),
          

      } );

      const updatedCodPagamentoForm = updateObject(codPagamento,{
          [inputIdentifier]: updatedFormElement
      });
      
      let formIsValid = true;
      for (let inputIdentifier in updatedCodPagamentoForm) {
          formIsValid = updatedCodPagamentoForm[inputIdentifier].valid && formIsValid;
      }
      setCodPagamento(updatedCodPagamentoForm);
   
     // setFormIsValid(formIsValid);
  }
  
      
      const addCodPagamentoHandler = ( event ) => {
              event.preventDefault();
              const codPagamentoData = {};
                    for (let formElementIdentifier in codPagamento) {
                      codPagamentoData[formElementIdentifier] = codPagamento[formElementIdentifier].value;
                    }
              //console.log(codPagamentoData)
              props.onAddCodPagamento(codPagamentoData, props.token);
              toggle();
            }

      const submitModificaCodPagamentoHandler = ( event ) => {
              event.preventDefault();
              const codPagamentoData = {};
                    for (let formElementIdentifier in codPagamento) {
                      codPagamentoData[formElementIdentifier] = codPagamento[formElementIdentifier].value;
                    }
              //console.log(codPagamentoData)
              props.onModificaCodPagamento(codPagamentoData, props.token);
              toggle();
            }

      const submitEliminaCodPagamentoHandler = ( event ) => {
              event.preventDefault();
              const codPagamentoData = {};
                    for (let formElementIdentifier in codPagamento) {
                      codPagamentoData[formElementIdentifier] = codPagamento[formElementIdentifier].value;
                    }
              //console.log(codPagamentoData)
              props.onEliminaCodPagamento(codPagamentoData, props.token);
              toggle();
            }

    
        return (
            <div>
            <Button color="primary" onClick={nuovaCodPagamentoHandler} style={{heigth: "15px", fontSize: "0.8rem", padding: "2px", margin: "5px"}}>Inserisci un nuovo tipo di codPagamento</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{modifica ? 'MODIFICA TIPO DI PAGAMENTO' : 'INSERISCI NUOVO TIPO DI PAGAMENTO'}</ModalHeader>
        <ModalBody>
        <Form onSubmit={addCodPagamentoHandler}>
                      <Input  type="hidden" name="id" id="id" value={codPagamento.id} />
                                
                <FormGroup >
                  <Label>Codice pagamento</Label>
                  <Input valid={codPagamento.codicePagamento.valid} invalid={!codPagamento.codicePagamento.valid}  type="text" name="codicePagamento" id="codicePagamento"  placeholder="Codice Pagamento" value={codPagamento.codicePagamento.value} onChange={(event) => inputChangedHandler(event, "codicePagamento")}/>
                </FormGroup>

                <FormGroup >
                  <Label>Tipo di Pagamento</Label>
                  <Input valid={codPagamento.tipoPagamento.valid} invalid={!codPagamento.tipoPagamento.valid}  type="text" name="tipoPagamento" id="tipoPagamento"  placeholder="Tipo di Pagamento" value={codPagamento.tipoPagamento.value} onChange={(event) => inputChangedHandler(event, "tipoPagamento")}/>
                </FormGroup>
               
          
    </Form>
        </ModalBody>
        <ModalFooter>
        {modifica ? <Button color="primary" onClick={submitModificaCodPagamentoHandler}>Modifica</Button> : <Button color="primary" onClick={addCodPagamentoHandler}>Inserisci</Button>}{' '}
        {modifica ? <Button color="danger" onClick={submitEliminaCodPagamentoHandler}>Elimina</Button> : null}{' '}
          <Button color="secondary" onClick={toggle}>Indietro</Button>
        </ModalFooter>
      </Modal>
      <h3>TIPI DI PAGAMENTO</h3>

               {codPagamentiList} 
            </div>
            
        );
    
}

const mapStateToProps = state => {
    return {
        codPagamenti: state.codPagamenti.codPagamenti,
        loading: state.codPagamenti.loading,
        token: state.auth.token
    }
    
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCodPagamenti: (token) => dispatch(actions.fetchCodPagamenti(token)),
        onAddCodPagamento: (codPagamentoData, token) => dispatch(actions.addCodPagamento(codPagamentoData, token)),
        onModificaCodPagamento: (codPagamentoData, token) => dispatch(actions.modificaCodPagamento(codPagamentoData, token)),
        onEliminaCodPagamento: (codPagamentoData, token) => dispatch(actions.eliminaCodPagamento(codPagamentoData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(CodPagamenti, axios));
