import React, { useEffect, useState } from 'react';
import axios from '../../hoc/axios-vendite';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
import { Spinner } from 'reactstrap';
import { Form, FormGroup, Col, Input, Row, Label } from 'reactstrap';
import { getMonday } from '../../shared/utility.js';
import Dettaglio from '../../components/Stats/Dettaglio.js';

const oggettiVenditaInitialState = {
  montatureVista: { nome: 'Montature vista', numero: 0, prezzo: 0 },
  montatureSole: { nome: 'Montature sole', numero: 0, prezzo: 0 },
  lenti: { nome: 'Lenti', numero: 0, prezzo: 0 },
  lac: { nome: 'Lac', numero: 0, prezzo: 0 },
  liquidi: { nome: 'Liquidi', numero: 0, prezzo: 0 },
  altro: { nome: 'Altro', numero: 0, prezzo: 0 },
};

const Stats = (props) => {
  const { onFetchVendite } = props;

  let oggi = new Date();
  //   let df = new Date();
  let oggi30 = new Date();
  oggi30 = oggi30.setDate(oggi.getDate() - 30);
  // df = df.setDate(oggi.getDate() + 1 );
  const [anno, setAnno] = useState(oggi.getFullYear());
  const [intervallo, setIntervallo] = useState('Mese in corso');
  const [dataInizio, setDataInizio] = useState(
    new Date(oggi.getFullYear(), oggi.getMonth(), 1)
  );
  const [dataFine, setDataFine] = useState(
    new Date(anno, oggi.getMonth() + 1, 1)
  );

  let updatedOggettiVendita = oggettiVenditaInitialState;

  //    let updatedOggettiVenditaPerMese = '';

  useEffect(() => {
    // console.log(props.vendite.lenght);

    onFetchVendite(props.token, dataInizio, dataFine);
  }, [onFetchVendite, props.token, dataInizio, dataFine]);

  const annoChangeHandler = (event) => {
    console.log(event.target.value);

    setAnno(event.target.value);
    let inizio = new Date(dataInizio);
    inizio.setFullYear(event.target.value);
    let fine = new Date(dataFine);
    fine.setFullYear(event.target.value);
    setDataInizio(inizio);
    setDataFine(fine);
    console.log(inizio, fine);
  };

  let venditeList = <Spinner />;

  //  console.log(props.vendite);

  if (!props.loading && props.vendite.length > 0) {
    let updatedOggettiVendita = { ...oggettiVenditaInitialState };

    let montatureVista = props.vendite
      .filter((vendita) => vendita.tipoMontatura === 'vista')
      .map((vendita) => vendita.prezzoMontatura);
    let montatureSole = props.vendite
      .filter((vendita) => vendita.tipoMontatura === 'sole')
      .map((vendita) => vendita.prezzoMontatura);
    let lenti = props.vendite
      .filter(
        (vendita) =>
          vendita.tipoVendita === 'occhialeCompleto' ||
          vendita.tipoVendita === 'occhialeCompletoSole' ||
          vendita.tipoVendita === 'lenti'
      )
      .map((vendita) => vendita.prezzoLenti);
    let lac = props.vendite
      .filter((vendita) => vendita.tipoVendita === 'lac')
      .map((vendita) => vendita.prezzoLac);
    let liquidi = props.vendite
      .filter((vendita) => vendita.tipoVendita === 'liquido')
      .map((vendita) => vendita.prezzoLiquido);
    let altro = props.vendite
      .filter((vendita) => vendita.tipoVendita === 'altro')
      .map((vendita) => vendita.prezzoAltro);

    updatedOggettiVendita.montatureVista = {
      ...updatedOggettiVendita.montatureVista,
      numero: montatureVista.length,
      prezzo: montatureVista.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
    };
    updatedOggettiVendita.montatureSole = {
      ...updatedOggettiVendita.montatureSole,
      numero: montatureSole.length,
      prezzo: montatureSole.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
    };
    updatedOggettiVendita.lenti = {
      ...updatedOggettiVendita.lenti,
      numero: lenti.length,
      prezzo: lenti.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
    };
    updatedOggettiVendita.lac = {
      ...updatedOggettiVendita.lac,
      numero: lac.length,
      prezzo: lac.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
    };
    updatedOggettiVendita.liquidi = {
      ...updatedOggettiVendita.liquidi,
      numero: liquidi.length,
      prezzo: liquidi.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
    };
    updatedOggettiVendita.altro = {
      ...updatedOggettiVendita.altro,
      numero: altro.length,
      prezzo: altro.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
    };

    venditeList = (
      <Dettaglio
        vendite={updatedOggettiVendita}
        dataInizio={dataInizio}
        dataFine={dataFine}
      />
    );
  }

  if (!props.loading && props.vendite.length < 1) {
    //console.log(props.vendite);
    venditeList = (
      <Dettaglio
        vendite={updatedOggettiVendita}
        dataInizio={dataInizio}
        dataFine={dataFine}
      />
    );
  }

  const intervalloChangeHandler = (event) => {
    setIntervallo(event.target.value);
    //console.log(event.target.value);
    oggi = new Date();
    console.log(oggi);
    let today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (event.target.value === 'Ultimi 30 giorni') {
      setDataInizio(oggi30);
      setDataFine(tomorrow);
      setAnno(oggi.getFullYear());
      onFetchVendite(props.token, oggi30, tomorrow);
    }

    if (event.target.value === 'Settimana in corso') {
      setDataInizio(getMonday(oggi));
      setDataFine(tomorrow);
      setAnno(oggi.getFullYear());
      // console.log(getMonday(oggi));
      // console.log(tomorrow);
      onFetchVendite(props.token, getMonday(oggi), tomorrow);
    }

    if (event.target.value === 'Mese in corso') {
      setDataInizio(new Date(oggi.getFullYear(), oggi.getMonth(), 1));
      setDataFine(new Date(oggi.getFullYear(), oggi.getMonth() + 1, 1));
      setAnno(oggi.getFullYear());
      onFetchVendite(
        props.token,
        new Date(oggi.getFullYear(), oggi.getMonth(), 1),
        new Date(oggi.getFullYear(), oggi.getMonth() + 1, 1)
      );
    }

    if (event.target.value === "Tutto l'anno") {
      setDataInizio(new Date(anno, 0, 1));
      setDataFine(new Date(parseFloat(anno) + 1, 0, 1));
      console.log(new Date(parseFloat(anno) + 1, 0, 1));
      onFetchVendite(
        props.token,
        new Date(anno, 1, 1),
        new Date(parseFloat(anno) + 1, 0, 1)
      );
    }

    if (event.target.value === 'Gennaio') {
      setDataInizio(new Date(anno, 0, 1));
      setDataFine(new Date(anno, 1, 1));
      onFetchVendite(props.token, new Date(anno, 0, 1), new Date(anno, 1, 1));
    }

    if (event.target.value === 'Febbraio') {
      setDataInizio(new Date(anno, 1, 1));
      setDataFine(new Date(anno, 2, 1));
      onFetchVendite(props.token, new Date(anno, 1, 1), new Date(anno, 2, 1));
    }

    if (event.target.value === 'Marzo') {
      setDataInizio(new Date(anno, 2, 1));
      setDataFine(new Date(anno, 3, 1));
      onFetchVendite(props.token, new Date(anno, 2, 1), new Date(anno, 3, 1));
    }

    if (event.target.value === 'Aprile') {
      setDataInizio(new Date(anno, 3, 1));
      setDataFine(new Date(anno, 4, 1));
      onFetchVendite(props.token, new Date(anno, 3, 1), new Date(anno, 4, 1));
    }

    if (event.target.value === 'Maggio') {
      setDataInizio(new Date(anno, 4, 1));
      setDataFine(new Date(anno, 5, 1));
      onFetchVendite(props.token, new Date(anno, 4, 1), new Date(anno, 5, 1));
    }

    if (event.target.value === 'Giugno') {
      setDataInizio(new Date(anno, 5, 1));
      setDataFine(new Date(anno, 6, 1));
      onFetchVendite(props.token, new Date(anno, 5, 1), new Date(anno, 6, 1));
    }

    if (event.target.value === 'Luglio') {
      setDataInizio(new Date(anno, 6, 1));
      setDataFine(new Date(anno, 7, 1));
      onFetchVendite(props.token, new Date(anno, 6, 1), new Date(anno, 7, 1));
    }

    if (event.target.value === 'Agosto') {
      setDataInizio(new Date(anno, 7, 1));
      setDataFine(new Date(anno, 8, 1));
      onFetchVendite(props.token, new Date(anno, 7, 1), new Date(anno, 8, 1));
    }

    if (event.target.value === 'Settembre') {
      setDataInizio(new Date(anno, 8, 1));
      setDataFine(new Date(anno, 9, 1));
      onFetchVendite(props.token, new Date(anno, 8, 1), new Date(anno, 9, 1));
    }

    if (event.target.value === 'Ottobre') {
      setDataInizio(new Date(anno, 9, 1));
      setDataFine(new Date(anno, 10, 1));
      onFetchVendite(props.token, new Date(anno, 9, 1), new Date(anno, 10, 1));
    }

    if (event.target.value === 'Novembre') {
      setDataInizio(new Date(anno, 10, 1));
      setDataFine(new Date(anno, 11, 1));
      onFetchVendite(props.token, new Date(anno, 10, 1), new Date(anno, 11, 1));
    }

    if (event.target.value === 'Dicembre') {
      setDataInizio(new Date(anno, 11, 1));
      setDataFine(new Date(anno, 12, 1));
      onFetchVendite(
        props.token,
        new Date(anno, 11, 1),
        new Date(anno + 1, 1, 1)
      );
    }
  };

  return (
    <div style={{ padding: '5px' }}>
      <Form>
        <Row>
          <Col>
            <FormGroup>
              <Label>Anno</Label>
              <Input
                type='select'
                name='anno'
                id='anno'
                placeholder='anno'
                value={anno}
                onChange={(event) => annoChangeHandler(event)}
              >
                <option value={anno}>{anno}</option>
                <option value='2020'>2020</option>
                <option value='2021'>2021</option>
                <option value='2022'>2022</option>
                <option value='2023'>2023</option>
                <option value='2024'>2024</option>
                <option value='2025'>2025</option>
                <option value='2026'>2026</option>
                <option value='2027'>2027</option>
                <option value='2028'>2028</option>
              </Input>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label>Intervallo</Label>

              <Input
                type='select'
                name='intervallo'
                id='intervallo'
                placeholder='Intervallo'
                value={intervallo}
                onChange={(event) => intervalloChangeHandler(event)}
              >
                <option>Ultimi 30 giorni</option>
                <option>Settimana in corso</option>
                <option>Mese in corso</option>
                <option>Tutto l'anno</option>
                <option>Gennaio</option>
                <option>Febbraio</option>
                <option>Marzo</option>
                <option>Aprile</option>
                <option>Maggio</option>
                <option>Giugno</option>
                <option>Luglio</option>
                <option>Agosto</option>
                <option>Settembre</option>
                <option>Ottobre</option>
                <option>Novembre</option>
                <option>Dicembre</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      {venditeList}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vendite: state.vendite.vendite,
    loading: state.vendite.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchVendite: (token, dataInizio, dataFine) =>
      dispatch(actions.fetchVendite(token, dataInizio, dataFine)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Stats, axios));
