import * as actionTypes from './actionTypes';
import axios from '../../hoc/axios-vendite';

//create add fattura Start action
export const addFatturaStart= () => {
    return  {
        type: actionTypes.ADD_FATTURA_START
        };
    };
    
export const addFatturaSuccess = (id,fatturaData) => {
        return {

        type: actionTypes.ADD_FATTURA_SUCCESS,
        fatturaId: id,
        fatturaData: fatturaData
    }
}

export const addFatturaFail= (error) => {
    return {
        type: actionTypes.ADD_FATTURA_FAIL,
        error: error
    }
}

export const addFattura = (fatturaData, token) => {
    return dispatch => {
        dispatch(addFatturaStart());
       // console.log('sto chiamando axios')
        axios.post( '/fatture.json?auth=' + token, fatturaData )
            .then( response => {
               //console.log(response.data.id)
                dispatch( addFatturaSuccess(response.data.id, response.data));
                //console.log(response.data.name)
                dispatch(fetchFatture(token));
                dispatch(fetchFattura(fatturaData, token));
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( addFatturaFail(error));
               
            } );
    }
}

export const addFatturaInit = () => {
    return  {
        type: actionTypes.ADD_FATTURA_INIT
        };
    };

//create add fattura Start action
export const modificaFatturaStart= () => {
    return  {
        type: actionTypes.MODIFICA_FATTURA_START
        };
    };
    
export const modificaFatturaSuccess = (id,fatturaData) => {
        return {

        type: actionTypes.MODIFICA_FATTURA_SUCCESS,
        fatturaId: id,
        fatturaData: fatturaData
    }
}

export const modificaFatturaFail= (error) => {
    return {
        type: actionTypes.MODIFICA_FATTURA_FAIL,
        error: error
    }
}

export const modificaFattura = (fatturaData, token) => {
    return dispatch => {
        dispatch(modificaFatturaStart());
        console.log('sto chiamando axios')
        axios.patch( '/fatture/' +  fatturaData.id + '.json?auth=' + token, fatturaData )
            .then( response => {
                console.log(response.data.name)
                dispatch( modificaFatturaSuccess(response.data.name, fatturaData))
                //console.log(response.data.name)
                dispatch(fetchFatture(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( modificaFatturaFail(error));
               
            } );
    }
}

//create elimina fattura Start action
export const eliminaFatturaStart= () => {
    return  {
        type: actionTypes.ELIMINA_FATTURA_START
        };
    };
    
export const eliminaFatturaSuccess = (fatturaData) => {
        return {

        type: actionTypes.ELIMINA_FATTURA_SUCCESS,
        fatturaId: fatturaData.id
    }
}

export const eliminaFatturaFail= (error) => {
    return {
        type: actionTypes.ELIMINA_FATTURA_FAIL,
        error: error
    }
}

export const eliminaFattura = (fatturaData, token) => {
    return dispatch => {
        dispatch(eliminaFatturaStart());
        console.log('sto chiamando axios per cancellare')
        axios.delete( '/fatture/' +  fatturaData.id + '.json?auth=' + token, fatturaData )
            .then( response => {
                dispatch( eliminaFatturaSuccess(fatturaData))
                //console.log(response.data.name)
                dispatch(fetchFatture(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( eliminaFatturaFail(error));
               
            } );
    }
}

export const fetchFattureStart = () => {
    return {
        type: actionTypes.FETCH_FATTURE_START
    };
};

export const fetchFattureSuccess = (fatture) => {
    return {
        type: actionTypes.FETCH_FATTURE_SUCCESS,
        fatture : fatture        
    };
};

export const fetchFattureFail = (error) => {
    return {
        type: actionTypes.FETCH_FATTURE_FAIL,
        error: error
    };
};

export const fetchFatture= (token) => {
   return dispatch => {
        dispatch(fetchFattureStart());
        const queryParams = '?auth=' + token ;
        //console.log(queryParams);
        axios.get('/fatture.json' + queryParams )
    .then(res => {
        const fetchedFatture=[];
        for (let key in res.data) {
            fetchedFatture.push({
                ...res.data[key],
                id: key
            });
        }
        dispatch(fetchFattureSuccess(fetchedFatture))
        
    })
    .catch(err => {
        dispatch(fetchFattureFail(err))        
    });
    }
}


//Fetch singolo fattura

export const fetchFatturaStart = () => {
    return {
        type: actionTypes.FETCH_FATTURA_START
    };
};

export const fetchFatturaSuccess = (fattura) => {
    return {
        type: actionTypes.FETCH_FATTURA_SUCCESS,
        fattura : fattura        
    };
};

export const fetchFatturaFail = (error) => {
    return {
        type: actionTypes.FETCH_FATTURA_FAIL,
        error: error
    };
};

export const fetchFattura= (fatturaData, token) => {
   return dispatch => {
        dispatch(fetchFatturaStart());
        //console.log(token)
        const queryParams = '?auth=' + token  + '&orderBy="fornitore"&equalTo="' + fatturaData.fornitore +'"';
        //console.log('/fatture.json' + queryParams);
        axios.get('/fatture.json' + queryParams )
    .then(res => {
       //console.log(res.data)
        let fetchedFattura=null;
        for (let key in res.data) {
            if (res.data[key].data === fatturaData.data && res.data[key].numero === fatturaData.numero){
                fetchedFattura = {
                    ...res.data[key],
                    id: key};
                }
            };
        //console.log(fetchedFattura);
        dispatch(fetchFatturaSuccess(fetchedFattura))
        
    })
    .catch(err => {
        dispatch(fetchFatturaFail(err))        
    });
    }
}
