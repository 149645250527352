import React from 'react';
import classes from './CodPagamento.module.css';

const CodPagamento = props => {
    
    

        return (
            <tr key = {props.codPagamento.id} onClick={() => props.onClicked(props.codPagamento) } >
                <td className={classes.TableRow} style={{padding: "4px"}}>{props.codPagamento.codicePagamento}</td>
                <td className={classes.TableRow} style={{padding: "4px"}}>{props.codPagamento.tipoPagamento}</td>
                
            </tr>
        )
    
}

export default CodPagamento;