import React, { useEffect, useState } from 'react';
import axios from '../../hoc/axios-vendite';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';

import { Spinner, Table } from 'reactstrap';
import classes from '../../components/Marche/Marche.module.css';
import { Form, FormGroup, Col, Input, Row, Label } from 'reactstrap';
import { getMonday } from '../../shared/utility.js';




const VenditeVista = (props) => {
  const { onFetchVendite, onFetchMarche } = props;

  let oggi = new Date();
  //   let df = new Date();
  let oggi30 = new Date();
  oggi30 = oggi30.setDate(oggi.getDate() - 30);
  // df = df.setDate(oggi.getDate() + 1 );
  const [anno, setAnno] = useState(oggi.getFullYear());
  const [intervallo, setIntervallo] = useState('Mese in corso');
  const [dataInizio, setDataInizio] = useState(
    new Date(oggi.getFullYear(), oggi.getMonth(), 1)
  );
  const [dataFine, setDataFine] = useState(
    new Date(anno, oggi.getMonth() + 1, 1)
  );
  
    const marcheVendita = [];



  useEffect(() => {
    // console.log(props.vendite.lenght);
    onFetchMarche(props.token)}, [onFetchMarche, props.token] );

  useEffect(() => {
    onFetchVendite(props.token, dataInizio, dataFine);
  }, [onFetchVendite, props.token, dataInizio, dataFine]);

  const annoChangeHandler = (event) => {
    console.log(event.target.value);

    setAnno(event.target.value);
    let inizio = new Date(dataInizio);
    inizio.setFullYear(event.target.value);
    let fine = new Date(dataFine);
    fine.setFullYear(event.target.value);
    setDataInizio(inizio);
    setDataFine(fine);
    console.log(inizio, fine);
  };

  let marcheList = <Spinner />;

  //  console.log(props.vendite);

  if (!props.loading && props.vendite.length > 0 && !props.loadingMarche && props.marche.length > 0 ) {


    
    

    
props.marche
      .filter((marca) => marca.tipo === 'montatura')    
      .map(marca => {

          let marcaVendita = props.vendite
          .filter((vendita) => vendita.marcaMontatura === marca.name && vendita.tipoMontatura === 'vista')
          .map((vendita) => vendita.prezzoMontatura);

         // console.log(marcaVendita);

          marcheVendita.push({
                nome: marca.name,
                numero: marcaVendita.length,
                prezzo: marcaVendita.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
          });
      }
       
      )};

       // console.log(marcheVendita);



 
  marcheList = (
      <Table  className={classes.Table}>
          <thead> 
            <tr style={{heigth: "15px", fontSize: "0,8rem", padding: "2px"}}>
              <th style={{fontSize: "1.8rem", textAlign: "left"}}>Marca Vista</th>
              <th style={{fontSize: "1.8rem", textAlign: "left"}}>Pezzi</th>
              <th style={{fontSize: "1.8rem", textAlign: "left"}}>Incasso</th>
            </tr>
          </thead>
          <tbody>

          {
          console.log(marcheVendita.filter((marcaVendita) => marcaVendita.numero > 0))}
          {
          marcheVendita
          .filter((marcaVendita) => marcaVendita.numero > 0)
           .sort((a, b) => (a.numero > b.numero) ? -1 : 1)
          .map(marcaVendita => (
                 
            <tr key = {marcaVendita.nome}  >
            <td className={classes.TableRow} style={{padding: "4px"}}>{marcaVendita.nome}</td>
            <td className={classes.TableRow} style={{padding: "4px"}}>{marcaVendita.numero}</td>
            <td className={classes.TableRow} style={{padding: "4px"}}>{marcaVendita.prezzo}</td>
        </tr>



          ))  }
      </tbody>
        </Table>
      )


 
  const intervalloChangeHandler = (event) => {
    setIntervallo(event.target.value);
    //console.log(event.target.value);
    oggi = new Date();
    console.log(oggi);
    let today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (event.target.value === 'Ultimi 30 giorni') {
      setDataInizio(oggi30);
      setDataFine(tomorrow);
      setAnno(oggi.getFullYear());
      onFetchVendite(props.token, oggi30, tomorrow);
    }

    if (event.target.value === 'Settimana in corso') {
      setDataInizio(getMonday(oggi));
      setDataFine(tomorrow);
      setAnno(oggi.getFullYear());
      // console.log(getMonday(oggi));
      // console.log(tomorrow);
      onFetchVendite(props.token, getMonday(oggi), tomorrow);
    }

    if (event.target.value === 'Mese in corso') {
      setDataInizio(new Date(oggi.getFullYear(), oggi.getMonth(), 1));
      setDataFine(new Date(oggi.getFullYear(), oggi.getMonth() + 1, 1));
      setAnno(oggi.getFullYear());
      onFetchVendite(
        props.token,
        new Date(oggi.getFullYear(), oggi.getMonth(), 1),
        new Date(oggi.getFullYear(), oggi.getMonth() + 1, 1)
      );
    }

    if (event.target.value === "Tutto l'anno") {
      setDataInizio(new Date(anno, 0, 1));
      setDataFine(new Date(parseFloat(anno) + 1, 0, 1));
      console.log(new Date(parseFloat(anno) + 1, 0, 1));
      onFetchVendite(
        props.token,
        new Date(anno, 1, 1),
        new Date(parseFloat(anno) + 1, 0, 1)
      );
    }

    if (event.target.value === 'Gennaio') {
      setDataInizio(new Date(anno, 0, 1));
      setDataFine(new Date(anno, 1, 1));
      onFetchVendite(props.token, new Date(anno, 0, 1), new Date(anno, 1, 1));
    }

    if (event.target.value === 'Febbraio') {
      setDataInizio(new Date(anno, 1, 1));
      setDataFine(new Date(anno, 2, 1));
      onFetchVendite(props.token, new Date(anno, 1, 1), new Date(anno, 2, 1));
    }

    if (event.target.value === 'Marzo') {
      setDataInizio(new Date(anno, 2, 1));
      setDataFine(new Date(anno, 3, 1));
      onFetchVendite(props.token, new Date(anno, 2, 1), new Date(anno, 3, 1));
    }

    if (event.target.value === 'Aprile') {
      setDataInizio(new Date(anno, 3, 1));
      setDataFine(new Date(anno, 4, 1));
      onFetchVendite(props.token, new Date(anno, 3, 1), new Date(anno, 4, 1));
    }

    if (event.target.value === 'Maggio') {
      setDataInizio(new Date(anno, 4, 1));
      setDataFine(new Date(anno, 5, 1));
      onFetchVendite(props.token, new Date(anno, 4, 1), new Date(anno, 5, 1));
    }

    if (event.target.value === 'Giugno') {
      setDataInizio(new Date(anno, 5, 1));
      setDataFine(new Date(anno, 6, 1));
      onFetchVendite(props.token, new Date(anno, 5, 1), new Date(anno, 6, 1));
    }

    if (event.target.value === 'Luglio') {
      setDataInizio(new Date(anno, 6, 1));
      setDataFine(new Date(anno, 7, 1));
      onFetchVendite(props.token, new Date(anno, 6, 1), new Date(anno, 7, 1));
    }

    if (event.target.value === 'Agosto') {
      setDataInizio(new Date(anno, 7, 1));
      setDataFine(new Date(anno, 8, 1));
      onFetchVendite(props.token, new Date(anno, 7, 1), new Date(anno, 8, 1));
    }

    if (event.target.value === 'Settembre') {
      setDataInizio(new Date(anno, 8, 1));
      setDataFine(new Date(anno, 9, 1));
      onFetchVendite(props.token, new Date(anno, 8, 1), new Date(anno, 9, 1));
    }

    if (event.target.value === 'Ottobre') {
      setDataInizio(new Date(anno, 9, 1));
      setDataFine(new Date(anno, 10, 1));
      onFetchVendite(props.token, new Date(anno, 9, 1), new Date(anno, 10, 1));
    }

    if (event.target.value === 'Novembre') {
      setDataInizio(new Date(anno, 10, 1));
      setDataFine(new Date(anno, 11, 1));
      onFetchVendite(props.token, new Date(anno, 10, 1), new Date(anno, 11, 1));
    }

    if (event.target.value === 'Dicembre') {
      setDataInizio(new Date(anno, 11, 1));
      setDataFine(new Date(anno, 12, 1));
      onFetchVendite(
        props.token,
        new Date(anno, 11, 1),
        new Date(anno + 1, 1, 1)
      );
    }
  };

  return (
    <div style={{ padding: '5px' }}>
      <Form>
        <Row>
          <Col>
            <FormGroup>
              <Label>Anno</Label>
              <Input
                type='select'
                name='anno'
                id='anno'
                placeholder='anno'
                value={anno}
                onChange={(event) => annoChangeHandler(event)}
              >
                <option value={anno}>{anno}</option>
                <option value='2020'>2020</option>
                <option value='2021'>2021</option>
                <option value='2022'>2022</option>
                <option value='2023'>2023</option>
                <option value='2024'>2024</option>
                <option value='2025'>2025</option>
                <option value='2026'>2026</option>
                <option value='2027'>2027</option>
                <option value='2028'>2028</option>
              </Input>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label>Intervallo</Label>

              <Input
                type='select'
                name='intervallo'
                id='intervallo'
                placeholder='Intervallo'
                value={intervallo}
                onChange={(event) => intervalloChangeHandler(event)}
              >
                <option>Ultimi 30 giorni</option>
                <option>Settimana in corso</option>
                <option>Mese in corso</option>
                <option>Tutto l'anno</option>
                <option>Gennaio</option>
                <option>Febbraio</option>
                <option>Marzo</option>
                <option>Aprile</option>
                <option>Maggio</option>
                <option>Giugno</option>
                <option>Luglio</option>
                <option>Agosto</option>
                <option>Settembre</option>
                <option>Ottobre</option>
                <option>Novembre</option>
                <option>Dicembre</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    

      {marcheList}






    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    marche: state.marche.marche,
    loadingMarche: state.marche.loading,
    vendite: state.vendite.vendite,
    loading: state.vendite.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchMarche: (token) => dispatch(actions.fetchMarche(token)),
    onFetchVendite: (token, dataInizio, dataFine) =>
      dispatch(actions.fetchVendite(token, dataInizio, dataFine)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(VenditeVista, axios));
