export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
};

// data intera 01 Gennaio 2021
export const dataIntera = (data) => {

    const mese = ["Gennaio", "Febbraio", "Marzo","Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settebre", "Ottobre", "Novembre", "Dicembre"];
    const giornoSettimana =  ["Domenica","Lunedì", "Martedì", "Mercoledì","Giovedì", "Venerdì", "Sabato" ];
    let dataFormattataIntera =  giornoSettimana[data.getDay()] + ', ' + data.getDate()+ ' ' + mese[data.getMonth()] + ' ' + data.getFullYear() ;

    return dataFormattataIntera;


}

// datda Stringa YYYY-mm-dd
export const dataStringa = (date) => {

    let data = new Date(date);
   // console.log(data);
    let dataStringa =  data.getFullYear() + '-' + ("0" + (data.getMonth() + 1)).slice(-2) + '-' + ("0" + (data.getDate())).slice(-2) ;

    return dataStringa;
}


// dataNormale gg/mm/AAAA
export const dataNormale = (date) => {
    let data = new Date(date);

    let dataNormale =  data.getDate() + '/' + (data.getMonth() + 1) + '/' + data.getFullYear() ;

    return dataNormale

}

export const  getMonday = (d) => {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }


  //tipi di documenti
  export const tipoDocumento = (td) => {

    const tipoDoc = {
                    TD01: 'Fattura',
                    TD02: 'Acconto/Anticipo su fattura',
                    TD03: 'Acconto/Anticipo su parcella',
                    TD04: 'Nota di Credito',
                    TD05: 'Nota di Debito',
                    TD06: 'Parcella',
                    TD07: 'Fattura semplificata',
                    TD08: 'Nota di credito semplificata',
                    TD25: 'Fattura differita'
    };

    return tipoDoc[td];
}

  //tipi di pagamento


  export const codicePagamento = {   MP01:	'contanti',
                                    MP02:	'assegno',
                                    MP03:	'assegno circolare',
                                    MP04:	'contanti presso Tesoria',
                                    MP05:	'bonifico',
                                    MP06:	'vaglia cambiario',
                                    MP07:	'bollettino bancario',
                                    MP08:	'carta di pagamento',
                                    MP09:	'RID',
                                    MP10:	'RID utenze',
                                    MP11:	'RID veloce',
                                    MP12:	'RIBA',
                                    MP13:	'MAV',
                                    MP14:	'quietanza erario',
                                    MP15:	'giroconto su conti di contabilità speciale',
                                    MP16:	'domiciliazione bancaria',
                                    MP17:	'domiciliazione postale',
                                    MP18:	'bollettino di c/c postale',
                                    MP19:	'SEPA Direct',
                                    MP20:	'SEPA Direct Debit B2B',
                                    MP21:	'SEPA Direct Debit CORE',
                                    MP22:	'trattenute su somme già riscosse'}

  export const tipoPagamento = (tp) => {

            return codicePagamento[tp];
  }
