import React, {useEffect, useState} from 'react';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import xml2js from 'xml2js';
import Spinner from 'reactstrap/lib/Spinner';
import {
    Input, FormGroup, Label, Button, Card, CardHeader, CardTitle, CardText, CardDeck,
    CardBody, FormText, CardFooter
  } from 'reactstrap';
import Aux from '../../hoc/Aux_/Aux';
import {tipoDocumento, dataNormale} from '../../shared/utility';
import { Fragment } from 'react';
import axios from '../../hoc/axios-vendite';

const initialStateFornitore = {   id: '',
                                  denominazione: '',
                                  indirizzo: '',
                                  comune: '',
                                  cap: '',
                                  provincia: '',
                                  codiceFiscale: '',      
                                  telefono: '',
                                  email: ''
                                  };
const initialStateFattura = {   numero: '',
                                data: '',
                                fornitore: '',
                                tipo: '',
                                importo: '',
                                imponibile: '',
                                iva: ''
                                }

let initialPagamento = {               id: '',
                                  fattura: '',
                                  scadenza: '',
                                  importo: '',
                                  modalita: '',
                                  rata: '',
                                  dataPagamento: ''
                          }                              

const XmlReader = props => {


  const {
    token,
    fornitoreDb,
    fatturaDb,
    pagamentiDb,
    loading,
    loadingFattura,
    loadingPagamenti,
    onAddFornitore,
    onFetchFornitore,
    onAddFattura,
    onFetchFattura,
    onFetchCodPagamenti,
    onFetchPagamenti
  } = props;


    //initially no file selected
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlText, setXmlText] = useState(null);

  //const [dettaglioPagamento, setDettaglioPagamento] = useState(null);
  const [fornitore, setFornitore] = useState(initialStateFornitore)
  const [fattura, setFattura] = useState(initialStateFattura); 
  const [pagamenti, setPagamenti] = useState(null);
  const [aliquote, setAliquote] = useState([]);                                                                               
  let spinner = '';
    
  //Controllo fornitore in Db
  useEffect( () => {
  //  console.log('UseEffect: 1') ;
    if ( (!fornitoreDb  && fornitore.codiceFiscale !== '') || (fornitore.codiceFiscale !== '' && fornitoreDb.codiceFiscale !== fornitore.codiceFiscale) ) {
    onFetchFornitore(fornitore.codiceFiscale, token) ;
  //  console.log('onFetchFornitore');
    } 
    if (fornitoreDb?.id && fornitore.id === '' ) {
       // console.log(fornitoreDb.id);
        setFornitore({...fornitore, id: fornitoreDb.id});
     //   console.log('setFornitore');
        }
      },[onFetchFornitore, fornitore, fornitoreDb, token])    
   
  //Controllo fattura in Db    
  useEffect( () => {    
   console.log('UseEffect: 2') ;
    if (  (!fatturaDb && fattura.id !== '') || (fatturaDb && fatturaDb.numero !== fattura.numero) ) {
    onFetchFattura(fattura, token) ;

    console.log('onFetchFattura');
      }   
    if (fatturaDb?.id && fattura.id === '') {
      // console.log(fatturaDb.id);
        setFattura({...fattura, id: fatturaDb.id});
      //  console.log('setFattura');
        }
      },[onFetchFattura, fattura, fatturaDb, token]) ;
   
  //se la fattura c'e' nel DB ed il numero è uguale a quella corrent, controllo che ci siano i pagamenti
      useEffect( () => {
        console.log('UseEffect: 3', ) ;
          if (fatturaDb?.id &&  fatturaDb.numero === fattura.numero ) {
            onFetchPagamenti(token, fatturaDb.id);
            console.log('onFetchPagamenti con fattura db =' + fatturaDb.id );
            }
         }, [onFetchPagamenti, fattura, fatturaDb, token]);

      useEffect( () => {
          //console.log('Pagamenti: ',pagamentiDb?.length, fatturaDb?.numero,fatturaDb?.id, pagamenti?.length );

          if (pagamentiDb?.length > 0 && fatturaDb?.numero === fattura?.numero && pagamenti?.length > 0   ){
                  let updatedPagamenti =[]
                  let update = false;
                pagamenti.map( (d) => {
                 // console.log(d.id,pagamentiDb.filter(function(hero) { return hero.rata === d.rata })[0]?.id )

                  update = (d.id !== pagamentiDb.filter(function(hero) { return hero.rata === d.rata })[0]?.id || update ) ? true : false; 
                  // console.log(update);
                  updatedPagamenti.push({ ...d,
                                          fattura: fatturaDb?.id,
                                          id: pagamentiDb.filter(function(hero) { return hero.rata === d.rata && hero.fattura === fatturaDb?.id })[0]?.id
                         })
                         return null;
                    });
             console.log("Aggiorniamo? ", update);
               if (update) {setPagamenti(updatedPagamenti)};
              }
              
      }, [pagamenti, pagamentiDb, fatturaDb, fattura, token]);

      //Prendo dal DB i codici pagamenti
  useEffect( () => {
    //console.log('UseEffect: 4') ;
      onFetchCodPagamenti(token);
      
      //console.log('onFetchCodPagamenti');
      },[onFetchCodPagamenti, token]);

 
 
	const onFileChange = event => { 
	        // Update the state 
        setSelectedFile(event.target.files[0]);
        setXmlText(null);
        setFornitore(initialStateFornitore);
        setFattura(initialStateFattura);
       // setDettaglioPagamento(null);
        setAliquote([]);
        setPagamenti(null);
    }; 

  const salvaFornitoreHandler = () => {
    
    onAddFornitore(fornitore, token);
    readFile();
  };
 
  const aggiornaFornitoreHandler = () => {
    //Salvo 
    console.log("Aggiorna dat fornitore con id " + fornitore.id +  "da implementare");
    //props.onAddFornitore(fornitore, props.token);
  }   ;

  const salvaFatturaHandler = () => {
    //Salvo fornitore
    console.log(fattura)
    onAddFattura(fattura, token);
    readFile();
  }   ;
  
  const aggiornaFatturaHandler = () => {
    //Salvo 
    console.log("Aggiorna dati fattura con id " + props.fatturaDb.id +  "da implementare");
    readFile();
  }  
  
  const salvaPagamentiHandler = () => {
  
    let pagamento = null;
    let id = '';
    //let pagamentiUpdated = [];
          pagamenti.map( (d, i, a) => {
            id = '';
            id = pagamentiDb.filter(pDb => (pDb.fattura === fatturaDb.id && pDb.rata === (i+1)+'/'+ a.length)).length > 0 ? 
            pagamentiDb.filter(pDb => (pDb.fattura === fatturaDb.id && pDb.rata === (i+1)+'/'+ a.length))[0].id : '';
            //console.log(i,d,id);
            
                    pagamento = { ...d, 
                          id: id,
                          fattura: fatturaDb.id,
                            }
          
                if (id === ''){ 
                  //console.log(pagamento);

                  axios.post( '/pagamenti.json?auth=' + token, pagamento )
                  .then( response => {
                   // console.log("responseData: ", response.data);
                    onFetchPagamenti(token, fatturaDb.id);
                  }

                  //onAddPagamento(pagamento, token); };
                // onAddPagamento(pagamento, token);
                )} 
                return null;
           });
           

  }
 
  const aggiornaPagamentiHandler = () => {
    //Salvo 
    console.log("Aggiorna dpagamenti da implementare");
    readFile();
  } 

  const readFile = () => {
        //console.log(file);
        let data = '';
        let reader = new FileReader();
         reader.readAsText(selectedFile);
         reader.onload = () => {

            
            xml2js.parseString(reader.result, function(err,result) {
            //console.log(util.inspect(result, false, null)) ;
           console.log(result);
            let prop = '';
            for ( prop in result) {
               // console.log(prop);
            }

            //Dati fornitore
            let updatedFornitore = {}
            data = result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['DatiAnagrafici'][0]['Anagrafica'][0]['Denominazione'][0];
            updatedFornitore.denominazione = result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['DatiAnagrafici'][0]['Anagrafica'][0]['Denominazione'][0];
            updatedFornitore.indirizzo = result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Sede'][0]['Indirizzo'][0]
                                         + (typeof result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Sede'][0]['NumeroCivico'] !== 'undefined'
                                         ? ', ' + result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Sede'][0]['NumeroCivico'][0] : '') ;
            updatedFornitore.comune = result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Sede'][0]['Comune'][0];
            updatedFornitore.cap = result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Sede'][0]['CAP'][0];
            updatedFornitore.provincia = result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Sede'][0]['Provincia'][0];
            updatedFornitore.codiceFiscale = result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['DatiAnagrafici'][0]['IdFiscaleIVA'][0]['IdPaese'][0]
                                              + result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['DatiAnagrafici'][0]['IdFiscaleIVA'][0]['IdCodice'][0];
            if (typeof result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Contatti'] !== 'undefined'){
            updatedFornitore.telefono = (typeof result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Contatti'][0]['Telefono'] !== 'undefined'
                                        ? result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Contatti'][0]['Telefono'][0] : '') ;
            updatedFornitore.email = (typeof result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Contatti'][0]['Email'] !== 'undefined'
                                        ? result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['Contatti'][0]['Email'][0] : '') ;
            }
            //Controllo se il Fornitore e' prensente nel DB
            



            setFornitore(updatedFornitore);

            //Dati Fattura
            let updatedFattura = {};
            updatedFattura.numero = result[prop]['FatturaElettronicaBody'][0]['DatiGenerali'][0]['DatiGeneraliDocumento'][0]['Numero'][0];
            updatedFattura.data = result[prop]['FatturaElettronicaBody'][0]['DatiGenerali'][0]['DatiGeneraliDocumento'][0]['Data'][0];
            updatedFattura.fornitore = result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['DatiAnagrafici'][0]['IdFiscaleIVA'][0]['IdPaese'][0]
                                        + result[prop]['FatturaElettronicaHeader'][0]['CedentePrestatore'][0]['DatiAnagrafici'][0]['IdFiscaleIVA'][0]['IdCodice'][0];
            updatedFattura.importo = result[prop]['FatturaElettronicaBody'][0]['DatiGenerali'][0]['DatiGeneraliDocumento'][0]['ImportoTotaleDocumento'][0]; 
            updatedFattura.tipo = result[prop]['FatturaElettronicaBody'][0]['DatiGenerali'][0]['DatiGeneraliDocumento'][0]['TipoDocumento'][0]; 
            //updatedFattura.aliquote = result[prop]['FatturaElettronicaBody'][0]['DatiBeniServizi'][0]['DatiRiepilogo'];  
          //  console.log( updatedFattura.tipo)   ;                          
          result[prop]['FatturaElettronicaBody'][0]['DatiBeniServizi'][0]['DatiRiepilogo'].map( (d) => ( 
            updatedFattura.imponibile = (updatedFattura.imponibile ? parseFloat(updatedFattura.imponibile) : 0) + 
                                        (d.ImponibileImporto ? parseFloat(d.ImponibileImporto) : 0)));
          updatedFattura.imponibile = Math.round((updatedFattura.imponibile + Number.EPSILON) * 100) / 100
          result[prop]['FatturaElettronicaBody'][0]['DatiBeniServizi'][0]['DatiRiepilogo'].map( (d) => ( 
            updatedFattura.iva = (updatedFattura.iva ? parseFloat(updatedFattura.iva) : 0) + 
                                        (d.Imposta ? parseFloat(d.Imposta) : 0)));
          updatedFattura.iva = Math.round((updatedFattura.iva + Number.EPSILON) * 100) / 100;
                    
          //console.log("Imponibile= " + updatedFattura.imponibile + " Imposta= " + updatedFattura.iva);
                    
            setFattura(updatedFattura);                             
            setAliquote(result[prop]['FatturaElettronicaBody'][0]['DatiBeniServizi'][0]['DatiRiepilogo']) ;

            let updatedDettagli = [];
            let updatedPagamento = null;
            result[prop]['FatturaElettronicaBody'][0]['DatiPagamento'][0]['DettaglioPagamento'].map( (d, i, a) => {
              
              updatedPagamento = {...initialPagamento,
                                    id: '',
                                    fattura: '',
                                    scadenza: d.DataScadenzaPagamento ? d.DataScadenzaPagamento[0] : updatedFattura.data  ,
                                    importo: d.ImportoPagamento[0],
                                    modalita: d.ModalitaPagamento[0],
                                    rata: (i+1)+'/'+ a.length,
                                    dataPagamento: ''
                                  }
              updatedDettagli.push(updatedPagamento);
              setPagamenti(updatedDettagli);
              return null;
              });
              
          });
        
          setXmlText(data)
          spinner = <Spinner />;
         };
         
      };
 
      

  if (!!selectedFile && !!xmlText ) {
        //console.log('sono qui' + xmlText)
       
        spinner = '';
      }
      

  let dettagliFornitore = null

      if (!!selectedFile && fornitore.codiceFiscale !== '' ) {
        
        let fornitoreEsiste = props.fornitoreDb?.codiceFiscale === fornitore.codiceFiscale ?  true : false;
       
        
        dettagliFornitore = (
                <Card outline color="secondary">
                    <CardHeader tag="h4">Dati Fornitore</CardHeader>
                    <CardBody className="text-left">
                    <CardTitle tag="h3">{fornitore.denominazione}</CardTitle>
                    <CardText>  {fornitore.indirizzo}<br/>
                                {fornitore.cap} {fornitore.comune} ({fornitore.provincia})<br/>
                                Partita Iva: {fornitore.codiceFiscale}<br/>
                                Tel. {fornitore.telefono}<br/>
                                Email: {fornitore.email}<br/>
                                

                                {/* {fornitore.sede.CAP} {fornitore.sede.Comune} ({fornitore.sede.Provincia}) <br/> <br/>*/}
                                </CardText> 
                    </CardBody>
                    <CardFooter>
                      {props.loading ? <Spinner /> :
                      fornitoreEsiste ? <Button color="secondary" onClick={aggiornaFornitoreHandler}>Aggiorna Fornitore</Button> : <Button color="primary" onClick={salvaFornitoreHandler}>Salva Fornitore</Button>}  
                    </CardFooter>
                    
                </Card>
        );
      }

      let dettagliFattura = null;
      let fatturaEsiste = props.fatturaDb?.numero === fattura.numero ? true : false; 

        
      if (!!selectedFile && fattura.numero !== '' && !loading && !loadingFattura && !loadingPagamenti ) {

        console.log("Fattura numero db " + props.fatturaDb?.numero + " Fattura numero: " + fattura.numero);
        
        //console.log('sono qui' + xmlText)

        //console.log(props.fatturaDb);
        dettagliFattura = (
                <Card outline color="secondary">
                    <CardHeader tag="h4">Dati {tipoDocumento(fattura.tipo)} ({fattura.tipo})</CardHeader>
                    <CardBody className="text-left">
                    <CardTitle tag="h4">Numero {fattura.numero}</CardTitle>
                    <CardText tag="h5">   del {dataNormale(fattura.data)} <br/> </CardText>
                    <CardText >  Importo Totale: {fattura.importo}<br/>
                     </CardText> 
                     {aliquote.map( (d, i, a) => (
                            <Aux key={i}>
                        <CardText > 
                        Aliquota IVA: {d.AliquotaIVA}%  Imponibile: {d.ImponibileImporto}€ Imposta: {d.Imposta}€ <br/> 
                        </CardText>
                        </Aux>
                        ))}
                    </CardBody>
                    <CardFooter>
                      {props.loading ? <Spinner /> :
                      fatturaEsiste ? 
                      <Fragment>
                      {/* <p>Fattura id: {props.fatturaDb.id}</p> */}
                      <Button color="secondary" onClick={aggiornaFatturaHandler}>Aggiorna Fattura</Button></Fragment> : <Button color="primary" onClick={salvaFatturaHandler}>Salva Fattura</Button>}  
                    </CardFooter>
                </Card>
        );
      }

      let dettagliPagamenti = null;
      let pagamentoEsiste =  true; 
      
      if ( pagamenti?.length > 0  && !loadingPagamenti && !loading && !loadingFattura  && (props.codPagamenti && props.codPagamenti.length) ) {
        
            console.log('dettagli pagamentiDb: ', pagamentiDb.length);  
            console.log('dettagli pagamenti: ', pagamenti.length);
            let updatedPagamenti = [];
            if (pagamenti.length === pagamentiDb.lenght) {
              
              let update = false;
            pagamenti.map( (d, i, a) => {
             // console.log("id: ",pagamentiDb.filter(function(hero) { return hero.rata === d.rata })[0]?.id )
              update = (d.id === '' || update ) ? true : false; 

              updatedPagamenti.push({ ...d,
                                      fattura: fatturaDb?.id,
                                      id: pagamentiDb.filter(function(hero) { return hero.rata === d.rata && hero.fattura === fatturaDb?.id })[0]?.id
                     })

                     return null;
                });
            //  console.log(updatedPagamenti);
              if (update) {setPagamenti(updatedPagamenti)};
              console.log("updated Pagamenti: ", updatedPagamenti);
            } else {updatedPagamenti = pagamenti}

            dettagliPagamenti = <Card outline color="secondary">
            <CardHeader tag="h4">Dati Pagamenti</CardHeader>
            <CardBody className="text-left">
                <CardTitle tag="h3">Totale: {fattura.importo}  {pagamenti.length > 1 ? 'in ' + pagamenti.length + ' rate' : ''}</CardTitle>
                        
                        
                        {updatedPagamenti.map( (d, i, a) => {
                              pagamentoEsiste = (d.id && pagamentoEsiste )  ? true : false;
                             // console.log(fatturaDb?.id);
                              let bgCol = '';
                              bgCol = d.id ? '#D5F5E3' : '#FADBD8';

                            return (
                            <Card key={i} outline color="secondary" style={{ backgroundColor: bgCol , margin: '2px'}}><CardBody  >
                              
                                <CardText > 
                                Rata: {i+1}/{a.length}  { d.scadenza && 'scadenza: ' + dataNormale(d.scadenza)} <br/> 
                                Importo: {d.importo} Modalità:  {                          
                                                      props.codPagamenti ? 
                                                      props.codPagamenti.filter(function(hero) { return hero.codicePagamento === d.modalita })
                                                      .map( codPagamento => ( codPagamento.tipoPagamento )  ) : null }
                                </CardText>
                               </CardBody></Card>)
                              } )
                        }
                      

            </CardBody>
            <CardFooter>
                      {loading || loadingFattura || loadingPagamenti ? <Spinner /> :
                          (fatturaEsiste ?
                          (pagamentoEsiste > 0 ? <Button color="secondary" onClick={aggiornaPagamentiHandler}>Aggiorna pagamenti</Button> 
                                                  : <Button color="primary" onClick={salvaPagamentiHandler}>Salva pagamenti</Button> ) 
                          : <Button color="primary" disabled>Salva pagamenti</Button>)
                      }
                      </CardFooter>
        </Card>
        
        
        pagamenti.map( (d, i, a) => (

            <div key= {i+1} >rata: {i+1}/{a.length} scadenza: { d.scadenza} importo: {d.importo} </div>
        ));
      }


    
        return (
        
        <div>
           	
			<div style={{display: 'flex', justifyContent: 'center'}}> 
                
            <FormGroup>
            <Label for="exampleFile">File</Label><br/>
            <Input type="file" name="file" id="exampleFile" onChange={onFileChange}  />
            <FormText color="muted">
            Carica una fattura elettronica in formato XML.
            </FormText>
        <br/>
				 
				<Button onClick={readFile} disabled={!selectedFile}> 
				Leggi
				</Button> { spinner }
             </FormGroup>  
			</div> 
        <br />
        
        <div style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>

        
        <CardDeck >
            {dettagliFornitore}
            {dettagliFattura}
            {dettagliPagamenti}

        </CardDeck>
        </div>
        </div> 
        
            );
    
}

const mapStateToProps = state => {
    return {
        pagamentiDb: state.pagamenti.pagamenti,
        pagamentoDb: state.pagamenti.pagamento,
        loadingPagamenti: state.pagamenti.loading,
        codPagamenti: state.codPagamenti.codPagamenti,
        loadingCodPagamenti: state.codPagamenti.loading,
        fatturaDb: state.fatture.fattura,
        loadingFattura: state.fatture.loading,
        fornitoreDb: state.fornitori.fornitore,
        fornitori: state.fornitori.fornitori,
        loading: state.fornitori.loading,
        fatturaDB: state.fatture.fatture,
        loadingFatture: state.fatture.loading,
        token: state.auth.token
    }
    
}

const mapDispatchToProps = dispatch => {
    return {
        onAddPagamento: (pagamnentoData, token) => dispatch(actions.addPagamento(pagamnentoData, token)),
        onFetchPagamento: (pagamnentoData, token) => dispatch(actions.fetchPagamento(pagamnentoData, token)),
        onFetchPagamenti: (token, fatturaId) => dispatch(actions.fetchPagamenti( token, fatturaId)),
        onFetchCodPagamenti: (token) => dispatch(actions.fetchCodPagamenti(token)),
        onFetchFattura: (fatturaData, token) => dispatch(actions.fetchFattura(fatturaData, token)),
        onAddFattura: (fatturaData, token) => dispatch(actions.addFattura(fatturaData, token)),
        onFetchFornitore: (codiceFiscale, token) => dispatch(actions.fetchFornitore(codiceFiscale, token)),
        onFetchFornitori: (token) => dispatch(actions.fetchFornitori(token)),
        onAddFornitore: (fornitoreData, token) => dispatch(actions.addFornitore(fornitoreData, token)),
        onModificaFornitore: (fornitoreData, token) => dispatch(actions.modificaFornitore(fornitoreData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(XmlReader);