import React, {  useEffect, useState } from 'react';
import axios from '../../hoc/axios-vendite';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Marca from './Marca/Marca';
import * as actions from '../../store/actions/index';
import { Spinner, Table } from 'reactstrap';
import classes from './Marche.module.css';
import { checkValidity,updateObject } from '../../shared/utility.js';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup,  
  Col,Input, InputGroup, InputGroupAddon, 
  InputGroupText} from 'reactstrap';


  


const Marche = props => {

    const {
        className,
        onFetchMarche,
      } = props;

      
      const [modal, setModal] = useState(false);
      const [modifica, setModifica] = useState(false);

      const [ marca, setMarca] = useState({
        id:   { value: '',
                validation: { required: false},
                valid: true},
        name: { value: '',
                validation: { required: true},
                valid: false},
        tipo: { value: '',
                validation: { required: true},
                valid: false
            }
        })
    
      const toggle = () => setModal(!modal);

      const modificaMarcaHandler = (marcaData) => {
        setModifica(true);
        console.log(marcaData);
        setMarca({id: {value: marcaData.id, valid: true}, name: {value: marcaData.name, valid: true}, tipo:{value: marcaData.tipo, valid: true}});
        console.log(marca);
        if(marca.id !== ''){
          toggle();
        }
        
      }  


      const nuovaMarcaHandler = () => {
        setModifica(false);
        //console.log(marcaData);
        setMarca({id: {value: '', valid: true}, name: {value: '', valid: false}, tipo:{value: '', valid: false}});
        toggle();
        
        
      }  
   
    useEffect( () => {
      onFetchMarche(props.token)}, [onFetchMarche, props.token] );

      let marcheList =  <Spinner />;

        if (!props.loading) {
          
          props.marche.sort((a, b) => (a.name > b.name) ? 1 : -1);

          marcheList = (
            <Table hover className={classes.Table}>
                <thead> 
                  <tr style={{heigth: "15px", fontSize: "0,8rem", padding: "2px"}}>
                    <th style={{fontSize: "1.8rem"}}>Nome</th>
                    <th style={{fontSize: "1.8rem"}}>Tipo</th>
                  </tr>
                </thead>
                <tbody>

                {props.marche.map(marca => (
                        <Marca 
                            marca = {marca}
                            key={marca.id}
                            nome={marca.name}
                            tipo={marca.tipo}
                            onClicked={modificaMarcaHandler}/> 
                ))  }
            </tbody>
              </Table>
            )
        };

    

    

    const inputChangedHandler = (event, inputIdentifier) => { 
        

      const updatedFormElement = updateObject(marca[inputIdentifier], {
          value: event.target.value,
          valid: checkValidity(event.target.value, marca[inputIdentifier].validation),
          

      } );

      const updatedMarcaForm = updateObject(marca,{
          [inputIdentifier]: updatedFormElement
      });
      
      let formIsValid = true;
      for (let inputIdentifier in updatedMarcaForm) {
          formIsValid = updatedMarcaForm[inputIdentifier].valid && formIsValid;
      }
      setMarca(updatedMarcaForm);
   
     // setFormIsValid(formIsValid);
  }
  
      
      const addMarcaHandler = ( event ) => {
              event.preventDefault();
              const marcaData = {};
                    for (let formElementIdentifier in marca) {
                      marcaData[formElementIdentifier] = marca[formElementIdentifier].value;
                    }
              console.log(marcaData)
              props.onAddMarca(marcaData, props.token);
              toggle();
            }

      const submitModificaMarcaHandler = ( event ) => {
              event.preventDefault();
              const marcaData = {};
                    for (let formElementIdentifier in marca) {
                      marcaData[formElementIdentifier] = marca[formElementIdentifier].value;
                    }
              console.log(marcaData)
              props.onModificaMarca(marcaData, props.token);
              toggle();
            }

      const submitEliminaMarcaHandler = ( event ) => {
              event.preventDefault();
              const marcaData = {};
                    for (let formElementIdentifier in marca) {
                      marcaData[formElementIdentifier] = marca[formElementIdentifier].value;
                    }
              console.log(marcaData)
              props.onEliminaMarca(marcaData, props.token);
              toggle();
            }

    
        return (
            <div>
            <Button color="primary" onClick={nuovaMarcaHandler} style={{heigth: "15px", fontSize: "0.8rem", padding: "2px", margin: "5px"}}>Inserisci una nuova marca</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{modifica ? 'MODIFICA MARCA' : 'INSERISCI NUOVA MARCA'}</ModalHeader>
        <ModalBody>
        <Form onSubmit={addMarcaHandler}>
                      <Input  type="hidden" name="id" id="id" value={marca.id} />
                                
                 <FormGroup row>
                        <Col >
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>NOME MARCA</InputGroupText>
                                </InputGroupAddon>
                                <Input valid={marca.name.valid} invalid={!marca.name.valid}  type="text" name="name" id="name"  placeholder="Nome marca" value={marca.name.value} onChange={(event) => inputChangedHandler(event, "name")}/>
                                
                                </InputGroup>
                        </Col>
                </FormGroup>
                <FormGroup row>
                <Col >
                    
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                                <InputGroupText>TIPO MARCA</InputGroupText>
                        </InputGroupAddon>
                        <Input valid={marca.tipo.valid} invalid={!marca.tipo.valid} type="select" name="tipo" id="tipo" value={marca.tipo.value} onChange={(event) => inputChangedHandler(event, "tipo")}>
                            <option value='' >Seleziona il tipo...</option>
                            <option>montatura</option>
                            <option>lac</option>
                        </Input>
                    </InputGroup>  
                    
                </Col>
                </FormGroup>
          
    </Form>
        </ModalBody>
        <ModalFooter>
        {modifica ? <Button color="primary" onClick={submitModificaMarcaHandler}>Modifica</Button> : <Button color="primary" onClick={addMarcaHandler}>Inserisci</Button>}{' '}
        {modifica ? <Button color="danger" onClick={submitEliminaMarcaHandler}>Elimina</Button> : null}{' '}
          <Button color="secondary" onClick={toggle}>Indietro</Button>
        </ModalFooter>
      </Modal>

               {marcheList} 
            </div>
            
        );
    
}

const mapStateToProps = state => {
    return {
        marche: state.marche.marche,
        loading: state.marche.loading,
        token: state.auth.token
    }
    
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchMarche: (token) => dispatch(actions.fetchMarche(token)),
        onAddMarca: (marcarData, token) => dispatch(actions.addMarca(marcarData, token)),
        onModificaMarca: (marcaData, token) => dispatch(actions.modificaMarca(marcaData, token)),
        onEliminaMarca: (marcaData, token) => dispatch(actions.eliminaMarca(marcaData, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Marche, axios));
