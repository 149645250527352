import * as actionTypes from './actionTypes';
import axios from '../../hoc/axios-vendite';

//create add marca Start action
export const addMarcaStart= () => {
    return  {
        type: actionTypes.ADD_MARCA_START
        };
    };
    
export const addMarcaSuccess = (id,marcaData) => {
        return {

        type: actionTypes.ADD_MARCA_SUCCESS,
        marcaId: id,
        marcaData: marcaData
    }
}

export const addMarcaFail= (error) => {
    return {
        type: actionTypes.ADD_MARCA_FAIL,
        error: error
    }
}

export const addMarca = (marcaData, token) => {
    return dispatch => {
        dispatch(addMarcaStart());
       // console.log('sto chiamando axios')
        axios.post( '/marche.json?auth=' + token, marcaData )
            .then( response => {
               // console.log(response.data.name)
                dispatch( addMarcaSuccess(response.data.name, marcaData))
                //console.log(response.data.name)
                dispatch(fetchMarche(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( addMarcaFail(error));
               
            } );
    }
}

export const addMarcaInit = () => {
    return  {
        type: actionTypes.ADD_MARCA_INIT
        };
    };

//create add marca Start action
export const modificaMarcaStart= () => {
    return  {
        type: actionTypes.MODIFICA_MARCA_START
        };
    };
    
export const modificaMarcaSuccess = (id,marcaData) => {
        return {

        type: actionTypes.MODIFICA_MARCA_SUCCESS,
        marcaId: id,
        marcaData: marcaData
    }
}

export const modificaMarcaFail= (error) => {
    return {
        type: actionTypes.MODIFICA_MARCA_FAIL,
        error: error
    }
}

export const modificaMarca = (marcaData, token) => {
    return dispatch => {
        dispatch(modificaMarcaStart());
        console.log('sto chiamando axios')
        axios.patch( '/marche/' +  marcaData.id + '.json?auth=' + token, marcaData )
            .then( response => {
                console.log(response.data.name)
                dispatch( modificaMarcaSuccess(response.data.name, marcaData))
                //console.log(response.data.name)
                dispatch(fetchMarche(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( modificaMarcaFail(error));
               
            } );
    }
}

//create elimina marca Start action
export const eliminaMarcaStart= () => {
    return  {
        type: actionTypes.ELIMINA_MARCA_START
        };
    };
    
export const eliminaMarcaSuccess = (marcaData) => {
        return {

        type: actionTypes.ELIMINA_MARCA_SUCCESS,
        marcaId: marcaData.id
    }
}

export const eliminaMarcaFail= (error) => {
    return {
        type: actionTypes.ELIMINA_MARCA_FAIL,
        error: error
    }
}

export const eliminaMarca = (marcaData, token) => {
    return dispatch => {
        dispatch(eliminaMarcaStart());
        console.log('sto chiamando axios per cancellare')
        axios.delete( '/marche/' +  marcaData.id + '.json?auth=' + token, marcaData )
            .then( response => {
                dispatch( eliminaMarcaSuccess(marcaData))
                //console.log(response.data.name)
                dispatch(fetchMarche(token))
                // this.setState( { loading: false } );
                // this.props.history.push( '/' );
            } )
            

            .catch( error => {
                console.log(error)
                dispatch( eliminaMarcaFail(error));
               
            } );
    }
}

export const fetchMarcheStart = () => {
    return {
        type: actionTypes.FETCH_MARCHE_START
    };
};

export const fetchMarcheSuccess = (marche) => {
    return {
        type: actionTypes.FETCH_MARCHE_SUCCESS,
        marche : marche        
    };
};

export const fetchMarcheFail = (error) => {
    return {
        type: actionTypes.FETCH_MARCHE_FAIL,
        error: error
    };
};

export const fetchMarche= (token) => {
   return dispatch => {
        dispatch(fetchMarcheStart());
        const queryParams = '?auth=' + token ;
        axios.get('/marche.json' + queryParams )
    .then(res => {
        const fetchedMarche=[];
        for (let key in res.data) {
            fetchedMarche.push({
                ...res.data[key],
                id: key
            });
        }
        dispatch(fetchMarcheSuccess(fetchedMarche))
        
    })
    .catch(err => {
        dispatch(fetchMarcheFail(err))        
    });
    }
}

