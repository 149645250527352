import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility.js';
// import {dataStringa } from '../../shared/utility.js';



let oggi = new Date();
// oggi = dataStringa(oggi.setDate(oggi.getDate() + 1));
//console.log('STORE OGGI:', oggi);

const initialState = {
    giorno: oggi,
   

}

 
    const  setGiorno = ( state, action) => {
        return updateObject(state, {giorno: action.giorno});
    }


const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_GIORNO: return setGiorno(state, action);
 
         default: return state;
    }
};

export default reducer;