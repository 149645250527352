import React from 'react';
import classes from './Flusso.module.css';

const Flusso = props => {
    
    

        return (
            <tr key = {props.flusso.id} onClick={() => props.onClicked(props.flusso) } >
                <td className={classes.TableRow} style={{padding: "4px"}}>{props.flusso.name}</td>
                <td className={classes.TableRow} style={{padding: "4px"}}>{props.flusso.tipo}</td>
            </tr>
        )
    
}

export default Flusso;