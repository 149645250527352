import React, {  useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {Button, Form, FormGroup, 
    Col,Input, InputGroup, InputGroupAddon, ModalBody, ModalFooter, 
     InputGroupText} from 'reactstrap';
import Aux from '../../../hoc/Aux_/Aux';
import {dataStringa } from '../../../shared/utility';
import * as actions from '../../../store/actions/index';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';
import Spinner from 'reactstrap/lib/Spinner';
registerLocale('it', it)

const ModificaUscita = props => {


    const {
        onFetchFlussi,
        onFetchCodPagamenti
      } = props;    
    
const [formError, setFormError] = useState('');
const [ uscita, setUscita] = useState({

    id: {value: props.uscita.id,
        validation: { required: false},
        valid: true},
    
    data: { value: props.uscita.data, 
        validation: { required: false},
        valid: true},
    
    flusso: { value: 'uscita',
        validation: { required: false},
        valid: true
    },
    tipo: { value: props.uscita.tipo,
            validation: { required: true},
            valid: false
        },
    descrizione: { value: props.uscita.descrizione,
            validation: { required: true},
            valid: false
        },
    modo: { value: props.uscita.modo,
        validation: { required: true},
        valid: false
    },
    valore: { value: props.uscita.valore,
    validation: { required: true},
    valid: false
    }

    })

    let body = <Spinner />;

    useEffect( () => {

        onFetchFlussi(props.token);
        onFetchCodPagamenti(props.token);
        
        }, [onFetchFlussi,onFetchCodPagamenti, props.token] );

     if (!props.loadingFlussi || (props.flussi && props.flussi.length) || !props.loadingCodPagamenti || (props.codPagamenti && props.codPagamenti.length)){
        body = '';
        }
     
   
        const checkValidity = (value, rules) => {
            let isValid = false;

            //  console.log(rules);
            //  console.log(value);
         
            if (rules.required) {
                isValid = value.trim() !== '';
            }

            if (!rules.required) {
                isValid = true;
            }

            return isValid
        }
        const inputChangeHandler = (event, id) => {
                    //  console.log(event.target.value + ' elemento: ' + id);
                       const updatedUscita = {
                           ...uscita
                       };
                       const updatedFormElement = {
                           ...updatedUscita[id]
                       };
                       
                       updatedFormElement.value = (typeof event.target.type !== 'undefined' && event.target.type === 'checkbox') ? event.target.checked : event.target.value;
                       
                       updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation)
                       updatedUscita[id] = updatedFormElement;
                    //   console.log(updatedFormElement);
       
                       setUscita(updatedUscita);
                   }

        
        const submitAddUscitaHandler = (event) => {
            event.preventDefault();
            const uscitaData = {};
                  for (let formElementIdentifier in uscita) {
                    uscitaData[formElementIdentifier] = uscita[formElementIdentifier].value;
                    
                    if (!uscita[formElementIdentifier].valid){
                        //   console.log(formElementId, ' valore non corretto: ', this.state.vendita[formElementId].value, ' ma validido: ', this.state.vendita[formElementId].valid);
                        setFormError( ' valore non corretto: ' + formElementIdentifier);
                        return;
                    }

                    
            }
                    //console.log(uscitaData );
                    props.onSetGiorno(uscitaData.data);
                    props.onAddCash(uscitaData, props.token);
            props.toggle();

        }

        const submitModificaUscitaHandler = (event) => {
            event.preventDefault();
            const uscitaData = {};
                  for (let formElementIdentifier in uscita) {
                    uscitaData[formElementIdentifier] = uscita[formElementIdentifier].value;
                    uscita[formElementIdentifier].valid = checkValidity(uscita[formElementIdentifier].value, uscita[formElementIdentifier].validation)
                    if (!uscita[formElementIdentifier].valid){
                        //   console.log(formElementId, ' valore non corretto: ', this.state.vendita[formElementId].value, ' ma validido: ', this.state.vendita[formElementId].valid);
                        setFormError( ' valore non corretto: ' + formElementIdentifier);
                        return;
                    }
    
            }
                    //console.log(uscitaData );
                    console.log(uscitaData.data );
                    props.onSetGiorno(uscitaData.data);
                    props.onModificaCash(uscitaData, props.token);
            
            
            props.toggle();
        }

        
            
            

        const submitEliminaUscitaHandler = (event) => {
            event.preventDefault();
              const uscitaData = {};
                    for (let formElementIdentifier in uscita) {
                        uscitaData[formElementIdentifier] = uscita[formElementIdentifier].value;
                    }
            //  console.log(uscitaData)
              props.onEliminaCash(uscitaData, props.token);
            props.toggle();
       }

       const handleChange = date => {
        const updatedUscita = {
            ...uscita
        };
        updatedUscita['data']['value'] = dataStringa(date);
       // console.log("Data a stringa:" + updatedUscita['data']['value'])
        setUscita(updatedUscita);
       // props.onSetGiorno(date);
        };
    
        return (
         <Aux>

             { body }
                <ModalBody>
                 <Form>
                 <Input  type="hidden" name="id" id="id" value={uscita.id} />
                 <Input  type="hidden" name="flusso" id="flusso" value={uscita.flusso.value} />

                <FormGroup row>
                    <Col>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>Data</InputGroupText>
                        </InputGroupAddon>
                    <DatePicker locale = "it"
                                selected={Date.parse(uscita.data.value)}
                                onChange={(date) => handleChange(date)}
                                inline={props.visibile}
                                dateFormat="dd/MM/yyyy"  
                                customInput={<Input />}  />
                    </InputGroup>  
                    </Col>
                </FormGroup>

                <FormGroup row>
                <Col >
                    
                    <InputGroup>
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>Tipo</InputGroupText>
                    </InputGroupAddon><Input valid={uscita.tipo.valid} 
                                            invalid={!uscita.tipo.valid && (uscita.tipo.value === '') } 
                                            type="select" 
                                            name="tipo" 
                                            id="tipo"  
                                            value = {uscita.tipo.value }
                                            onChange={(event) => inputChangeHandler(event, "tipo")}>
                                        <option value='' >Seleziona il tipo...</option>
                                        {props.flussi ? 
                                            props.flussi.filter(function(hero) { return hero.tipo === 'uscita' })
                                            .sort((a, b) => (a.name > b.name) ? -1 : 1)
                                            .map( flusso => (                                                        
                                                        <option value={flusso.name} key={flusso.id}  >{flusso.name}</option>
                                                        ) 
                                                ) : null }
                                               
                        </Input> </InputGroup>  
                    
                </Col>
                </FormGroup>

                <FormGroup row>
                <Col >
                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>Descrizione </InputGroupText>
                                </InputGroupAddon>
                                <Input valid={uscita.descrizione.valid } invalid={!uscita.descrizione.valid && uscita.descrizione.value === ''} value={uscita.descrizione.value} type="text" name="descrizione" id="descrizione"  placeholder="Dscrizione" onChange={(event) => inputChangeHandler(event, "descrizione")}/>
                                
                                </InputGroup>
                    
                    
                </Col>
                </FormGroup>

                <FormGroup row>
                <Col >
                    
                    <InputGroup>
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>Modo</InputGroupText>
                    </InputGroupAddon><Input valid={uscita.modo.valid} 
                                            invalid={!uscita.modo.valid & uscita.modo.value === ''} 
                                            type="select" 
                                            name="modo" 
                                            id="modo"  
                                            value = {uscita.modo.value}
                                            onChange={(event) => inputChangeHandler(event, "modo")}>
                                        <option value='' >Seleziona il tipo...</option>
                                        {props.codPagamenti ? 
                                            props.codPagamenti
                                            .sort((a, b) => (a.codicePagamento > b.codicePagamento) ? 1 : -1)
                                            .map( codPagamento => (                                                        
                                                        <option value={codPagamento.codicePagamento} key={codPagamento.id}  >{codPagamento.tipoPagamento}</option>
                                                        ) 
                                                ) : null }
                                      
                                      
                                               
                        </Input> </InputGroup>  
                    
                </Col>
                </FormGroup>

                <FormGroup row>
                <Col >
                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>Valore </InputGroupText>
                                </InputGroupAddon>
                                <Input valid={uscita.valore.valid } invalid={!uscita.valore.valid & uscita.valore.value <= 0  } value={uscita.valore.value} 
                                type="number" name="valore" id="valore"  placeholder="Importo" 
                                onChange={(event) => inputChangeHandler(event, "valore")}/>
                                
                                </InputGroup>
                    
                    
                </Col>
                </FormGroup>
                
          
    </Form>
    <p style={{color: "red"}}>{formError}</p>
    </ModalBody>
    <ModalFooter>
        {props.uscita.modifica ? <Button color="primary" onClick={submitModificaUscitaHandler}>Modifica</Button> : <Button color="primary" onClick={submitAddUscitaHandler}>Inserisci</Button>}{' '}
        {props.uscita.modifica && <Button color="danger" onClick={submitEliminaUscitaHandler}>Elimina</Button> }{' '}
          <Button color="secondary" onClick={props.toggle}>Indietro</Button>
        </ModalFooter>
            </Aux> 
        )
    
}

const mapStateToProps = (state) => ({
    giorno: state.foglio.giorno,
    loading: state.cash.loading,
    flussi: state.flussi.flussi,
    loadingFlussi: state.flussi.loading,
    codPagamenti: state.codPagamenti.codPagamenti,
    loadingCodPagamenti: state.codPagamenti.loading,
    token: state.auth.token

})

const mapDispatchToProps = dispatch => {
    return {
        onFetchCodPagamenti: (token) => dispatch(actions.fetchCodPagamenti(token)),
        onFetchFlussi: (token) => dispatch(actions.fetchFlussi(token)),
        onFetchCash: (token, data) => dispatch(actions.fetchCash(token, data)),
        onAddCash: (cashData, token) => dispatch(actions.addCash(cashData, token)),
        onModificaCash: (cashData, token) => dispatch(actions.modificaCash(cashData, token)),
        onEliminaCash: (cashData, token) => dispatch(actions.eliminaCash(cashData, token)),
        onSetGiorno: (giorno) => dispatch(actions.setGiorno(giorno))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModificaUscita);
