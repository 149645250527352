import React from 'react';
import classes from './Marca.module.css';

const Marca = props => {
    
    

        return (
            <tr key = {props.marca.id} onClick={() => props.onClicked(props.marca) } >
                <td className={classes.TableRow} style={{padding: "4px"}}>{props.marca.name}</td>
                <td className={classes.TableRow} style={{padding: "4px"}}>{props.marca.tipo}</td>
            </tr>
        )
    
}

export default Marca;