import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    cash: [],
    loading: false,
    added: false
   
};

const addCashInit = ( state, action ) => {
    return updateObject(state, {added: false });
}

const addCashStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const addCashSuccess = ( state, action ) => {
    const newCash = {
        ...action.marcaData,
        id:action.marcaId,
        }    
    return updateObject(state, {
            loading: false,
            cash: state.cash.concat(newCash),
            added: true
        });
}

const addCashFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const modificaCashStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const modificaCashSuccess = ( state, action ) => {
    const newCash = {
        ...action.marcaData,
        id:action.marcaId,
        }    
    return updateObject(state, {
            loading: false,
            cash: state.cash.concat(newCash),
            added: true
        });
}

const modificaCashFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//elimina marca
const eliminaCashStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const eliminaCashSuccess = ( state, action ) => {
    // const newCash = {
    //     ...action.marcaData,
    //     id:action.marcaId,
    //     }    
    return updateObject(state, {
            loading: false,
            cash: state.cash,
            added: true
        });
}

const eliminaCashFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

//fetch cash
const fetchCashStart = ( state, action ) => {
    return updateObject(state, {loading: true });
}

const fetchCashSuccess = ( state, action ) => {
    return updateObject(state,{
        cash: action.cash,
        loading: false});
}

const fetchCashFail = ( state, action ) => {
    return updateObject(state, {loading: false });
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.ADD_CASH_INIT: return addCashInit( state, action );
        case actionTypes.ADD_CASH_START: return addCashStart(state, action );            
        case actionTypes.ADD_CASH_SUCCESS: return addCashSuccess( state, action);
        case actionTypes.ADD_CASH_FAIL: return addCashFail (state, action);
        case actionTypes.MODIFICA_CASH_START: return modificaCashStart(state, action );            
        case actionTypes.MODIFICA_CASH_SUCCESS: return modificaCashSuccess( state, action);
        case actionTypes.MODIFICA_CASH_FAIL: return modificaCashFail (state, action);
        case actionTypes.ELIMINA_CASH_START: return eliminaCashStart(state, action );            
        case actionTypes.ELIMINA_CASH_SUCCESS: return eliminaCashSuccess( state, action);
        case actionTypes.ELIMINA_CASH_FAIL: return eliminaCashFail (state, action);
        case actionTypes.FETCH_CASH_START: return fetchCashStart(state, action);
        case actionTypes.FETCH_CASH_SUCCESS: return fetchCashSuccess(state, action);
        case actionTypes.FETCH_CASH_FAIL:return fetchCashFail(state, action);
        default: return state;

        }
}

export default reducer;
